import React, {useContext} from 'react';
import Grid from '@mui/material/Grid';
import {FormControl} from '@mui/material';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import pharmacyNumContext from '../Authorization/AuthContext';

interface UserPharmacies {
  state: string;
  pharmacy: string;
  pharmacyId: number;
}

interface PharmacyFormProps {
  pharmacy: string;
  setPharmacy: (input: string) => void;
  data: UserPharmacies[];
  setPharmacyId: (input: number) => void;
  pharmacyId: number;
}

export const PharmacyForm = (props: PharmacyFormProps) => {
  const [selectedState, setSelectedState] = React.useState('');
  const c = useContext(pharmacyNumContext);

  const handleStateChange = (event: SelectChangeEvent) => {
    setSelectedState(event.target.value);
  };

  const handlePharmacyChange = (event: SelectChangeEvent) => {
    props.setPharmacy(event.target.value);
    props.setPharmacyId(props.data.find((element) => element.pharmacy == event.target.value).pharmacyId);
    let found = props.data.find((element) => element.pharmacy == event.target.value);
    let value = 0;
    let name = '';
    if (found != null) {
      value = found.pharmacyId;
      name = found.pharmacy;
    }

    let siteInfo = { siteNumber:value.toString(), siteName: name}
    c.setSiteInfo(JSON.parse(JSON.stringify(siteInfo)));
    sessionStorage.setItem(
      'siteInfo',
      JSON.stringify(siteInfo)
    );
  };

  const states = props.data.map((row) => row.state);

  function onlyUnique(value: any, index: any, array: any): any {
    return array.indexOf(value) === index;
  }

  // Will sort the pharmacies based on the 'state' variable
  function specificState(list: UserPharmacies[]): any {
    if (selectedState == '') {
      const pharmacies = list.map((row) => row.pharmacy);
      return pharmacies;
    } else {
      const pharmacies = list.filter((row) => row.state === selectedState).map((row) => row.pharmacy);
      return pharmacies;
    }
  }

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item sm={6} sx={{textAlign: 'end'}}>
        <FormControl sx={{minWidth: 180, textAlign: 'start'}}>
          <InputLabel id="select-state-label">Select State</InputLabel>
          <Select
            labelId="select-state-label"
            id="select-state"
            label="Select State"
            value={selectedState}
            onChange={handleStateChange}
            style={{ height: '55px', padding: '15px'}}
          >
            <MenuItem value="">Not Selected</MenuItem>
            {states
              .filter(onlyUnique)
              .sort((a, b) => a.localeCompare(b))
              .map((row, key) => (
                <MenuItem key={row} value={row}>{row}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={6} sx={{textAlign: 'start'}}>
        <FormControl sx={{minWidth: 180, width: 'auto'}}>
          <InputLabel id="select-pharmacy-label">Select Pharmacy</InputLabel>
          <Select
            labelId="select-pharmacy-label"
            id="select-pharmacy"
            label="Select Pharmacy"
            value={props.pharmacy}
            onChange={handlePharmacyChange}
            style={{ height: '55px', padding: '15px', paddingRight: '30px'}}
          >
            {specificState(props.data).map((row: any, key:number) => (
              <MenuItem key={row} value={row}>{row}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PharmacyForm;
