import { useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ConfirmationPageRxDTO } from '../../Interfaces/OrderRefills';
import pharmacyNumContext from '../Authorization/AuthContext';
import {getUser} from '../../actions/lib/auth.js';
import './ConfirmationPage.css';
import { formatDate } from '../PatientInformation/Helpers';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";
import { PrintButton } from '../../common/components/PrintButton';

export const OrderRefillConfirmationPage = () => {
    const location = useLocation();
    const data = location.state as ConfirmationPageRxDTO[];
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const dateFormat = `${month}/${date}/${year}`;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const context = useContext(pharmacyNumContext);
    const siteNum = context.siteInfo.siteNumber;
    const [userName, setUserName] = useState('');
    const printRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      getUser().then(user => {
        if(user!=null && user!=undefined){
          setUserName(user.profile.email)
        }
      });
    }, [])
     getUser().then(function(result) {return result?.profile.email})
  
    const timeFormat = today.toLocaleTimeString([], {hour:'numeric', minute: '2-digit', timeZoneName: 'short'});

    const handleChangePage = (event: any, newPage: number) => {
      setPage(newPage);
  };

  const handleChnageRowsPerPage = (event: { target: { value: string; }; }) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  };

  const getRowColor = (index: number) =>  {
    return index % 2 === 0 ? 'white' : '#FAFAFA';
  };
  
  return (
    <div className='pageContainer-orderConfirmation'>
          <div className='confirmationPage' ref={printRef}>
          <h1 className='pageTitle-confirmation'>Status of New Refill Requests</h1>
          <div className="confirmationContainer">
            <PrintButton printRef={printRef} />
            {/* User Information Box*/}
            <div style={{ width: 'fit-content', alignSelf: 'center' }}>
              <div className='infoBox'>
                <div className='infoRow'>
                  <div className='title-content'>
                    <div className='infoTitle'>Date</div>
                    <div className="infoValue">{dateFormat}</div>
                    <div className='infoTitle' style={{ marginTop: '15px'}}>Pharmacy Number</div>              
                    <div className="infoValue">{siteNum}</div>
                  </div>
                  <div className='title-content'>
                    <div className='infoTitle'>Time</div>
                    <div className='infoValue'>{timeFormat}</div>
                    <div className='infoTitle' style={{ marginTop: '15px'}}>User</div>
                    <div className='infoValue'>{userName}</div>
                  </div>
                  <div className='title-content' style={{ alignSelf: 'flex-start'}}>
                    <div className='infoTitle'>Number of Requests</div>
                    <div className="infoValue">{data.length}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Table style={{border: '1px solid #CBCCCD', marginTop: '30px'}}>
                <TableHead>
                  <TableRow className="table-header-row">
                        <TableCell className="table-header-title">Rx Number</TableCell>
                        <TableCell className="table-header-title">Event</TableCell>
                        <TableCell className="table-header-title">Patient Name</TableCell>
                        <TableCell className="table-header-title">Date of Birth</TableCell>
                        <TableCell className="table-header-title">Description</TableCell>
                        <TableCell className="table-header-title">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map((row, index) => (
                        <TableRow key={row.RxNumber} sx={{ backgroundColor: getRowColor(index)}}>
                            <TableCell>{row.RxNumber}</TableCell>
                            <TableCell>{row.Event}</TableCell>
                            <TableCell>{row.PatientName}</TableCell>
                            <TableCell>{formatDate(row.BDate.toString())}</TableCell>
                            <TableCell>{row.Description}</TableCell>
                            <TableCell>{row.Status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChnageRowsPerPage}
            />
          </div>
        </div>
    </div>
  );
};