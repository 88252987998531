import React, { useEffect, useRef, useState } from 'react';
import './autoSuggestion.css';
import { formatNDC } from '../../../Components/PatientInformation/Helpers';

interface AutoSuggestionProps {
    onSuggestionSelected: (suggestion: [string, string]) => void;
    setSelectedValue: (input: string) => void;
    searchType: string;
    isRequired: boolean;
    inputValue: string;
    setInputValue: (input: string) => void;
    fetchData: (input: string) => Promise<[string, string][]>; 
    placeholder: string;
    secondColNDC: boolean;
}

// Input Variables:
//    suggestions: Takes in two lists to be filtered and sorted through while patient is searching
//    onSuggestionSelected: function to handle returned [string, string] with the selected option
//    searchType: Specify type of search to coorelate with css file (patientSearch, drugSearch) 

// How to use component:
// import AutoSuggestion from './autoSuggestion';
// const suggestions: [string, string][] = [];
// const [selectedSuggestion, setSelectedSuggestion] = useState<[string, string]>(['', '']);;

// const handleSuggestionSelected = (suggestion: [string, string]) => {
//     setSelectedSuggestion(suggestion);
// };
// // All instances of 'field' can be changed to desired variable
// const handleField = (textValue) => {
//     setField(textValue)
// 
// const [FieldInputValue, setFieldInputValue] = useState('');

// <AutoSuggestion
//   suggestions={suggestions}
//   onSuggestionSelected={handleSuggestionSelected}
//   setTextValue={handleField}
//   searchType='drugSearch'
//   isRequired="false"
//   inputValue={fieldInputValue}
//   setInputValue={setFieldInputValue}
// />

const AutoSuggestion: React.FC<AutoSuggestionProps> = ({onSuggestionSelected, setSelectedValue, searchType, isRequired, inputValue, setInputValue, fetchData, placeholder, secondColNDC}) => {
    
    // Filtered suggestions
    const [filteredSuggestions, setFilteredSuggestions] = useState<[string, string][]>([]);
    const [displaySuggestions, setDisplaySuggestions] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<[string, string][]>([]);
    const [text, setText] = useState<string>(",");
    const suggestionBoxRef = useRef<HTMLDivElement | null>(null);

    // Handles any change in the text box (filters suggestions)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("Handling Change: filtering suggestions");
        // Updates text box value
        const input = event.target.value;
        setText(input);
        setInputValue(input);
        updateFilteredSuggestions(input);
    };

    const handleSuggestionClick = (suggestion: [string, string]) => {
        // Sets input box to selected suggestion
        setInputValue(suggestion[0]);
        setSelectedValue(suggestion[0]);

        // Clears suggestions when a selection is clicked
        setFilteredSuggestions([]); 
        setDisplaySuggestions(false);

        console.log('Selected ', {searchType}, ' ', suggestion)
        onSuggestionSelected(suggestion);
    };

    function updateFilteredSuggestions(textInput: string) {
        // Filter suggestions based on input (includes anything that holds that string)
        const filtered: [string, string][] = suggestions.filter(([name, number]) => name.toLowerCase().includes(textInput.toLowerCase()) || number.toLowerCase().includes(textInput.toLowerCase())
        ).map(([name, number]) => [name, number]);

        const sortedFiltered: [string, string][] = [...filtered].sort((a , b) => {
            const relevanceA = a[0].toLowerCase().startsWith(inputValue.toLowerCase()) || a[1].toLowerCase().startsWith(inputValue.toLowerCase()) ? 0 : 1;
            const relevanceB = b[0].toLowerCase().startsWith(inputValue.toLowerCase()) || b[1].toLowerCase().startsWith(inputValue.toLowerCase()) ? 0 : 1;

            if (relevanceA !== relevanceB) {
                return relevanceA - relevanceB;
            }

            return a[0].localeCompare(b[0]);
        })

        setFilteredSuggestions(sortedFiltered);
    };

    const handleClickOutside = (event: any) => {
        if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
            setDisplaySuggestions(false);
        }
    };

    const handleInputClick = () => {
        if (inputValue.length >= 3) {
            setDisplaySuggestions(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    useEffect(() => {
        if (text.length === 3 && !displaySuggestions) {
            const fetch = async () => {
                try {
                    const result = await fetchData(text);
                    console.log("Received Results");
                    setSuggestions(result);
                    if (result.length !== 0) {
                        setDisplaySuggestions(true);
                    }
                } 
                catch (error) {
                  console.error('Error fetching patient search information', error);
                }
            };
            fetch();
        }
        else if (text.length < 3) {
            setDisplaySuggestions(false);
        }

    }, [text]);

    useEffect(() => {
        if (displaySuggestions) {
            updateFilteredSuggestions(text);
        }
    }, [suggestions]);

    return (
        <div className='auto-suggestion-container' ref={suggestionBoxRef}>
           <input
            type='text'
            id={searchType}
            value={inputValue}
            onChange={handleChange}
            onClick={handleInputClick}
            required={isRequired}
            data-testid="Input"
            className='auto-suggestion-box'
            placeholder={placeholder}
            autoComplete='off'
            />
            {displaySuggestions && (
                <div className='suggestions-overlay'>
                    <ul data-testid="Suggestions">
                        {filteredSuggestions.map(([name, number], index) => (
                            <li key={index} onClick={() => handleSuggestionClick([name, number])}>
                                <div className='suggestion-row'>
                                    <div className='name-column'>{name}</div>
                                    <div className='number-column'>{secondColNDC ? formatNDC(number) : number}</div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default AutoSuggestion;