import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import { isAuthenticated, authenticateUser, setHistory } from '../../actions/index.js';
import { GetLogin } from '../../actions/UsersAPI';

interface LoginProps {
    setUserAuthenticated: (input: boolean | null) => void;
    setUser: (input: Oidc.User) => void;
}

const Login: React.FC<LoginProps> = ({ setUserAuthenticated, setUser })=> {
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [redirectPath, setRedirectPath] = useState('/');
    const [loginStatus, setLoginStatus] = useState<string>('');

    useEffect(() => {
        isAuthenticated().then(user => {
            if ((user && user.expired) || (!user && !redirectToReferrer)) {
                setHistory({ pathname: '/' });
                authenticateUser();
            }
            else if (user && !redirectToReferrer) {
                setUserAuthenticated(true);
                setUser(user);

                const fetch = async () => {
                    try {
                        const status = await GetLogin();
                        setLoginStatus(status);
                    }
                    catch (error) {
                        console.error('Error logging in:', error);
                    }
                };
                fetch();

            }
        });
    }, [])

    useEffect(() => {
        console.log('login:', loginStatus);
        if (loginStatus != '') {
            if (loginStatus.toLowerCase() == 'pending') {
                setRedirectPath('/secure/pending-registration');
            } else if (loginStatus.toLowerCase() == 'approved') {
                setRedirectPath('/');
            } else {
                setRedirectPath('/secure/registration');
            }
            setRedirectToReferrer(true);
        }
    }, [loginStatus])

    // If we were already logged in, try to redirect, 
    // if no redirect is defined, or if redirect value is the login page, go to the home page
    if (redirectToReferrer) {
        return (<Navigate to={{ pathname: redirectPath }} />)
    }
    else {
        return (
            <div>
                <p style={{ paddingLeft: '20px'}}> Redirecting to authentication server, </p>
                <p style={{ paddingLeft: '20px'}}> Please wait... </p>
            </div>
        )
    }
};

export default Login;

