import React, {useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {DataGrid, GridColDef, GridEventListener} from '@mui/x-data-grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import RefreshIcon from '@mui/icons-material/Refresh';
import './PatientProfile.css';
import { formatDate } from '../PatientInformation/Helpers';
import { PatientDetailDTO, PatientSearchResultsDTO } from '../../Interfaces/PatientDTOModel';
import '../../common/components/AutoSuggestion/SearchBox.css';
import AutoSuggestion from '../../common/components/AutoSuggestion/autoSuggestion';
import {buttonStyle, buttonStyleGray, CustomToggleButton} from './PatientProfileStyle';
import {fetchPatientDetails, fetchPatientSearch, fetchPatientSearchResults} from '../../actions/ProScriptAPI';
import { useNavigate } from 'react-router-dom';
import pharmacyNumContext from '../Authorization/AuthContext';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import { ErrorMessage } from '../../common/components/AlertMessages/AlertMessages';
import { ErrorMessages } from '../../Interfaces/ErrorMessages';

export const PatientProfile = () => {
  const [show, setShow] = useState(false); // visibility of results grid
  const [rows, setRows] = React.useState<PatientSearchResultsDTO[]>(() => [{name: '', account: '', bDate: '', sex: '', fac: ''}]);
  const [inputValue, setInputValue] = useState(''); // input box
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisbility, setErrorMessageVisibility] = useState(false);

  const context = useContext(pharmacyNumContext);
  const siteNum = context.siteInfo.siteNumber;

  const navigate = useNavigate();

  //consts for handling toggling search option
  const [searchBy, setSearchBy] = React.useState<string>('Name');
  const handleSearchBy = (
    event: React.MouseEvent<HTMLElement>,
    newSearchBy: string,
  ) => {
    if(newSearchBy!=null){
      setInputValue('');
      setSearchBy(newSearchBy);
    }
  };

  // Defining the headers for the grid
  const columns: GridColDef[] = [
    {field: 'name', headerName: 'Patient Name', flex: 3},
    {field: 'account', headerName: 'Account Number', flex: 3},
    {field: 'bDate', headerName: 'Date of Birth', flex: 3},
    {field: 'sex', headerName: 'Gender', flex: 2},
    {field: 'fac', headerName: 'Facility', flex: 4}
  ];

  // Function to be passed to the auto suggestion component (retrieves suggestions for the auto suggestion)
  async function fetchAutoSuggestionData(patName: string) : Promise<[string, string][]>  {
    try {
        
        const result : string[] = await fetchPatientSearch({patName, siteNum});
        const results: [string, string][] =  result.map((item) => [item, ""]);
        return results;
      } 
    catch (error) {
        console.error('Error fetching patient search', error);
        setErrorMessage("Error Searching for Patient. Please Try Again.");
        setErrorMessageVisibility(true);
      }
    return [["Try again", ""]];
  };

  // Handles clearing the text box
  const handleClear = () => {
    setInputValue('');
    setRows(() => [{name: '', account: '', bDate: '', sex: '', fac: ''}]);
    setShow(false);
    setShowLoading(false);
  };

  const handleErrorMessageClose = () => {
    setErrorMessageVisibility(false);
    setErrorMessage('');
  };

  // Handles when user selects a patient from the grid
  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const account = params.row.account;
    navigate('./patient-information', {state: { account } });
  };

  // Handles when a user selects a patient from the auto suggestion
  const handleSuggestionSelected = async (suggestion: [string, string]) => {
    setShow(false);
    setShowLoading(true);

    try {
      const patName = suggestion[0];
      const result : PatientSearchResultsDTO[] = await fetchPatientSearchResults({patName, siteNum});

      result.forEach(row => {
        row.bDate = formatDate(row.bDate.toString());
      })
      setRows(result);
      setShowLoading(false);
      setShow(true);
      setInputValue("");
    } 
    catch (error) {
      console.error('Error fetching patient search information', error);
      setShowLoading(false);
      setErrorMessage("Error Loading Patients. Please Try Again.");
      setErrorMessageVisibility(true);
    }
  };

  const handleChangeSearchBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Updates text box value
    const input = event.target.value;
    setInputValue(input);
  };

    const handleAccountSearch = async (event: React.MouseEvent) => {
        setShow(false);
        setShowLoading(true);

        try {
            if (inputValue.length > 0) {
                const result: PatientDetailDTO["patient"] | string = await fetchPatientDetails({ siteNum, account: inputValue });
                console.log(result);
                if (result != null && result.account != null) {
                    const accountResult: PatientSearchResultsDTO = { name: result.name, account: result.account.toString(), bDate: result.bDate.toString(), sex: result.sex, fac: result.fac.toString() }
                    accountResult.bDate = formatDate(accountResult.bDate);
                    setShowLoading(false);
                    setRows([accountResult]);
                    setShow(true);
                    setInputValue("");
                }
                else {
                    setShowLoading(false);
                    setRows([]);
                    setShow(true);
                    setInputValue("");
                    setErrorMessage("Patient Not Found. Please Try Again.");
                    setErrorMessageVisibility(true);
                }
            }
            else {
              setErrorMessage("Please Input a Patient Account. Ensure Only Numeric Values.");
              setErrorMessageVisibility(true);
              setShowLoading(false);
            }
        }
        catch (error)
        {
            const errorMsg = await error;
            if (errorMsg === ErrorMessages.noFacAccess) {
                setErrorMessage(errorMsg);
                setErrorMessageVisibility(true);
                setShow(true);
            }
            else {
                console.log("Error fetching patient information during search.");
                console.log("ERROR IS " + error);
                setErrorMessage("Error Locating Patient. Please Try Again.");
                setErrorMessageVisibility(true);
                setShow(true);
            }
            setShowLoading(false);
            setRows([]);
            setShow(true);
        }
    };

  return (
    // The search fields
    <>
      {errorMessageVisbility &&
        <ErrorMessage message={errorMessage} onClose={handleErrorMessageClose}/>
      }
      <Grid id='patientDisplay'>
      <Typography className='header'><b>Search Patient</b></Typography>
        <Grid container spacing={0} alignItems="end">
          <Grid id="toggleSpacing">
          <Typography className='searchWithHeader'><b>Search With</b></Typography>
          <ToggleButtonGroup
            value={searchBy}
            exclusive
            onChange={handleSearchBy}
            >
            <ToggleButton sx={CustomToggleButton} value="Name">
              Patient Name
            </ToggleButton>
            <ToggleButton data-testid="toggle-account-button" sx={CustomToggleButton} value="Account">
              Account Number
            </ToggleButton>
          </ToggleButtonGroup>
          </Grid>
          <Grid item xs={2}>
            {searchBy=='Name'?<Typography className='subheader'><b>Patient Name</b></Typography>:<Typography className='subheader'><b>Account Number</b></Typography>}
            {searchBy=='Name'?<AutoSuggestion
              onSuggestionSelected={handleSuggestionSelected}
              searchType="patientSearch"
              isRequired={true}
              setSelectedValue={() => {}}
              inputValue={inputValue}
              setInputValue={setInputValue}
              fetchData={fetchAutoSuggestionData}
              placeholder='Patient Name'
              secondColNDC={false}
            />:
            <input placeholder='Account Number' data-testid="accountInput" type='number' min="1" value={inputValue} onChange={handleChangeSearchBox} id='patientSearch'></input>
            }
            
          </Grid>
          <Grid container item xs={1.5} id = "searchSpacing">
            {searchBy=='Name'?
            <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
              <RefreshIcon/>&nbsp;Reset
            </Button>:
            <div id='buttonsPosition'>
            <Button variant='contained'sx={buttonStyle} onClick={handleAccountSearch}>Search</Button>
            <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
              <RefreshIcon/>&nbsp;Reset
            </Button>
            </div>
          }
          </Grid>
        </Grid>

      {/* The search results */}
      {show ? (
        <Box className='boxContainer'>
          <DataGrid
            data-testid="Datagrid"
            autoHeight
            sx={{'.MuiDataGrid-columnHeaderTitle': { 
              fontWeight: 'bold'},   mt: 6, '& .MuiDataGrid-row:hover':{cursor: 'pointer', backgroundColor: 'lightgray'}, '& .MuiDataGrid-columnHeader': {
              backgroundColor: "#f6f6f6"},borderBottom: 'none'}}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.account}
            onRowClick={handleRowClick}
            pagination
            pageSizeOptions={[5, 10, 20]}
          />
        </Box>
      ) : null}
      {showLoading &&
        <LoadingSpinner {...{ height:"200px", width:"50%"}}/>            
      }
    </Grid>
    </>
  );
};