import * as React from 'react';
import {RoutingComponent} from '@skyline/types';

import {NavItem} from './NavLinkContent';
import {Link} from '@mui/material';

export interface NavLinkProps extends NavItem {
  routingComponent: RoutingComponent;
  active: boolean;
  onNavigate?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const NavLink: React.FC<NavLinkProps> = ({title, path, items, id, routingComponent, active, onNavigate}) => {
  return (
    <Link
      component={routingComponent}
      href={path}
      onClick={onNavigate}
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: {xs: 'left', md: 'center'},
        fontSize: {xs: 16, md: 14.22},
        fontWeight: {xs: 700, md: 500},
        minWidth: 60,
        '&.MuiLink-root': {
          p: 2,
          color: active ? 'primary.main' : 'common.white',
          bgcolor: active ? 'common.white' : 'transparent',
          outlineOffset: -2,
          '&:hover': {color: active ? 'primary.main' : 'common.white'}
        }
      }}
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      data-testid={`navbar-${id}`}
    >
      {title}
    </Link>
  );
};
