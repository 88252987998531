import React, { useState, useEffect } from 'react';
import './PatientInformation.css';
import { DrugImageDTO } from '../../Interfaces/DrugImageDTOModels';
import { fetchDrugImage } from '../../actions/ProScriptAPI';
import PhotoIcon from '@mui/icons-material/Photo';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DrugImageOverlayComponentProps {
    onClose: () => void;
    ndc: string;
    drugDesc: string
}

export const DrugImageOverlayComponent: React.FC<DrugImageOverlayComponentProps> = ({ onClose, ndc, drugDesc}) => {
    const [drugImageDTO, setDrugImageDTO] = useState<DrugImageDTO>({drugName: "", drugImage: ""});
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result : DrugImageDTO = await fetchDrugImage(ndc);
                setDrugImageDTO(result);
            }
            catch (error) {
                console.error('Error fetching drug image', error);
                setDrugImageDTO({drugName: "Error. Please Try Again.", drugImage: "Error"});
            }
        };
        fetchData();
    }, []);

    // If user clicks outside of the overlay, overlay closes
    const handleOverlaysClick = (e: React.MouseEvent) => {
        if ((e.target as HTMLElement).classList.contains('overlay-patient')) {
          onClose();
        }
      };

    if (drugImageDTO.drugImage === "") {
        return (
            <div className='overlay-patient' onClick={handleOverlaysClick}>
                <div className='overlay-content-patient'>
                    <div className='overlay-top-none'>
                        <div className='title-patient'>Loading...</div>
                        <IconButton className='close-button' onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    }

    if (!drugImageDTO.drugImage) {
        return (
            <div className='overlay-patient' onClick={handleOverlaysClick}>
                <div className='overlay-content-patient'>
                    <div className='overlay-top-none'>
                        <div className='title-patient'>No Image Available</div>
                        <IconButton className='close-button' onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='overlay-patient' onClick={handleOverlaysClick}>
            <div className='overlay-content-patient'>
                <div className='overlay-top'>
                    <div className='title-patient'>{drugDesc}</div>
                    <IconButton className='close-button' onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                <img src={`data:image/jped;base64,${drugImageDTO.drugImage}`} alt={drugImageDTO.drugName} style={{padding: '20px'}}></img>
            </div>
        </div>
    );
};

interface RowProps {
    ndc: string;
    drugDesc: string;
}

// Displays overlay for drug image selected
export const DrugRow: React.FC<RowProps> = ({ ndc, drugDesc }) => {
    // Drug Image Overlay Controls
    const [DrugOverlayVisible, setDrugOverlayVisible] = useState(false);
    const [SelectedDrugImage, setSelectedDrugImage] = useState<string>("");

    const handleDrugOverlaysClick = (drugImage: string) => {
        setSelectedDrugImage(drugImage);
        setDrugOverlayVisible(!DrugOverlayVisible);
    };

    const handleDrugCloseOverlay = () => {
        setSelectedDrugImage("");
        setDrugOverlayVisible(false);
    };
    
    return (
        <>
        <IconButton>
            <PhotoIcon className='overlay-link-rx' onClick={(event) => handleDrugOverlaysClick(ndc)} />
        </IconButton>

            {DrugOverlayVisible && 
                <DrugImageOverlayComponent onClose={handleDrugCloseOverlay} ndc={SelectedDrugImage} drugDesc={drugDesc}/> 
            }
        </>
    );
};