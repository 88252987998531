import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './AlertMessages.css';
import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface AlertMessageProp {
    message: string;
    onClose: () => void;
}

export const ErrorMessage: React.FC<AlertMessageProp> = ({ message, onClose }) => {
    const [canClose, setCanClose] = useState(false);

    useEffect(() => {
        // Allow closing after 2 seconds
        const closeTimer = setTimeout(() => {
            setCanClose(true);
        }, 2000);

        // Close the alert after 10 seconds
        const timer = setTimeout(() => {
            onClose();
        }, 10000)

        const handleClickOutside = (event: MouseEvent) => {
            if (canClose) onClose();
        };

        document.addEventListener('click', handleClickOutside);

        return () => {clearTimeout(timer); clearTimeout(closeTimer);}
    }, [canClose]);

    return (
        <div className='alert-message-container'>
            <div className='error-message-content'>
                <ErrorIcon/>
                <div className='message-display' style={{ paddingRight: '30px'}}>{message}</div>
                <IconButton id='alert-msg-close-button' className='close-button' onClick={onClose}>
                    <CloseIcon style={{ color: '#C40000' }}/>
                </IconButton>
            </div>
        </div>
    )
}

export const SuccessMessage: React.FC<AlertMessageProp> = ({ message, onClose }) => {

    useEffect(() => {
        // Close the alert after 5 seconds
        const timer = setTimeout(() => {
            onClose();
        }, 5000)

        const handleClickOutside = (event: MouseEvent) => {
            onClose();
        };

        document.addEventListener('click', handleClickOutside);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className='alert-message-container'>
            <div className='success-message-content'>
                <CheckCircleIcon/>
                <div className='message-display'>{message}</div>
            </div>
        </div>
    )
}