import React, { useEffect, useState } from "react";
import { formatDate, formatTime } from "../PatientInformation/Helpers";
import { RxTrackingDTO } from "../../Interfaces/TrackingDTOModels";
import { fetchRxTracking } from "../../actions/ProScriptAPI";
import { LoadingSpinner } from "../../common/components/LoadingSpinner/LoadingSpinner";
import "./Tracking.css";
import { ErrorMessage } from "../../common/components/AlertMessages/AlertMessages";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomTooltip } from "./CustomToolTip";
import { ErrorMessages } from '../../Interfaces/ErrorMessages';

// Define an interface `RxTrackingProps` for the props of the RxTracking component
interface RxTrackingProps {
    RxNum: string,
    siteNum: string,
    submitCount: number
}

// Define the RxTracking component
export const RxTracking: React.FC<RxTrackingProps> = ({ RxNum, siteNum, submitCount}) => {
    const [rxTracking, setRxTracking] = useState<RxTrackingDTO['trackingInfo']>(null);
    const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showLoading, setShowLoading] = useState(true)


    // Fetch data when the submit count changes
    useEffect(() => {
        setShowLoading(true);
        setErrorMessageVisibility(false);
        setRxTracking(null);
        const fetchData = async () => {
            if (RxNum === "") {
                setShowLoading(false);
                setErrorMessageVisibility(true);
                setErrorMessage("Please Enter a Valid Rx Number.");
                return;
            }
            
            try {
                let RefillNum = "-1";
                // Fetch tracking information
                const result: RxTrackingDTO['trackingInfo'] = await fetchRxTracking({siteNum, RxNum, RefillNum}); 
                // Set the tracking information
                setRxTracking(result);
                if (result !== null && (result.rxNumber === "Does Not Exist" || result.rxNumber === null)) {
                    // Set error message visibility if the Rx number does not exist
                    setErrorMessageVisibility(true);
                    setErrorMessage("Unable to Locate Prescription. Please Verify Rx Number and Try Again.");
                }
                else if (result === null || result.rxNumber === "Error") {
                    setErrorMessageVisibility(true);
                    setErrorMessage("Error Locating Prescription. Please Try Again.\nInput Can Only Contain Digits.");
                }

            } catch (error) {
                setErrorMessageVisibility(true);
                const errorMessage = await error;

                if (errorMessage === ErrorMessages.noFacAccess) {
                    setErrorMessage(errorMessage);
                }
                else {
                    setErrorMessage("Error Locating Prescription. Please Try Again.\nInput Can Only Contain Digits.");
                    console.error('Error fetching Rx Tracking info', error);
                }
            }
        };
            fetchData();
    }, [submitCount])

    // Function to format the drug name and NDC number
    function formatDrug (ndc: string, drugName: string) {
        let drug: string = drugName;

        if (ndc.length !== 11) {
            drug += `(${ndc})` // Add the NDC number in parentheses if it's not 11 characters long
        }
        else {
            drug += ` (${ndc.substring(0, 5)}-${ndc.substring(5, 9)}-${ndc.substring(9, 11)})` // Add the NDC number with hyphens if it's 11 characters long
        }

        if (drug === " ()") return "-";
        else return drug;
    };

    useEffect(() => {
        if (errorMessageVisibility) {
            setShowLoading(false);
        }
        else if (!errorMessageVisibility) {
            if (errorMessage.length > 0)
                setShowLoading(false);
        }
    }, [errorMessageVisibility]);

    const handleErrorMessageClose = () => {
        // Function to set error message visibility to false
        setErrorMessageVisibility(false);
        setErrorMessage('');
    };

    const showLoadingStatus = () => {
        if (Number.isInteger(rxTracking?.rxNumber))
            return (
                <ErrorMessage message={"Unable to Locate Batch. Please Try Again."} onClose={handleErrorMessageClose} />
            );
        else if (rxTracking === null && showLoading) {
            return (
                <div>
                    <LoadingSpinner {...{ height: "200px", width: "50%" }} />
                </div>
            );
        }
        else if (errorMessageVisibility) {
            return (
                <ErrorMessage message={errorMessage} onClose={handleErrorMessageClose} />
            );
        }
        return null;
    };

    return (
        <div>
            {rxTracking == null || isNaN(parseInt(rxTracking.rxNumber)) ?
                showLoadingStatus() :
            <>
            <div className='info-box'>
                <div className='tracking-title'>Rx Information</div>
                <div className="result-container">
                    <div className="result-row">
                        <div className="result-title-row">Rx Number</div>
                        <div className="result-title-row">Patient Name</div>
                        <div className="result-title-row">Facility</div>
                    </div>
                    <div className="content-row">
                        <div className="result-content-row">{rxTracking.rxNumber}/{rxTracking.refill}</div>
                        <div className="result-content-row">{rxTracking.pat} - {rxTracking.patName}</div>
                        <div className="result-content-row">{rxTracking.fac} - {rxTracking.facName}</div>
                    </div>
                    <div className="result-row" style={{ marginTop: '10px'}}>
                        <div className="result-title-row">Drug Name</div>
                        <div className="result-title-row">Batch Number</div>
                        <div className="result-title-row">Current Status</div>
                    </div>
                    <div className="content-row" style={{ marginBottom: '10px'}}>
                        <div className="result-content-row">{formatDrug(rxTracking.ndc, rxTracking.drug)}</div>
                        <div className="result-content-row">{rxTracking.batch === "" ? "-" : rxTracking.batch}</div>
                        <div className="result-content-row">{rxTracking.status || '-'}</div>
                    </div>
                </div>
            </div>

            <div className='info-box' style={{ marginTop: '40px' }}>
                <div className='tracking-title'>Tracking Information</div>
                <div className="result-container">
                    <div className="result-row">
                        <div className="result-title-row">Request for Rx/Refill
                            <CustomTooltip title="Requested date for Rx/Refill.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                        <div className="result-title-row">Prescription Filled
                            <CustomTooltip title="Date when prescription was filled.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                        <div className="result-title-row">Drug/NDC Verification
                            <CustomTooltip title="Drug still in pharmacy and packaging complete.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                    </div>
                    <div className="content-row">
                        <div className="result-content-row">{formatDate(rxTracking.rxStep1Date)} {formatTime(rxTracking.rxStep1Time)}</div>
                        <div className="result-content-row">{formatDate(rxTracking.rxStep2Date)} {formatTime(rxTracking.rxStep2Time)}</div>
                        <div className="result-content-row">{formatDate(rxTracking.rxStep3Date)} {formatTime(rxTracking.rxStep3Time)}</div>
                    </div>
                    <div className="result-row" style={{ marginTop: '10px'}}>
                        <div className="result-title-row">Pharmacist Verification #1
                            <CustomTooltip title="Rx verified for correct information prior to packaging.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                        <div className="result-title-row">Pharmacist Verification #2
                            <CustomTooltip title="Rx complete and ready for shipping/delivery/pickup.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                        <div className="result-title-row">Placed in Tote
                            <CustomTooltip title="Shipping/Delivery option identified and assigned.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                    </div>
                    <div className="content-row">
                        <div className="result-content-row">{formatDate(rxTracking.rxStep4Date)} {formatTime(rxTracking.rxStep4Time)}</div>
                        <div className="result-content-row">{formatDate(rxTracking.rxStep5Date)} {formatTime(rxTracking.rxStep5Time)}</div>
                        <div className="result-content-row">{formatDate(rxTracking.rxStep6Date)} {formatTime(rxTracking.rxStep6Time)}</div>
                    </div>
                    <div className="result-row" style={{ marginTop: '10px'}}>
                        <div className="result-title-row">Tote Closed/Awaiting Pick-Up
                            <CustomTooltip title="Still in pharmacy waiting for delivery option pickup.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                        <div className="result-title-row">In Delivery
                            <CustomTooltip title="In transit either shipping/driver/courier.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                        <div className="result-title-row">Delivered
                            <CustomTooltip title="Time and date of actual delivery at home.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                    </div>
                    <div className="content-row" style={{ marginBottom: '10px'}}>
                        <div className="result-content-row">{formatDate(rxTracking.batchStep2Date)} {formatTime(rxTracking.batchStep2Time)}</div>
                        <div className="result-content-row">{formatDate(rxTracking.batchStep3Date)} {formatTime(rxTracking.batchStep3Time)}</div>
                        <div className="result-content-row">{formatDate(rxTracking.batchStep4Date)} {formatTime(rxTracking.batchStep4Time)}</div>
                    </div>
                    {rxTracking.status === "Picked Up" && 
                    <>
                        <div className="result-row" style={{ marginTop: '10px' }}>
                            <div className="result-title-row">Picked Up
                                <CustomTooltip title="Date of pickup at pharmacy.">
                                    <InfoOutlinedIcon fontSize='small'/>
                                </CustomTooltip>
                            </div>
                        </div>
                        <div className="content-row" style={{ marginBottom: '10px' }}>
                            <div className="result-content-row">{formatDate(rxTracking.pickUpDate)} {formatTime(rxTracking.pickUpTime)}</div>
                        </div>
                    </>
                    }
                </div>
            </div></>}
        </div>
    );
};