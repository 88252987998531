import {useEffect, useRef} from 'react';

import {Breakpoint, useMobile} from '../useMobile';

export const useBreakpointChangeEffect = (onChange: (matches: boolean) => void, breakpoint: Breakpoint = 'md') => {
  const isMounted = useRef(false);
  const isMobile = useMobile(breakpoint);

  useEffect(() => {
    // only trigger after mounted
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      onChange(isMobile);
    }
  }, [isMobile]);
};
