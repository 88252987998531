// helpers.tsx

import { DateTime } from 'luxon';

// Functin to format dates from YYYYMMDD
export const formatDate = (date: string) => {
    if (date.length !== 8) {
        return "-"
    }
    const year = parseInt(date.slice(0, 4));
    const month = parseInt(date.slice(4, 6), 10) - 1; // JS is zero-based for month
    const day = parseInt(date.slice(6, 8));

    const jsDate = new Date(year, month, day);
       
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    }).format(jsDate);

    return formattedDate;
};

// Function to format dates and time from Unix time
export const formatDateTime = (unixTime: string | null) => {

    if (unixTime === null) {        
        return "-";
    }

    if (unixTime === "") {
        return "-";
    }

    const date = new Date(parseInt(unixTime) * 1000);

    const formatted = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        timeZone: 'CST'
    }).format(date);
    
    return formatted;
};

export const formattedDateTime = (dateTime: string | null) => {
    if (dateTime === null || dateTime === "") {
        return "";
    }

    const dateTimeFormatted = new Date(dateTime).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        hourCycle: 'h12'
      });
  
      return dateTimeFormatted;
};

export const formatTime = (time: string | null) => {
    if (time === null || time === "") {
        return "";
    };

    time = time.padStart(6, '0');
    let hours = time.slice(0, -4);
    const minutes = time.slice(-4, -2);
    const seconds = time.slice(-2);

    const pstDateTime = DateTime.fromObject({
        hour: parseInt(hours),
        minute: parseInt(minutes),
        second: parseInt(seconds),
    }, {zone: 'America/Los_Angeles'});

    const localTime = pstDateTime.toLocal();
    const localTimeFormatted = localTime.toFormat('h:mm:ss a');

    return localTimeFormatted;
};

export function formatNDC (ndc: string) {
    if (ndc.length !== 11) {
        return `${ndc}`;
    }

    return `${ndc.substring(0, 5)}-${ndc.substring(5, 9)}-${ndc.substring(9, 11)}`
};