import {ApproveInterface, CreateUser, DenyInterface, RemoveAccessInterface, UserAccessDTO, UserSiteAccessInfoInterface, NewSiteRequestInterface} from '../Interfaces/Users';

const apiUrl = process.env['REACT_APP_NETRX_API'];
import { callApi } from './index.js';


export async function GetUserAccessesByUserId() {
  const response = await callApi(`${apiUrl}api/Users/GetUserSiteAccessInfo`, 'GET', null, null, true);

  const result: UserSiteAccessInfoInterface[] = await response;
  return result;
}
export async function GetAuthUser() {
  const result = await callApi(`${apiUrl}api/Users/GetUser`, 'GET', null, null, true);
  return result;
}

export async function PostRequestNewSiteAccess(siteRequestFields: NewSiteRequestInterface) {
  let body = JSON.stringify(siteRequestFields)
  return callApi(`${apiUrl}api/Users/RequestNewSite`, 'POST', {'content-type': 'application/json'}, body, true);
}

export async function PostRegistration(users: CreateUser) {
  let body = JSON.stringify(users)
  return callApi(`${apiUrl}api/Users/RegisterUser`, 'POST', {'content-type': 'application/json'}, body, true)
  .then();
}

export async function PostApproveRegistration(approveFields: ApproveInterface) {
  let body = JSON.stringify(approveFields)
  return callApi(`${apiUrl}api/Users/ApproveUserFacilityAccess`, 'POST', {'content-type': 'application/json'}, body, true)
  .then();
}

export async function PostDenyRegistration(denyFields: DenyInterface) {
  let body = JSON.stringify(denyFields)
  return callApi(`${apiUrl}api/Users/DenyUserSiteAccess`, 'POST', {'content-type': 'application/json'}, body, true)
  .then();
}

export async function PutRemoveAccess(removeFields: RemoveAccessInterface){
  let body = JSON.stringify(removeFields)
  return callApi(`${apiUrl}api/Users/RemoveSiteorFacAccess`, 'PUT', {'content-type': 'application/json'}, body, true)
}

export async function GetAllUserAccessByUserId() {
  const response = await callApi(`${apiUrl}api/Users/GetAllUserAccessByUserId`, 'GET', null, null, true);
  const result: UserAccessDTO[] = await response;
  return result;
}

export async function GetLogin() {
  const response = await callApi(`${apiUrl}api/Users/Login`, 'GET', null, null, true);
  const result:string = await response.data;
  return result;
}