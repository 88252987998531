import {createContext} from 'react';

export interface pharmacyNum {
  siteInfo: selectedPharmacyInfo
  userId: string;
  userName: string;
  setSiteInfo: (siteInfo: selectedPharmacyInfo) => void;
}

export interface selectedPharmacyInfo{
  siteNumber: number;
  siteName: string;
}

const pharmacyNumContext = createContext<pharmacyNum>({
  siteInfo: {siteNumber: 0, siteName: ''},
  setSiteInfo: () => null,
  userId: '',
  userName: ''
});
export default pharmacyNumContext;
