import React, { useState } from "react";
import "./Tracking.css";
import { PatientRxDTO } from "../../Interfaces/PatientDTOModel";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { RxTracking } from "./RxTracking";

interface PatRxTrackingProps {
    patData: PatientRxDTO["data"],
    siteNum: string,
    submitCount: number
}

export const PatRxTracking: React.FC<PatRxTrackingProps> = ({ patData, siteNum, submitCount}) => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel : any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (patData == null || patData.length < 1) {
        return (
            <p>No Prescriptions Found.</p>
        )
    };

    return (
        <div>
            <Typography className='searchWithHeader' style={{ fontWeight: 'bold', margin: '0px', marginBottom: '10px'}}>Prescription(s) for {patData[0]?.patName}</Typography>
            {(patData).map((row, index) => (
                <Accordion key={row.rxNum} style={{ border: '0.5px solid #F3F3F3'}} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                    <AccordionSummary 
                        expandIcon={<ExpandMoreOutlined />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        >
                        <Typography className='searchWithHeader'>{row.rxNum} - {row.drugDesc}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {expanded === `panel${index}` && 
                            <RxTracking RxNum={row.rxNum.toString()} siteNum={siteNum}  submitCount={submitCount}/>
                        }
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};