import React, { useEffect, useState } from "react";
import { DrugInteractionDTO, DrugSearch } from "../../Interfaces/DrugInteractionsDTOModels";
import { fetchDrugInteraction } from "../../actions/ProScriptAPI";
import './DrugInteraction.css'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton } from "@mui/material";
import { InteractionOverlay } from "./InteractionOverlay";
import { LoadingSpinner } from "../../common/components/LoadingSpinner/LoadingSpinner";

interface InteractionProps {
  drugList: DrugSearch[],
  setErrorMessage: (message: string) => void,
  setVisibility: (visibility: boolean) => void,
  setShowResults: (results: boolean) => void
}

export const ResultsList: React.FC<InteractionProps> = ({ drugList, setErrorMessage, setVisibility, setShowResults }) => {
  const [interactions, setInteractions] = useState<DrugInteractionDTO[]>([]); //Will use to store interaction results
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayInteraction, setOverlayInteraction] = useState<DrugInteractionDTO>({interactionID: "", drugName: "", onset: "", severity: "", documentation: "", documentationDetail: ""})
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (drugList.length < 2) {
        setInteractions([]);
        setErrorMessage("Please Select At Least 2 Drugs.");
        setVisibility(true);
        setShowResults(false);
    }
    else {
        fetchDrugData();
    }
  }, [drugList]);

  async function fetchDrugData() {
    setShowLoading(true);

    try {
        const result: DrugInteractionDTO[] = await fetchDrugInteraction(drugList);
        const sorted = [...result].sort((a, b) => prioritizeValue(a.severity.toUpperCase()) - prioritizeValue(b.severity.toUpperCase()));
        setInteractions(sorted);
        setShowLoading(false);
    }
    catch (error) {
        console.error('Error fetching drug interaction', error);
        setInteractions([]);
        setErrorMessage("Error Fetching Drug Interactions. Please Try Again.");
        setVisibility(true);
        setShowLoading(false);
        setShowResults(false);
    }
    setShowLoading(false);
  };

  function getSeverityColor (severity: string) {
    return 'black';
  }

  const prioritizeValue = (value: string) => {
    switch (value) {
      case 'MAJOR': 
        return 0
      case 'MODERATE':
        return 1
      case 'MINOR':
        return 2
      default:
        return 3
    }
  }

  const handleOverlaysClick = (selectedInteraction: DrugInteractionDTO) => {
    setOverlayInteraction(selectedInteraction);
    setShowOverlay(!showOverlay);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  }

  if (showLoading) {
    return (
      <div className='info-box' style={{marginTop: '40px'}}>
        <div className='drug-title'>Interaction Result</div>    
        <LoadingSpinner {...{ height:"200px", width:"50%"}}/>
      </div>
    )
  }

  return (
  <>
    {showOverlay && <InteractionOverlay interaction={overlayInteraction} onClose={handleCloseOverlay}/>}
    
    {!showLoading && 
    <div className='info-box' style={{marginTop: '40px'}}>
      <div className='drug-title'>Interaction Result</div>
      {interactions.length === 0 ? 
        <p className='drug-list'>No Interactions Found</p>
      : 
        <div className="result-container">
          <div className="result-row">
            <div className="result-title-row-long">Interacting Drugs</div>
            <div className="result-title-row-short">Onset</div>
            <div className="result-title-row-med">Documentation</div>
            <div className="result-title-row">Severity</div>
          </div>
          {interactions.map((item, index) => (
            <div className="result-row" key={item.interactionID}>
              <div className="result-content-row-long">
                <IconButton style={{paddingLeft: '0px'}} onClick={() => handleOverlaysClick(item)}>
                  <InfoOutlinedIcon style={{color: "#004C97"}}/>
                </IconButton>
                {item.drugName}
              </div>
              <div className="result-content-row-short">{item.onset}</div>
              <div className="result-content-row-med">{item.documentation}</div>
              <div className="result-content-row" style={{ color: getSeverityColor(item.severity.toUpperCase()) }}>{item.severity.toUpperCase()} DRUG TO DRUG INTERACTION</div>
            </div>
          ))}
        </div>
      }    
    </div>
    }
  </>
  );
}
