import React, { useEffect, useState } from "react";
import { formatDate, formatTime } from "../PatientInformation/Helpers";
import { BatchTrackingDTO } from "../../Interfaces/TrackingDTOModels";
import { fetchBatchTracking } from "../../actions/ProScriptAPI";
import { LoadingSpinner } from "../../common/components/LoadingSpinner/LoadingSpinner";
import "./Tracking.css";
import { ErrorMessage } from "../../common/components/AlertMessages/AlertMessages";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomTooltip } from "./CustomToolTip";
import { DHL_URL, FEDEX_URL, ONTRACK_URL, UPS_URL, USPS_URL } from "../../environment";
import { ErrorMessages } from '../../Interfaces/ErrorMessages';

interface RxTrackingProps {
    batchNum: string,
    siteNum: string,
    submitCount: number
}

export const BatchTracking: React.FC<RxTrackingProps> = ({ batchNum, siteNum, submitCount }) => {
    const [batchTracking, setBatchTracking] = useState<BatchTrackingDTO['trackingInfo']>(null);
    const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);
    const [trackNumLink, setTrackNumLink] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        setShowLoading(true);
        setErrorMessageVisibility(false);
        setBatchTracking(null);
        const fetchData = async () => {
            if (batchNum === "") {
                setShowLoading(false);
                setErrorMessage("Please Enter a Valid Batch Number.");
                setErrorMessageVisibility(true);
                return;
            }

            try {
                const result: BatchTrackingDTO['trackingInfo'] = await fetchBatchTracking({ siteNum, batchNum });
                setBatchTracking(result);

                console.log(result);

                if (result !== null && (result.batch === "Does Not Exist" || result.batch === null)) {
                    setErrorMessage("Unable to Locate Batch. Please Verify Batch Number and Try Again.");
                    setErrorMessageVisibility(true);
                }

            } catch (error) {
                setErrorMessageVisibility(true);
                const errorMessage = await error;
                if (errorMessage === ErrorMessages.noFacAccess) {
                    setErrorMessage(errorMessage);
                }
                else {
                    console.error('Error fetching Batch Tracking info', error);
                    setErrorMessage("Unable to Locate Batch. Please Try Again.");
                    setErrorMessageVisibility(true);
                }
            }
        };
        fetchData();
    }, [submitCount])

    useEffect(() => {
        if (batchTracking?.trackType === "2") {
            setTrackNumLink(`${FEDEX_URL}${batchTracking.trackingNum}`);
        }
        else if (batchTracking?.trackType === '1') {
            setTrackNumLink(`${DHL_URL}${batchTracking.trackingNum}`);
        }
        else if (batchTracking?.trackType === "3") {
            setTrackNumLink(`${UPS_URL}${batchTracking.trackingNum}`);
        }
        else if (batchTracking?.trackType === "5") {
            setTrackNumLink(`${ONTRACK_URL}${batchTracking.trackingNum}`);
        }
        else if (batchTracking?.trackType === "6") {
            setTrackNumLink(`${USPS_URL}${batchTracking.trackingNum}`);
        }
        else {
            setTrackNumLink("");
        }
    }, [batchTracking?.trackingNum])

    useEffect(() => {
        if (errorMessageVisibility) {
            setShowLoading(false);
        }
        else if (!errorMessageVisibility) {
            if (errorMessage.length > 0) {
                setShowLoading(false);
            }
        }
    }, [errorMessageVisibility]);

    const handleErrorMessageClose = () => {
        setErrorMessageVisibility(false);
        setErrorMessage("");
    };

    const showLoadingStatus = () => {
        if (Number.isInteger(batchTracking?.batch))
            return (
                <ErrorMessage message={"Unable to Locate Batch. Please Try Again."} onClose={handleErrorMessageClose} />
            );
        else if (batchTracking === null && showLoading) {
            return (
                <div>
                    <LoadingSpinner {...{ height: "200px", width: "50%" }} />
                </div>
            );
        }
        else if (errorMessageVisibility) {
            return (
                <ErrorMessage message={errorMessage} onClose={handleErrorMessageClose} />
            );
        }
        return null;
    };

    return (
        <div>
            {batchTracking == null || isNaN(parseInt(batchTracking.batch)) ? showLoadingStatus() :
            <>
            <div className='info-box'>
                <div className='drug-title'>Batch Information</div>
                <div className="result-container">
                    <div className="result-row">
                        <div className="result-title-row">Batch Number</div>
                        <div className="result-title-row">Current Status</div>
                        <div className="result-title-row">Tracking Number</div>
                    </div>
                    <div className="content-row">
                        <div className="result-content-row">{batchTracking.batch}</div>
                        <div className="result-content-row">{batchTracking.status}</div>
                        {trackNumLink === "" ? 
                            <div className="result-content-row">{batchTracking.trackingNum === "" ? "-" : batchTracking.trackingNum}</div> 
                            :
                            <a className="result-content-row" href={trackNumLink} target="_blank" rel="noopener noreferrer">{batchTracking.trackingNum}</a>
                        }   
                    </div>
                    <div className="result-row" style={{ marginTop: '10px'}}>
                        <div className="result-title-row">Number of Items</div>
                        <div className="result-title-row">Facility</div>
                        <div className="result-title-row"></div>
                    </div>
                    <div className="content-row" style={{ marginBottom: '10px'}}>
                        <div className="result-content-row">{batchTracking.itemCount}</div>
                        <div className="result-content-row">{batchTracking.fac} - {batchTracking.facName}</div>
                        <div className="result-content-row"></div>
                    </div>
                </div>
            </div>

            <div className='info-box' style={{ marginTop: '40px'}}>
                <div className='tracking-title'>Tracking Information</div>
                <div className="result-container">
                    <div className="result-row">
                        <div className="result-title-row">Opened
                            <CustomTooltip title="Rx complete and ready for shipping/delivery/pickup.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                        <div className="result-title-row">Closed
                            <CustomTooltip title="Still in pharmacy waiting for delivery option pickup.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                        <div className="result-title-row"></div>
                    </div>
                    <div className="content-row">
                        <div className="result-content-row">{formatDate(batchTracking.batchStep1Date)} {formatTime(batchTracking.batchStep1Time)}</div>
                        <div className="result-content-row">{formatDate(batchTracking.batchStep2Date)} {formatTime(batchTracking.batchStep2Time)}</div>
                        <div className="result-content-row"></div>
                    </div>
                    <div className="result-row" style={{ marginTop: '10px'}}>
                        <div className="result-title-row">In Delivery
                            <CustomTooltip title="In transit either shipping/driver/courier.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                        <div className="result-title-row">Delivered
                            <CustomTooltip title="Time and date of actual delivery at home or pickup at pharmacy.">
                                <InfoOutlinedIcon fontSize='small'/>
                            </CustomTooltip>
                        </div>
                        <div className="result-title-row"></div>
                    </div>
                    <div className="content-row" style={{ marginBottom: '10px'}}>
                        <div className="result-content-row">{formatDate(batchTracking.batchStep3Date)} {formatTime(batchTracking.batchStep3Time)}</div>
                        <div className="result-content-row">{formatDate(batchTracking.batchStep4Date)} {formatTime(batchTracking.batchStep4Time)}</div>
                        <div className="result-content-row"></div>
                    </div>
                </div>
            </div>

            <div className='info-box' style={{ marginTop: '40px'}}>
                <div className='tracking-title'>Details</div>
                <div className="result-container">
                    <div className="result-row">
                        <div className="result-title-row">Rx Number</div>
                        <div className="result-title-row">Patient Name</div>
                        <div className="result-title-row">Drug</div>
                    </div>
                    {batchTracking.rxInformation.map((item, index) => (
                        <div key={item.rxNum} className="content-row" style={{ marginBottom: '10px'}}>
                            <div key={item.rxNum} className="result-content-row">{item.rxNum}</div>
                            <div key={item.pat} className="result-content-row">{item.pat} - {item.patName}</div>
                            <div key={item.drug} className="result-content-row">{item.drug}</div>
                        </div>                    
                    ))}
                </div>
            </div></>}
        </div>
    );
};