import { PatientDetailDTO, PatientRxDTO } from "../Interfaces/PatientDTOModel";
import { RxTrackingDTO, BatchTrackingDTO, RxTracking1DTO, BatchTracking1DTO } from "../Interfaces/TrackingDTOModels";
import { DrugImageDTO, DrugImageInfoDTO } from '../Interfaces/DrugImageDTOModels';
import { RxAdditionalInfoDTO } from "../Interfaces/OrderRefills";
import { Users } from '../Interfaces/Users';
import { ProdigySites } from "../Interfaces/ProdigySites";
import { callApi } from './index.js';
import { DrugMonographDTO } from "../Interfaces/DrugMonographDTOModel";
import { DrugInteractionDTO, DrugSearch, DrugSearchDrugAPI } from "../Interfaces/DrugInteractionsDTOModels";


const apiUrl = process.env['REACT_APP_NETRX_API'];

interface PatientInfoComponentProps {
  siteNum: any;
  account: any;
}

interface PatientSearchComponentProps {
    siteNum: any
    patName: any
    facName?: any
}

interface RxTrackingComponentProps {
  siteNum: any;
  RxNum: any;
  RefillNum: any;
}
  
  interface BatchTrackingComponentProps {
    siteNum: any
    batchNum: any
}

interface RecentTrackingComponentProps {
    siteNum: any
}

interface PatientRxProps {
    SiteNum: any
    RxNum: any
}
  
  interface BatchTrackingComponentProps {
    siteNum: any
    batchNum: any
}

interface RecentTrackingComponentProps {
    siteNum: any
}
  
export async function fetchSites(state: string) {

  const response = await callApi(apiUrl + 'api/Users/GetSitesByState?state=' + state, 'GET', null, null, true);

  const result: ProdigySites[] = await response;
  return result;
}

interface PatientRxProps {
    SiteNum: any
    RxNum: any
}

export async function PostRegistration(users: Users) {
  let body = JSON.stringify(users)
  return callApi(`${apiUrl}api/Users/RegisterUser`, 'POST', {'content-type': 'application/json'}, body, true)
  .then();
}

export async function fetchPatientDetails({siteNum, account}: PatientInfoComponentProps) {
  const response = await callApi(apiUrl + 'api/ProScriptAPI_Patient/GetPatientDetail?storeNum=' + siteNum + '&account=' + account, 'GET', null, null, true);

  const result: PatientDetailDTO['patient'] = await response;
  console.log(`Patient details retrieved for Account ${account} in Site Number ${siteNum}`);
  return result;
}

export async function fetchPatientRx (siteNum: string, account: string, includeRefills: boolean, includeDirections: boolean) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Patient/GetPatientRx?storeNum=${siteNum}&account=${account}&includeRefills=${includeRefills}&includeDirections=${includeDirections}`, 'GET', null, null, true);

  const result : PatientRxDTO["data"] = await response;
  console.log(`Patient Rx retrieved for Account ${account} in Site Number ${siteNum}`)
  return result;
}

export async function fetchPatientSearch({ siteNum, patName}: PatientSearchComponentProps) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Patient/GetPatientSearch?name=${patName}&storeNum=${siteNum}`, 'GET', null, null, true);

  const result = await response;
  console.log(`Patient search retrieved for Name ${patName} in Site Number ${siteNum}`)
  return result;
}

export async function fetchPatientSearchResults({ siteNum, patName}: PatientSearchComponentProps) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Patient/GetPatientSearchResults?name=${patName}&storeNum=${siteNum}`, 'GET', null, null, true);

  const result = await response;
  console.log(`Patient search results retrieved for Name ${patName} in Site Number ${siteNum}`)
  return result;
}

export async function fetchRxTracking({ siteNum, RxNum, RefillNum}: RxTrackingComponentProps) {
  if (RefillNum == undefined) RefillNum = -1;
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Tracking/GetRxTracking?storeNum=${siteNum}&rxNum=${RxNum}&refillNum=${RefillNum}`, 'GET', null, null, true);

  const result : RxTrackingDTO["trackingInfo"] = await response;
  console.log(`Rx tracking retrieved for Rx Number ${RxNum} and Refill Number ${RefillNum}`)
  return result;
}

export async function fetchBatchTracking({ siteNum, batchNum}: BatchTrackingComponentProps) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Tracking/getBatchTracking?storeNum=${siteNum}&batchNum=${batchNum}`, 'GET', null, null, true);

  const result : BatchTrackingDTO["trackingInfo"] = await response;
  console.log(`Batch tracking retrieved for Batch Number ${batchNum} in Site Number ${siteNum}`)
  return result;
}

export async function fetchRecentRx({ siteNum }: RecentTrackingComponentProps) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Tracking/GetRecentRx?storeNum=${siteNum}`, 'GET', null, null, true);

  const result : RxTracking1DTO[] = await response;
  console.log(`Recent prescriptions retrieved for Site Number ${siteNum}`)
  return result;
}

export async function fetchRecentBatch({ siteNum }: RecentTrackingComponentProps) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Tracking/GetRecentBatch?storeNum=${siteNum}`, 'GET' , null, null, true);

  const result : BatchTracking1DTO[] = await response;
  console.log(`Recent batches retrieved for Site Number ${siteNum}`)
  return result;
}

export async function fetchDrugImage(ndc: string) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Drug/GetDrugImage?NDC=${ndc}`, 'GET', null, null, true);

  const result : DrugImageDTO = await response;
  console.log(`Drug Image retrieved for NDC ${ndc}`)
  return result;
}

export async function fetchDrugImageInfo(ndc: string) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Drug/GetDrugImageInfo?NDC=${ndc}`, 'GET', null, null, true);

  const result : DrugImageInfoDTO = await response;
  console.log(`Drug Image Info received for NDC ${ndc}`)
  return result;
}

export async function fetchRxInformation({SiteNum, RxNum} : PatientRxProps) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Patient/GetRxInfo?storeNum=${SiteNum}&rx=${RxNum}`, 'GET', null, null, true);

  const result : RxAdditionalInfoDTO = await response;
  console.log(`Rx information received for Rx Number ${RxNum} in Site Number ${SiteNum}`);
  return result;
}

export async function fetchDrugMonograph ( ndc: string, spanish: boolean) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Drug/GetDrugMonograph?NDC=${ndc}&Spanish=${spanish}`, 'GET', null, null, true);
  
  const result : DrugMonographDTO = await response;
  console.log(`Drug Monograph received for NDC ${ndc}`);
  return result;
}

export async function fetchDrugInteraction(drugs: DrugSearch[]): Promise<DrugInteractionDTO[]> {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Drug/GetDrugInteraction`, 'POST', {'content-type': 'application/json'}, JSON.stringify(drugs), true);
  const result :  DrugInteractionDTO[] = await response;
  console.log(`Drug Interaction received`);
  return result;
}

export async function fetchD2DPatientRx (siteNum: string, account: string) {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Drug/GetD2DPatientRx?storeNum=${siteNum}&account=${account}`, 'GET', null, null, true);

  const result : DrugSearch[] = await response;
  console.log(`D2D Patient Rx retrieved for Account ${account} in Site Number ${siteNum}`)
  return result;
}

export async function fetchDrugSearch( drugName: string, siteNum: number, group: boolean = true): Promise<DrugSearch[]> {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Drug/GetDrugSearch?drugName=${drugName}&storeNum=${siteNum}&groupDrugs=${group}`, 'GET', null, null, true);
  const result: DrugSearch[] = await response;
  console.log(`Drug search retrieved for Name ${drugName} in Site Number ${siteNum}`)
  return result;
}

export async function fetchDrugLookup( ndc: string, siteNum: any ): Promise<DrugSearch> {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Drug/GetDrugLookup?NDC=${ndc}&storeNum=${siteNum}`, 'GET', null, null, true);
  const result: DrugSearch = await response;
  console.log(`Drug Lookup retrieved for NDC ${ndc}`)
  return result;
}

export async function fetchPatientBatch({siteNum, account}: PatientInfoComponentProps) : Promise<string[]> {
  const response = await callApi(`${apiUrl}api/ProScriptAPI_Tracking/GetPatientBatch?storeNum=${siteNum}&account=${account}`, 'GET', null, null, true);
  const result: string[] = await response;
  console.log(`Patient Batches retrieved for Account ${account}`)
  return result;
}