'use client';

import {createTheme} from '@mui/material';
import {OptumDefault} from '@skyline/themes';

/**
 * Extend the OptumDefualt Theme for the Genoa Platform
 */
export const GenoaTheme = createTheme({
  ...OptumDefault,
  components: {
    ...OptumDefault.components,
  
    // MuiMenuList: {
    //   styleOverrides: {
    //     root: {
    //       // backgroundColor: '#e75480',
    //     }
    //   }
    // },
  
    // MuiCard: {
    //   styleOverrides: {
    //     root: {
    //       cardColor: '#F68D2E',
    //     }
    //   }
    // },

    // Link Overrides
    MuiLink: {
      ...OptumDefault?.components?.MuiLink,
      styleOverrides: {
        root: {
          color: OptumDefault.palette.text.primary,
          '&.has-icon': {
            display: 'flex',
            alignItems: 'center'
          },
          '&.show-active-border': {
            borderRadius: OptumDefault.spacing(0.5),
            borderLeft: `${OptumDefault.spacing(0.5)} solid transparent`
          },
          '&.is-bold': {
            fontWeight: 'bold'
          },
          '&.is-active': {
            color: OptumDefault.palette.primary.main,

            '&.show-active-border': {
              borderColor: OptumDefault.palette.primary.main
            }
          }
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#004C97', // genoa blue
          color: 'white',
          fontFamily: "Arial",
        }
      }
    },

    // Breadcrumbs Overrides
    MuiBreadcrumbs: {
      ...OptumDefault?.components?.MuiBreadcrumbs,
      styleOverrides: {
        li: {
          display: 'flex',
          alignItems: 'center',
          '& .is-bold': {
            color: OptumDefault.palette.primary.dark
          },
          '& .is-underlined': {
            textDecoration: 'underline'
          }
        }
      }
    }
  }
});