import React from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
    outlet: React.JSX.Element;
    userIsAuthenticated: boolean | null;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ userIsAuthenticated, outlet }) => {
    if (userIsAuthenticated) {
        return outlet;
    } else {
        return <Navigate to={{ pathname: '/login' }} />;
    }
}

export default PrivateRoute;
