import React from 'react';
import './Overlays.css';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface PatientOverlayComponentProps {
    onClose: () => void;
    items: string[]
    title: string
}

const PatientOverlayComponent: React.FC<PatientOverlayComponentProps> = ({ onClose, items, title }) => {

    // If user clicks outside of the overlay, overlay closes
    const handleOverlaysClick = (e: React.MouseEvent) => {
        if ((e.target as HTMLElement).classList.contains('overlay-patient')) { onClose(); }
    };

    return (
        <div className='overlay-patient' onClick={handleOverlaysClick}>
            <div className='overlay-content-patient'>
                <div className='overlay-top'>
                    <div className='title-patient'>{ title }</div>
                    <IconButton className='close-button' onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div style={{paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '5px'}}>
                    <ul>
                        {items.map((item, index) => (
                            <li key={items[index]}>{items[index]}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PatientOverlayComponent;