import React from 'react';
import { DrugMonographDTO } from '../../Interfaces/DrugMonographDTOModel';
import { PrintButton } from '../../common/components/PrintButton';

interface ResultsProps {
    printRef: React.RefObject<HTMLDivElement>, 
    monograph: DrugMonographDTO | undefined, 
    checkedSpanish: boolean,
    drugName: string
}

export const DrugMonographResults: React.FC<ResultsProps>  = ({printRef, monograph, checkedSpanish, drugName}) => {
    return (
        <div className='info-container' ref={printRef}>
            <div className='info-box'>
                <PrintButton printRef={printRef} />
                <div className='drug-title'>{drugName}</div>
                <div className="result-container">
                <div id='monograph-result-top'>
                    <p className='monograph-result-content'><span id='monograph-result-header'>{checkedSpanish ? 'Pronunciación:' : 'Pronunciation:'}</span> {monograph?.pronunciation}</p>
                    <p className='monograph-result-content'><span id='monograph-result-header'>{checkedSpanish ? 'Nombres Comerciales:' : 'Brand Names:'}</span> {monograph?.brandName}</p>
                </div>
                {monograph !== null && monograph?.warnings != "" &&
                    <p className='monograph-result-content'><span id='monograph-result-header'>{checkedSpanish ? 'Advertencia:' : 'Warning:'}</span> {monograph?.warnings}</p>
                }
                <div id='monograph-result1'>
                    <div id='monograph-result-header'>{checkedSpanish ? '¿Para qué se utiliza este medicamento?' : 'What is this drug used for?'}</div>
                    <div>
                        <ul>
                            {monograph?.drugIndications.map((drugList, index) => (
                                <li key={drugList[index]}>
                                    <div className='monograph-result-content'>{drugList}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div id='monograph-result1'>
                    <div id='monograph-result-header'>{checkedSpanish ? '¿Qué necesito decirle a mi médico ANTES de tomar este medicamento?' : 'What do I need to tell my doctor BEFORE I take this drug?'}</div>
                    <div>
                        <ul>
                            {monograph?.existingConditions.map((drugList, index) => (
                                <li key={drugList[index]}>
                                    <div className='monograph-result-content'>{drugList}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div id='monograph-result1'>
                    <div id='monograph-result-header'>{checkedSpanish ? '¿Qué debo saber o hacer mientras tomo este medicamento?' : 'What are some things I need to know or do while I take this drug?'}</div>
                    <div>
                        <ul>
                            {monograph?.thingsToKnow.map((drugList, index) => (
                                <li key={drugList[index]}>
                                    <div className='monograph-result-content'>{drugList}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div id='monograph-result1'>
                    <div id='monograph-result-header'>{checkedSpanish ? '¿Cuáles son algunos efectos secundarios comunes de este medicamento?' : 'What are some common side effects of this drug?'}</div>
                    <div>
                        <ul>
                            {monograph?.commonSideEffects.map((drugList, index) => (
                                <li key={drugList[index]}>
                                    <div className='monograph-result-content'>{drugList}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div id='monograph-result1'>
                    <div id='monograph-result-header'>{checkedSpanish ? '¿Cuáles son algunos efectos secundarios raros de este medicamento?' : 'What are some rare side effects of this drug?'}</div>
                    <div>
                        <ul>
                            {monograph?.rareSideEffects.map((drugList, index) => (
                                <li key={drugList[index]}>
                                    <div className='monograph-result-content'>{drugList}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div id='monograph-result1'>
                    <div id='monograph-result-header'>{checkedSpanish ? '¿Cuál es la mejor forma de tomar este medicamento?' : 'How is this drug best taken?'}</div>
                    <div>
                        <ul>
                            {monograph?.administrationInstructions.map((drugList, index) => (
                                <li key={drugList[index]}>
                                    <div className='monograph-result-content'>{drugList}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div id='monograph-result1'>
                    <div id='monograph-result-header'>{checkedSpanish ? '¿Qué debo hacer si no tomo una dosis?' : 'What do I do if I miss a dose?'}</div>
                    <div>
                        <ul>
                            {monograph?.missedDoseMitigation.map((drugList, index) => (
                                <li key={drugList[index]}>
                                    <div className='monograph-result-content'>{drugList}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div id='monograph-result1'>
                    <div id='monograph-result-header'>{checkedSpanish ? '¿Cómo almaceno o descarto este medicamento?' : 'How do I store and/or throw out this drug?'}</div>
                    <div>
                        <ul>
                            {monograph?.storageAndDisposal.map((drugList, index) => (
                                <li key={drugList[index]}>
                                    <div className='monograph-result-content'>{drugList}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div id='monograph-result1'>
                    <div id='monograph-result-header'>{checkedSpanish ? 'Datos Generales Sobre el Medicamento' : 'General Drug Facts'}</div>
                    <div>
                        <ul>
                            {monograph?.generalDrugFacts.map((drugList, index) => (
                                <li key={drugList[index]}>
                                    <div className='monograph-result-content'>{drugList}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div id='monograph-result1'>
                    <div id='monograph-result-header'>{checkedSpanish ? 'Uso de la información por el consumidor y exención de responsabilidad' : 'Consumer Information Use and Disclaimer'}</div>
                    <div className='monograph-result-content'>{monograph?.disclaimer}</div>
                </div>
            </div>
        </div>
    </div>
    )
};