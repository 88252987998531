import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {SelectChangeEvent} from '@mui/material/Select';
import {states, MenuOptions} from './MenuItems';
import {buttonStyle} from './ButtonStyle';
import {states as OfficialStates} from '../../States';
import {fetchSites} from '../../actions/ProScriptAPI';
import {GetAllUserAccessByUserId, PostRegistration} from '../../actions/UsersAPI';
import {ProdigySites} from '../../Interfaces/ProdigySites';
import {CreateUser, UserAccessDTO} from '../../Interfaces/Users';
import {MenuItem} from '@mui/material';
import {getUser} from '../../actions/lib/auth';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from '../../common/components/AlertMessages/AlertMessages';

export const RegistrationForm = ({setShowNavbar}: {setShowNavbar: any}) => {
  useLayoutEffect(() => {
    setShowNavbar(false);
  }, []);
  // Individual state hooks for each form field
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  // @ts-ignore
  const [role, setRole] = useState('');
  const [state, setState] = useState('');
  const [pharmacy, setPharmacy] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [pharmacies, setPharmacies] = useState<MenuOptions[]>([]);
  const isMounted = useRef(false);
  const [userAccess, setUserAccess] = useState<UserAccessDTO[] | null>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);
  const [registrationResponse, setRegistrationResponse] = useState<JSON | null>(null);
  const navigate = useNavigate();

  //const pharmacies: MenuOptions[] = [];

  //put the external list of states into menu option for states
  if (!isMounted.current) {
    //TODO in future sprint: add service to call api for pending refills for this user
    //and remove this mockup usage
    OfficialStates.forEach(function (element) {
      states.push({value: element, lable: element});
    });
  }
  useEffect(() => {
    isMounted.current = true;
    getUser().then((user) => {
      if (user != null && user != undefined) {
        setFirstName(user.profile.given_name!);
        setLastName(user.profile.family_name!);
        setUserId(user.profile.sub);
        setEmail(user.profile.email!);
      }
    });
  }, []);

  useEffect(() => {
    if (!userAccess) {
      const fetchData = async () => {
        try {
          const result : UserAccessDTO[] = await GetAllUserAccessByUserId();
          setUserAccess(result);
        }
        catch (error) {
          setUserAccess(null);
          console.error('Error fetching User Access information', error);
      }
      };
      fetchData();
    }
  }, [hasSubmitted]);

  useEffect(() => {
    if (hasSubmitted && userAccess && registrationResponse) {
      if (!showRegistrationForm(userAccess)) {
        // successful registrations would redirect users to the pending-registration page and prevent multiple registrations
        navigate('/secure/pending-registration');
      } else {
        setErrorMessage("Error in Registering");
        setErrorMessageVisibility(true);
        setHasSubmitted(false);
      }
    }
  }, [userAccess, registrationResponse]);

  const [stateError, setStateError] = useState(false);
  const [pharmacyError, setPharmacyError] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    switch (name) {
      case 'additionalDetails':
        setAdditionalDetails(value);
        break;
    }
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const {name, value} = event.target;
    switch (name) {
      case 'role':
        setRole(value);
        break;
      case 'state':
        setPharmacies([]);
        setState(value);
        setStateError(!value); // Set error if value is empty
        fetchSites(value).then((data) => {
          data.forEach(function (element: ProdigySites) {
            setPharmacies((pharmacies) => [
              ...pharmacies,
              {lable: element.siteName, value: element.siteNumber.toString()}
            ]);
          });
        });
        break;
      case 'pharmacy':
        setPharmacy(value);
        setPharmacyError(!value); // Set error if value is empty
        break;
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //validation to check if fields are empty
    setStateError(!state);
    setPharmacyError(!pharmacy);
    if (!state || !pharmacy) {
      return; // Prevent form submission if any of these fields are empty
    } else {
      //make the api registration post request
      //isInternal is false on this page because this form is for external user registration
      const users: CreateUser = {
        additionalNotes: additionalDetails,
        siteId: pharmacy
      };
      const response = PostRegistration(users);
      setRegistrationResponse(await response);
    }

    // Clear the form fields
    setState('');
    setPharmacy('');
    setAdditionalDetails('');

    setUserAccess(null);
    setHasSubmitted(true);
  };

  const handleErrorMessageClose = () => {
    setErrorMessageVisibility(false);
    setErrorMessage('');
  };

  function showRegistrationForm(userAccess: UserAccessDTO[]) {
    // check if the user access has at least one pending status
    // only show registration form if there is no pending status
    for (let i = 0; i < userAccess.length; i++) {
      if (userAccess[i]?.status === "Pending") {
        return false;
      }
    }
    return true;
  }

  if (!userAccess) {
    return (
      <LoadingSpinner {...{ height:"200px", width:"50%" }}/>
    );
  } else if (showRegistrationForm(userAccess)) {
    return (
      <div>
        {errorMessageVisibility &&
          <ErrorMessage message={errorMessage} onClose={handleErrorMessageClose}/>
        }
        <Container maxWidth="md">
          <Typography style={{color: 'black', fontSize: '24px', fontWeight: 'bold'}} textAlign="center" flexGrow={1} gutterBottom>
            Genoa Online NetRx Request Access Form
          </Typography>

          <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            {/* Account Information Section */}
            <Typography component="h2" variant="h6" sx={{mt: 4, mb: 2, fontWeight: 'fontWeightMedium'}} gutterBottom>
              Account Information
            </Typography>
            <Paper elevation={3} sx={{mb: 4, p: 4, borderRadius: '8px', backgroundColor: '#fff'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={firstName}
                    disabled
                    variant='filled'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={lastName}
                    disabled
                    variant='filled'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={email}
                    disabled
                    variant='filled'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="userId"
                    name="userId"
                    label="User ID"
                    value={userId}
                    disabled
                    variant='filled'
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={3} sx={{mb: 4, p: 4, borderRadius: '8px', backgroundColor: '#fff'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={stateError} required>
                    <InputLabel id="state-select-label">Select a State</InputLabel>
                    <Select
                      labelId="state-select-label"
                      id="state-select"
                      name="state"
                      value={state}
                      label="Select a State"
                      onChange={handleSelectChange}
                      style={{ height: '55px', padding: '15px'}}
                    >
                      {states.map((role: MenuOptions, index) => (
                        <MenuItem key={index} value={role.value}>
                          {role.lable}
                        </MenuItem>
                      ))}
                    </Select>
                    {stateError && <FormHelperText>Required</FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={pharmacyError} required>
                    <InputLabel id="pharmacy-select-label">Select Genoa Pharmacy</InputLabel>
                    <Select
                      labelId="pharmacy-select-label"
                      id="pharmacy-select"
                      name="pharmacy"
                      value={pharmacy}
                      label="Select the location of your Genoa Pharmacy"
                      onChange={handleSelectChange}
                      style={{ height: '55px', padding: '15px'}}
                    >
                      {pharmacies.map((role: MenuOptions) => (
                        <MenuItem key={role.value} value={role.value}>
                          {role.lable}
                        </MenuItem>
                      ))}
                    </Select>
                    {pharmacyError && <FormHelperText>Required</FormHelperText>}
                  </FormControl>
                  
                  
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="additionalDetails"
                    name="additionalDetails"
                    label="Additional Details"
                    multiline
                    rows={4}
                    value={additionalDetails}
                    onChange={handleInputChange}
                    inputProps={{
                      maxLength: 255
                  }}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Grid container justifyContent="center" sx={{mt: 2, mb: 10}}>
              <Button variant="contained" type="submit" sx={buttonStyle}>
                Register
              </Button>
            </Grid>
          </Box>
        </Container>
      </div>
    );
  }
  return (
    // external users have a pending user access and should be redirected to pending-registration
    <Navigate to={{ pathname: "/secure/pending-registration" }} />
  );
};
