import { useEffect, useState } from "react";
import { BatchTracking1DTO, RxTracking1DTO } from "../../Interfaces/TrackingDTOModels";
import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { FilterableRxTable } from "./RxTable";
import Typography from '@mui/material/Typography';
import "./LandingPage.css";
import { FilterableBatchTable } from "./BatchTable";
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
interface TablesProps {
    rxData: RxTracking1DTO[] | null;
    batchData: BatchTracking1DTO[] | null;
    noRxData: boolean;
    noBatchData: boolean;
}

const TabbedTable: React.FC<TablesProps> = ({ rxData, batchData, noRxData, noBatchData }) => {
    const [selectedTab, setSelectedTab] = useState("1");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    // Go to the tab that has data first
    useEffect(() => {
        if (batchData?.length === 0 && rxData != null && rxData.length > 0) {
            setSelectedTab("1");
        }
        else if (rxData?.length === 0 && batchData != null && batchData.length > 0) {
            setSelectedTab("2");
        }
    }, [rxData, batchData]);

    return (
        <Box sx={{ width: '100%', typography: 'body1', marginTop: 2, minWidth: "900px" }}>
            <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList className="Custom-TabList" onChange={handleTabChange} aria-label="Batch/Rx" >
                        <Tab className="Custom-Tab" label="Recent Prescriptions" value="1" />
                        <Tab label="Recent Batches" value="2" />
                    </TabList>
                </Box>
                {rxData != null && rxData.length > 0 &&
                    <TabPanel value="1" sx={{ padding: 0, paddingTop: 1, border: '1px solid #EEEEEE', borderTop: 'none', minWidth: "900px" }}>
                        <FilterableRxTable rxData={rxData}></FilterableRxTable>
                    </TabPanel> 
                }
                {rxData?.length === 0 && !noRxData &&
                    <TabPanel value="1" sx={{ padding: 0, paddingTop: 1, border: '1px solid #EEEEEE', borderTop: 'none', minWidth: "900px" }}>
                        <LoadingSpinner {...{ height: "200px", width: "50%" }} />
                    </TabPanel>
                }
                {noRxData === true &&
                    <TabPanel value="1" sx={{ padding: 0, paddingTop: 1, border: '1px solid #EEEEEE', borderTop: 'none', minWidth: "900px" }}>
                        <Typography>No recent prescriptions in the last 7 days.</Typography>
                    </TabPanel>
                }
                {batchData != null && batchData.length > 0 &&
                    <TabPanel value="2" sx={{ padding: 0, paddingTop: 1, border: '1px solid #EEEEEE', borderTop: 'none', minWidth: "900px" }}>
                        <FilterableBatchTable batchData={batchData}></FilterableBatchTable>
                    </TabPanel>
                }
                {batchData?.length === 0 && !noBatchData &&
                    <TabPanel value="2" sx={{ padding: 0, paddingTop: 1, border: '1px solid #EEEEEE', borderTop: 'none', minWidth: "900px" }}>
                        <LoadingSpinner {...{ height: "200px", width: "50%" }} />
                    </TabPanel>
                }
                {noBatchData === true &&
                    <TabPanel value="2" sx={{ padding: 0, paddingTop: 1, border: '1px solid #EEEEEE', borderTop: 'none', minWidth: "900px" }}>
                        <Typography>No recent batches in the last 7 days.</Typography>
                    </TabPanel>
                }
            </TabContext>
        </Box>
    );
};

export default TabbedTable;
