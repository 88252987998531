import React, { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import '../../App/app.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { buttonStyle, buttonStyleGray, CustomToggleButton } from '../PatientProfile/PatientProfileStyle';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../../common/components/AutoSuggestion/SearchBox.css';
import '../DrugInformationPage/DrugInformationOverylays.css';
import pharmacyNumContext from '../Authorization/AuthContext';
import { RxTracking } from './RxTracking';
import { BatchTracking } from './BatchTracking';
import AutoSuggestion from '../../common/components/AutoSuggestion/autoSuggestion';
import { PatientRxDTO, PatientSearchResultsDTO } from '../../Interfaces/PatientDTOModel';
import { formatDate } from '../PatientInformation/Helpers';
import { fetchPatientBatch, fetchPatientRx, fetchPatientSearch, fetchPatientSearchResults } from '../../actions/ProScriptAPI';
import { PatientSearchOverlay } from '../../common/components/PatientSearchOverlay';
import { PatRxTracking } from './PatRxTracking';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import { PatBatchTracking } from './PatBatchTracking';

export function TrackingPage() {
    // Get the site number from the pharmacyNumContext
    const context = useContext(pharmacyNumContext);
    const siteNum = context.siteInfo.siteNumber;
    // State variables for toggling different sections
    const [showBatch, setShowBatch] = useState(false);
    const [showRx, setShowRx] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [submitCount, setSubmitCount] = useState(0);
    const [showPatOverlay, setShowPatOverlay] = useState(false);
    const [patOverlayData, setPatOverlayData] = React.useState<PatientSearchResultsDTO[] | null>(() => [{name: '', account: '', bDate: '', sex: '', fac: ''}]);
    const [patientRx, setPatientRx] = useState<PatientRxDTO["data"]>([{acct: -1, rxNum: -1, patName: "", ndc: "", code: "", rxDate: 0, doct: 0, quanDisp: 0, maxFills: 0, lstRef: 0, dir: "", actualFills: 0, prAuthQuanAccum: 0, docName: "", drugDesc: "", directions: "", drugImage: "", fills: 0, refills: []}]);
    const [showPatientRx, setShowPatientRx] = useState(false);
    const [patientBatch, setPatientBatch] = useState<string[] | null>([]);
    const [patientName, setPatientName] = useState('');
    const [showPatientBatch, setShowPatientBatch] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    //consts for handling toggling search by option
    const [searchBy, setSearchBy] = React.useState<string>('Number');
    const handleSearchBy = (
        event: React.MouseEvent<HTMLElement>,
        newSearchBy: string,
    ) => {
        if(newSearchBy!=null){
            setInputValue('')
            setSearchBy(newSearchBy);
        }
    };

    //consts for handling toggling search with option
    const [searchFor, setSearchFor] = React.useState<string>('Prescription');
    const handleSearchFor = (
        event: React.MouseEvent<HTMLElement>,
        newSearchFor: string,
    ) => {
        if(newSearchFor!=null){
            setInputValue('')
            setSearchFor(newSearchFor);
        }
    };

    // Function to close patient overlay
    const handlePatientCloseOverlay = () => {
        setShowPatOverlay(false);
    };

    // Function to clear all input fields and toggle states
    const handleClear = () => {
        setInputValue('');
        setShowBatch(false);
        setShowRx(false);
        setShowPatientRx(false);
        setShowPatientBatch(false);
    };

    // Function to update input value
    const handleChangeSearchBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Updates text box value
        const input = event.target.value;
        setInputValue(input)
    };

    // Function to handle search button click
    const handleSearch = () => {
        // Increment submit count
        setSubmitCount((prevCount) => prevCount + 1);
        // Reset toggle states
        setShowRx(false);
        setShowBatch(false);
        setShowPatientRx(false);
        setShowPatientBatch(false);
 
        // Toggle states based on searchFor option
        if (searchFor === 'Prescription') {
            setShowRx(true);
        }
        else if (searchFor === 'Batch') {
            setShowBatch(true);
        }
    };

    // Handles when a user selects a patient from the auto suggestion
    const handleSuggestionSelected = async (suggestion: [string, string]) => {
        setShowPatOverlay(true);

        try {
            // Fetch patient search results
            const patName = suggestion[0];
            const result : PatientSearchResultsDTO[] = await fetchPatientSearchResults({patName, siteNum});

            // Format birth date
            result.forEach(row => {
                row.bDate = formatDate(row.bDate.toString());
            })
            setPatOverlayData(result);
        } 
        catch (error) {
            console.error('Error fetching patient search information', error);
        }
    };

    // Function to be passed to the auto suggestion component (retrieves suggestions for the auto suggestion)
    async function fetchAutoSuggestionData(patName: string) : Promise<[string, string][]>  {
        try {
            // Fetch patient search results
            const result : string[] = await fetchPatientSearch({patName, siteNum});
            const results: [string, string][] =  result.map((item) => [item, ""]);
            return results;
        } 
        catch (error) {
            console.error('Error fetching patient search', error);
        }
        return [["Try again", ""]];
    };

    async function searchRxInformation(account: string) {
        setShowRx(false);
        setShowBatch(false);
        setShowPatientRx(false);
        setShowPatientBatch(false);
        setPatientName(inputValue);
        setInputValue('');
        setPatOverlayData(null);
        setShowLoading(true);

        if (searchFor === "Prescription") {
            try {
                const result : PatientRxDTO["data"] = await fetchPatientRx(siteNum.toString(), account, false, false);
                setPatientRx(result);
                setShowLoading(false);
                setShowPatientRx(true);
            }
            catch (error) {
                setPatientRx(null);
                console.error('Error fetching patient rx information', error);
                setShowLoading(false);
            }
        }
        else if (searchFor === "Batch") {
            try {
                const result2 : string[] = await fetchPatientBatch({account, siteNum});
                setPatientBatch(result2);
                setShowLoading(false);
                setShowPatientBatch(true);
            }
            catch (error) {
                setPatientBatch(null);
                console.error('Error fetching patient rx information', error);
                setShowLoading(false);
            }
        }
    }

    return(
        <>
            {showPatOverlay && 
                <PatientSearchOverlay patientSearchResults={patOverlayData} onClose={handlePatientCloseOverlay} searchFunction={searchRxInformation} />
            }

            <div id='drug-layout'>
                <Grid id='patientDisplay' style={{ paddingBottom: '40px'}}>
                    <Typography className='header'><b>Tracking Details</b></Typography>
                        <Grid container spacing={0} alignItems="end">
                            <Grid id="toggleSpacing">
                                <Typography className='searchWithHeader'><b>Search For</b></Typography>
                                <ToggleButtonGroup
                                    value={searchFor}
                                    exclusive
                                    onChange={handleSearchFor}
                                    >
                                    <ToggleButton sx={CustomToggleButton} value="Prescription" style={{ minWidth: '110px'}}>
                                        Prescription
                                    </ToggleButton>
                                    <ToggleButton sx={CustomToggleButton} value="Batch" style={{ minWidth: '110px'}}>
                                        Batch
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid id="toggleSpacing">
                                <Typography className='searchWithHeader'><b>Search With</b></Typography>
                                <ToggleButtonGroup
                                    value={searchBy}
                                    exclusive
                                    onChange={handleSearchBy}
                                    >
                                    {searchFor=='Prescription' ?                                 
                                        <ToggleButton sx={CustomToggleButton} value="Number" style={{ minWidth: '125px'}}>
                                            Rx Number
                                        </ToggleButton> :
                                        <ToggleButton sx={CustomToggleButton} value="Number" style={{ minWidth: '125px'}}>
                                            Batch Number
                                        </ToggleButton>
                                        }
                                    <ToggleButton data-testid="toggle-account-button" sx={CustomToggleButton} value="PatName" style={{ minWidth: '125px'}}>
                                        Patient Name
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className='subheader'><b>{searchBy=='Number' ? searchFor=='Prescription' ? "Rx Number" : "Batch Number" : "Patient Name"}</b></Typography>
                                {searchBy=='Number' ? 
                                    <input placeholder={searchFor=='Prescription' ? 'Rx Number' : 'Batch Number'} value={inputValue} onChange={handleChangeSearchBox} id='patientSearch'></input>
                                : 
                                    <AutoSuggestion
                                        onSuggestionSelected={handleSuggestionSelected}
                                        searchType="patientSearch"
                                        isRequired={true}
                                        setSelectedValue={() => {}}
                                        inputValue={inputValue}
                                        setInputValue={setInputValue}
                                        fetchData={fetchAutoSuggestionData}
                                        placeholder='Patient Name'
                                        secondColNDC={false}
                                    />
                                }
                            </Grid>
                            <Grid container item xs={1.5} id = "searchSpacing">
                                {searchBy=='PatName'?
                                    <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
                                        <RefreshIcon/>&nbsp;Reset
                                    </Button>
                                :
                                    <div id='buttonsPosition'>
                                        <Button variant='contained'sx={buttonStyle} onClick={handleSearch}>Search</Button>
                                        <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
                                            <RefreshIcon/>&nbsp;Reset
                                        </Button>
                                        </div>
                                }
                            </Grid>
                        </Grid>
                    <div style={{ marginBottom: '20px', marginTop: '40px' }}>
                        {showRx && <RxTracking siteNum={siteNum.toString()} RxNum={inputValue} submitCount={submitCount}/>}
                        {showBatch && <BatchTracking siteNum={siteNum.toString()} batchNum={inputValue} submitCount={submitCount} />}
                        {showPatientRx && <PatRxTracking patData={patientRx} siteNum={siteNum.toString()} submitCount={submitCount}/>}
                        {showPatientBatch && <PatBatchTracking  patData={patientBatch} patName={patientName} siteNum={siteNum.toString()} submitCount={submitCount}/>}
                        {showLoading && <LoadingSpinner {...{ height:"200px", width:"50%"}}/>}
                    </div>
                </Grid>
            </div>
        </>
    );
}