import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Button, Grid} from '@mui/material';
import { getUser } from '../../../../actions/lib/auth.js';
import Oidc from 'oidc-client';

interface RoutesPageProps {
  onSignOutClick: () => void;
  onChangePharmacyClick: () => void;
  onAccountClick: () => void;
  name: string | undefined;
  onUserRequestClick: () => void;
}

export const ProfileButton: React.FC<RoutesPageProps> = ({
  onSignOutClick,
  onChangePharmacyClick,
  onAccountClick,
  name,
  onUserRequestClick
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [admin, setAdmin] = React.useState(false);
  // @ts-ignore
  const [user, setUser] = React.useState<Oidc.User>();
  const NetRxAdminRole = "NetRxAdmin"

  React.useEffect(() => {
    getUser().then(user => {
      if ((user && !user.expired)) {
        setUser(user);
      }
      const userprofile = user?.profile;
      if(userprofile!=null){
      if(userprofile["role"]){
        const adminFound = userprofile["role"]===NetRxAdminRole
        setAdmin(adminFound)
      }
    }
    });
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container justifyContent={'flex-end'}>
      <Grid item>{/* profile picture if used in future */}</Grid>
      <Grid item>
        <Button
          sx={{color: 'black', bgcolor: 'transparent', textTransform: 'none', textSizeAdjust: '100'}}
          style={{fontSize: '18px', fontWeight: 'normal'}}
          onClick={handleClick}
        >
          {name}
        </Button>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {<MenuItem onClick={onAccountClick}>My Account</MenuItem>}
          {<MenuItem onClick={onChangePharmacyClick}>Change Pharmacy</MenuItem>}
          {<MenuItem 
          component = "a"
          href={process.env.REACT_APP_BASE_PATH + "/UserGuide.pdf"}
          target="_blank"
          rel="noopener noreferrer"
            >
              Download User Guide
          </MenuItem>}
          {admin &&           
            <MenuItem onClick={onUserRequestClick}>User Requests</MenuItem>
          }
          <MenuItem onClick={onSignOutClick}>Sign Out</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};
