// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-container {
    margin-top: 40px;
    border: none;
}

.info-box {
    margin-top: 5px;
    position: relative;
    border: 1px solid #CCCCCC;
    padding: 20px;
    border-radius: 5px;
    padding-bottom: 0px;
    background-color: white;
}

.drug-title {
    font-size: 20px;
    font-weight: bold;
    color: #002677;
    position: absolute;
    top: -15px;
    left: 15px;
    background-color: white;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 2px;
}

#drug-layout {
    margin-bottom: 50px;
    width: 100%;
}

.printButton {
    position: absolute !important;
    top: 10px;
    right: 40px;
}

.selected-tab {
    color: #004C97 !important;
    font-weight: bold !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/DrugInformationPage/DrugInformationOverylays.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,6BAA6B;IAC7B,SAAS;IACT,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;AAChC","sourcesContent":[".info-container {\n    margin-top: 40px;\n    border: none;\n}\n\n.info-box {\n    margin-top: 5px;\n    position: relative;\n    border: 1px solid #CCCCCC;\n    padding: 20px;\n    border-radius: 5px;\n    padding-bottom: 0px;\n    background-color: white;\n}\n\n.drug-title {\n    font-size: 20px;\n    font-weight: bold;\n    color: #002677;\n    position: absolute;\n    top: -15px;\n    left: 15px;\n    background-color: white;\n    padding-right: 10px;\n    padding-left: 10px;\n    padding-top: 2px;\n}\n\n#drug-layout {\n    margin-bottom: 50px;\n    width: 100%;\n}\n\n.printButton {\n    position: absolute !important;\n    top: 10px;\n    right: 40px;\n}\n\n.selected-tab {\n    color: #004C97 !important;\n    font-weight: bold !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
