import { useNavigate } from 'react-router-dom';
import React, {useContext, useEffect, useState} from 'react';
import pharmacyNumContext from '../Authorization/AuthContext';
import { ConfirmationPageRxDTO, OrderRefillProcessResponse, OrderRefillsDTO, OrderRefillsPageDTO, RxAdditionalInfoDTO } from '../../Interfaces/OrderRefills';
import { fetchPatientRx, fetchPatientSearch, fetchPatientSearchResults, fetchRxInformation } from '../../actions/ProScriptAPI';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { buttonStyle, buttonStyleGray, CustomToggleButton } from '../PatientProfile/PatientProfileStyle';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import '../../common/components/AutoSuggestion/SearchBox.css';
import './OrderRefills.css'
import { addOrderRefill, deleteOrderRefill, fetchAllOrderRefillsByUsername, processOrderRefill } from '../../actions/OrderRefill';
import { ErrorMessage, SuccessMessage } from '../../common/components/AlertMessages/AlertMessages';
import { PatientRxDTO, PatientSearchResultsDTO } from '../../Interfaces/PatientDTOModel';
import { RxListOverlay } from './RxListOverlay';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import AutoSuggestion from '../../common/components/AutoSuggestion/autoSuggestion';
import { formatDate } from '../PatientInformation/Helpers';
import { PatientSearchOverlay } from '../../common/components/PatientSearchOverlay';
import { ErrorMessages } from '../../Interfaces/ErrorMessages';

export const OrderRefill = () => {
    const navigate = useNavigate();
    
    const context = useContext(pharmacyNumContext);
    const siteNum = context.siteInfo.siteNumber;
    const userName = context.userName;

    const [show, setShow] = useState(false); // visibility of results grid
    const [resultRows, setResultRows] = React.useState<RxAdditionalInfoDTO[]>(() => [{rxNum: '', doctName: '', pharmacy: '', account: '', patName: '', quantity: '', daySupply: '', additionalNotes: '', directions: '', drugName: '', discontinued: false}]);
    const [inputValue, setInputValue] = useState(''); // input box
    const [rxList, setRxList] = useState<OrderRefillsPageDTO[]>([]);
    const [rxListVisibility, setRxListVisibility] = useState(false);
    const [erroMessage, setErrorMessage] = useState('');
    const [errorMessageVisbility, setErrorMessageVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [successMessageVisbility, setSuccessMessageVisibility] = useState(false);
    const [showPatInfo, setShowPatInfo] = useState(false); // show DOB & Gender (only when searching by patient)

    const [showPatOverlay, setShowPatOverlay] = useState(false);
    const [patOverlayData, setPatOverlayData] = React.useState<PatientSearchResultsDTO[] | null>(() => [{name: '', account: '', bDate: '', sex: '', fac: ''}]);
    const [patOverlayDataCopy, setPatOverlayDataCopy] = React.useState<PatientSearchResultsDTO[] | null>(() => [{ name: '', account: '', bDate: '', sex: '', fac: '' }]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        const fetchInitialData = async () => {
          try {
            const response: OrderRefillsPageDTO[] = await fetchAllOrderRefillsByUsername(siteNum.toString());
            setRxList(response);
          } 
          catch (error) {
            setErrorMessageVisibility(true);
            console.error('Error fetching pending order refills', error);
            setRxList([]);
          }
        };
    
        fetchInitialData();
      }, [userName]);

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenOverlay = () => {
        setRxListVisibility(true);
    };

    const handleCloseOverlay = () => {
        setRxListVisibility(false);
    };

    //consts for handling toggling search option
    const [searchBy, setSearchBy] = React.useState<string>('RxNumber');
    const handleSearchBy = (
        event: React.MouseEvent<HTMLElement>,
        newSearchBy: string,
    ) => {
        if(newSearchBy!=null){
            setInputValue('');
            setSearchBy(newSearchBy);
        }
    };

    // Handles clearing the text box
    const handleClear = () => {
        setInputValue('');
        setResultRows(() => [{rxNum: '', doctName: '', pharmacy: '', account: '', patName: '', quantity: '', daySupply: '', additionalNotes: '', directions: '', drugName: '', discontinued: false}]);
        setShow(false);
        setShowLoading(false);
    };

    const handleChangeSearchBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Updates text box value
        const input = event.target.value;
        setInputValue(input);
    };

    const handleRxSearch = async (event: React.MouseEvent) => {
        setShowLoading(true);
        setShow(false);
        setResultRows([]);
        setPage(0);
        setShowPatInfo(false);

        try {
            if (inputValue.length > 0) {
                const result : RxAdditionalInfoDTO = await fetchRxInformation({ SiteNum: siteNum, RxNum: inputValue });
                if (result != null && result.rxNum != null) {
                    if (result.discontinued === true) {
                        setErrorMessage(`Rx ${result.rxNum} Has Been Discontinued`);
                        setErrorMessageVisibility(true);
                        setShowLoading(false);
                        setShow(false);
                        return;
                    }
                    else {
                        const resultArray = [result];
    
                        setShowLoading(false);
                        setResultRows(resultArray);
                        setShow(true);
        
                        return;
                    }
                }
                else {
                    setShowLoading(false);
                    setResultRows([]);
                    setShow(true);
                    return;
                }
            }
            else {
                setErrorMessage("Please Input a Rx Number. Ensure Only Numeric Values.");
                setErrorMessageVisibility(true);
                setShowLoading(false);
            }
        }
        catch (error) {
            const errorMsg = await error;
            if (errorMsg === ErrorMessages.noFacAccess) {
                setErrorMessage(errorMsg);
                setErrorMessageVisibility(true);
                setShowLoading(false);
                setResultRows([]);
                setShow(true);
            }

            else {
                console.log("Error fetching rx information during search.");
                console.log("ERROR IS " + error);
                setShowLoading(false);
                setErrorMessage(ErrorMessages.genericError);
                setErrorMessageVisibility(true);
                setResultRows([]);
                setShow(true);
            }
            return;
        }
    };

    const handlePatientRxSearch = async (account: string) => {
        setPatOverlayDataCopy(patOverlayData);
        setShowLoading(true);
        setShow(false);
        setResultRows([]);
        setPatOverlayData(null);
        setPage(0);
        setShowPatInfo(true);

        try {
            const result : PatientRxDTO["data"] = await fetchPatientRx(siteNum.toString(), account, false, true);
            if (result != null) {
                const result2: RxAdditionalInfoDTO[] = result.map(rx => {
                    return { rxNum: rx.rxNum.toString(), doctName: rx.docName, pharmacy: "", account: rx.acct.toString(), patName: rx.patName, quantity: rx.quanDisp.toString(), daySupply: rx.fills.toString(), additionalNotes: "", directions: rx.directions, drugName: rx.drugDesc, discontinued: false }
                });

                setShowLoading(false);
                setResultRows(result2);
                setShow(true);
            }
            else {
                setShowLoading(false);
                setResultRows([]);
                setShow(true);
            }
            return;
        }
        catch(error) {
            setErrorMessage("Error Fetching Patient Rx Information. Please Try Again.");
            setErrorMessageVisibility(true);
            console.log("Error fetching rx information during patient search.");
            setShowLoading(false);
            setResultRows([]);
            setShow(true);
            return;
        }
    };

    // Handles when a user selects a patient from the auto suggestion
    const handleSuggestionSelected = async (suggestion: [string, string]) => {
        setShowPatOverlay(true);

        try {
            const patName = suggestion[0];
            const result : PatientSearchResultsDTO[] = await fetchPatientSearchResults({patName, siteNum});

            result.forEach(row => {
                row.bDate = formatDate(row.bDate.toString());
            })
            setPatOverlayData(result);
        } 
        catch (error) {
            setErrorMessage("Error Fetching Patient Information. Please Try Again.");
            setErrorMessageVisibility(true);
            console.error('Error fetching patient search information', error);
        }
    };

    // Function to be passed to the auto suggestion component (retrieves suggestions for the auto suggestion)
    async function fetchAutoSuggestionData(patName: string) : Promise<[string, string][]>  {
        try {
            console.log("Fetching data: patient profile");
            const result : string[] = await fetchPatientSearch({patName, siteNum});
            const results: [string, string][] =  result.map((item) => [item, ""]);
            return results;
        } 
        catch (error) {
            setErrorMessage("Error Fetching Patient Search. Please Try Again.");
            setErrorMessageVisibility(true);
            console.error('Error fetching patient search', error);
        }
        return [["Try again", ""]];
    };

    // Handles Proccess Prescriptions Button
    const handleProcessPrescriptions = async (event: React.MouseEvent) => {
        event.preventDefault();
        if (rxList.length <= 0) {
            setErrorMessage('There Are No Refills Selected to Process. Please Try Again.');
            setErrorMessageVisibility(true);
        }
        else {
            try {

            // Create variables for API request
            const orderRefills : OrderRefillsDTO[] = rxList.map(orderRefill => {
                return {StoreNum: siteNum, Username: userName, Rx: orderRefill.rxNum, IsStat: orderRefill.isStat}
            });

            // Make API call
            const result : OrderRefillProcessResponse[] = await processOrderRefill(orderRefills);

            // Convert to confirmation page variables (ensuring all refills were accounted for)
            const data : ConfirmationPageRxDTO[] = rxList.map(orderRefill => {
                const response = result.find(rx => rx.rxNum === orderRefill.rxNum);
                let event = 'Refill Request'
                if (orderRefill.isStat) event = 'Stat Refill Request';

                let status = 'Unable to Process Request';
                if (response?.status) status = response.status;

            return {RxNumber: orderRefill.rxNum, Event: event, PatientName: orderRefill.patName, BDate: orderRefill.patBdate, Description: orderRefill.rxDesc, Status: status}
            });

            setResultRows([]);
            setInputValue('');
            setRxList([]);
            setShow(false);
            navigate('confirmation', {state: data});
            }
            catch (error) {
                console.error('Error processing order refills', error);
            }
        }
    };

    const handleErrorMessageClose = () => {
        setErrorMessageVisibility(false);
        setErrorMessage('');
    };

    const handleSuccessMessageClose = () => {
        setSuccessMessageVisibility(false);
        setSuccessMessage('');
    };

    const getRowColor = (index: number) => {
        return index % 2 === 0 ? 'white' : '#FAFAFA';
    };

    const handleAddButton = async (rx: string, event: any) => {    
        const numberPattern = /^[0-9]+$/;
        let isStat = false;
        let checkbox = event.currentTarget.closest('tr').querySelector('input[type="checkbox"');

        if (checkbox.checked) {
            console.log("Is Stat");
            isStat = true;
        }
    
        if (rx !== "" && numberPattern.test(rx)) { 
          try {
            const result : OrderRefillsPageDTO = await addOrderRefill({StoreNum: siteNum, Username: userName, Rx: rx, IsStat: isStat});
            
            // Handles if the user cannot make this request
            if (result.status === 'Valid') {
                setRxList([...rxList, result]);
                
                setSuccessMessage("Order Refill Has Been Added to Pending Submission List");
                setSuccessMessageVisibility(true);
            } else {
                setErrorMessage(result.status);
                setErrorMessageVisibility(true);
            }
          } catch (error) {
            console.error('Error adding order refill', error);
            setErrorMessage('Error Adding Order Refill');
            setErrorMessageVisibility(true);
          }
        }
        else {
          setErrorMessage("Please Type a Rx Number to Refill (Ensure only Numeric Values)");
          setErrorMessageVisibility(true);
        }
      };

    // Handles removing one order refill
    const handleRemove = (rxNumber: string, event: React.MouseEvent) => {
        event.preventDefault();

        const orderRemove = rxList.find(order => order.rxNum === rxNumber);

        try {
            if (orderRemove !== undefined) {
            const result = deleteOrderRefill({StoreNum: siteNum, Username: userName, Rx: orderRemove.rxNum, IsStat: orderRemove.isStat});
            console.log(result);
            setRxList(rxList.filter((item) => item.rxNum !== rxNumber));
        }
        }
        catch (error) {
            console.error('Error deleting order refill', error);
            setErrorMessage("Error Removing Order Refill");
            setErrorMessageVisibility(true);
        }
    };

    const handlePatientCloseOverlay = () => {
        setShowPatOverlay(false);
    };

    return (
    <>
        {errorMessageVisbility && 
            <ErrorMessage message={erroMessage} onClose={handleErrorMessageClose}/>
        }
        {rxListVisibility && 
            <RxListOverlay rxList={rxList} onClose={handleCloseOverlay} handleRemove={handleRemove} handleProcessPrescriptions={handleProcessPrescriptions}/>
        }
        {successMessageVisbility &&
            <SuccessMessage message={successMessage} onClose={handleSuccessMessageClose}/>
        }
        {showPatOverlay && 
            <PatientSearchOverlay patientSearchResults={patOverlayData} onClose={handlePatientCloseOverlay} searchFunction={handlePatientRxSearch} />
        }
        <Grid id='patientDisplay'>
        <Typography className='header'><b>Order Details</b></Typography>
            <Grid container spacing={0} alignItems="end">
              <Grid id="toggleSpacing">
              <Typography className='searchWithHeader'><b>Search With</b></Typography>
              <ToggleButtonGroup
                value={searchBy}
                exclusive
                onChange={handleSearchBy}
                >
                <ToggleButton sx={CustomToggleButton} value="RxNumber">
                  Rx Number
                </ToggleButton>
                <ToggleButton data-testid="toggle-account-button" sx={CustomToggleButton} value="Patient">
                  Patient Name
                </ToggleButton>
              </ToggleButtonGroup>
              </Grid>
              <Grid item xs={2}>
                {searchBy=='RxNumber'?<Typography className='subheader'><b>Rx Number</b></Typography>:<Typography className='subheader'><b>Patient Name</b></Typography>}
                {searchBy=='Patient'?<AutoSuggestion
                    onSuggestionSelected={handleSuggestionSelected}
                    searchType="patientSearch"
                    isRequired={true}
                    setSelectedValue={() => {}}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    fetchData={fetchAutoSuggestionData}
                    placeholder='Patient Name'
                    secondColNDC={false}
                    />:
                    <input placeholder='Rx Number' data-testid="rxInput" type='number' min="1" value={inputValue} onChange={handleChangeSearchBox} id='patientSearch'></input>
                }
                </Grid>
                <Grid container item xs={1.5} id = "searchSpacing">
                {searchBy=='Patient'?
                    <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
                        <RefreshIcon/>&nbsp;Reset
                    </Button>:
                    <div id='buttonsPosition'>
                        <Button variant='contained'sx={buttonStyle} onClick={(event) => handleRxSearch(event)}>Search</Button>
                        <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
                            <RefreshIcon/>&nbsp;Reset
                        </Button>
                    </div>
                }
              </Grid>
            </Grid>

          {showLoading && <LoadingSpinner {...{displayMessage:"Getting your assigned pharmacies please wait", height:"200px", width:"50%"}}/>}
    
          {/* The search results */}
          {show ? (
            <>
                <Table className='tableStyle'>
                    <TableHead>
                        <TableRow className='headerRow'>
                            <TableCell className='headerRowCells' sx={{ width: '90px' }}>Click to Select</TableCell>
                            <TableCell className='statRow' sx={{ whiteSpace: 'nowrap' }}>Stat</TableCell>
                            <TableCell className='headerRowCells' sx={{ whiteSpace: 'nowrap' }}>Rx</TableCell>
                            <TableCell className='headerRowCells' sx={{ whiteSpace: 'nowrap' }}>Patient Name</TableCell>
                            {showPatInfo && 
                                <><TableCell className='headerRowCells' sx={{ whiteSpace: 'nowrap' }}>Date of Birth</TableCell><TableCell className='headerRowCells' sx={{ whiteSpace: 'nowrap' }}>Gender</TableCell></>
                            }   
                            <TableCell className='headerRowCells' sx={{ whiteSpace: 'nowrap' }}>Drug Name</TableCell>
                            <TableCell className='headerRowCells' sx={{ whiteSpace: 'nowrap' }}>Directions</TableCell>
                            <TableCell className='headerRowCells' sx={{ whiteSpace: 'nowrap' }}>Quantity</TableCell>
                            <TableCell className='headerRowCells' sx={{ whiteSpace: 'nowrap' }}>Day Supply</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? resultRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : resultRows).map((row, index) => (
                                    <TableRow key={row.rxNum} sx={{ backgroundColor: getRowColor(index) }}>
                                        <TableCell style={{ padding: '5px', paddingLeft: '10px'}}>
                                            <IconButton onClick={(event) => handleAddButton(row.rxNum, event)} style={{ padding: '0px'}}>
                                                <AddIcon style={{color: '#004C97', margin: '5px'}} fontSize='medium' data-testid='add-icon' />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell style={{ padding: '5px'}} align='center'>
                                            <input style={{height: '15px', width: '15px'}} type='checkbox' />
                                        </TableCell>
                                        <TableCell>{row.rxNum}</TableCell>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.patName}</TableCell>
                                        {showPatInfo && 
                                            <><TableCell sx={{ whiteSpace: 'nowrap' }}>{patOverlayDataCopy?.find(element => { return element.account.toString() === row.account; })?.bDate}</TableCell><TableCell>{patOverlayDataCopy?.find(element => { return element.account.toString() === row.account; })?.sex}</TableCell></>
                                        }
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.drugName}</TableCell>
                                        <TableCell>{row.directions}</TableCell>
                                        <TableCell>{row.quantity}</TableCell>
                                        <TableCell>{row.daySupply}</TableCell>
                                    </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 20]}
                            component="div"
                            count={resultRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage} /></>
          ) : null}
        </Grid>
        <div>
            <div className='viewButton' onClick={handleOpenOverlay}>View Pending Submissions</div>
        </div>
    </>
    );
};