import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { IconButton } from '@mui/material';
import React from 'react';

interface PrintProps {
    printRef: React.RefObject<HTMLDivElement>
}

const PrintTrigger = () => (
    <IconButton className='printButton'>
        <PrintIcon/>
    </IconButton>
);

export const PrintButton: React.FC<PrintProps> = ({printRef}) => {
    return (
        <ReactToPrint
            trigger={PrintTrigger}
            content={() => printRef.current}
        />
    )
}