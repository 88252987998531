import React, { useState } from "react";
import "./Tracking.css";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { BatchTracking } from "./BatchTracking";

interface PatBatchTrackingProps {
    patData: string[] | null,
    patName: string,
    siteNum: string,
    submitCount: number
}

export const PatBatchTracking: React.FC<PatBatchTrackingProps> = ({ patData, patName, siteNum, submitCount}) => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel : any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false);
    };
    
    if (patData == null || patData.length < 1) {
        return (
            <p>No Batches Found.</p>
        )
    }

    return (
        <div>
            <Typography className='searchWithHeader' style={{ fontWeight: 'bold', margin: '0px', marginBottom: '10px'}}>Batch Number(s) for {patName}</Typography>
            {(patData).map((row, index) => (
                <Accordion key={row[index]} style={{ border: '0.5px solid #F3F3F3'}} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                    <AccordionSummary 
                        expandIcon={<ExpandMoreOutlined />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        >
                        <Typography className='searchWithHeader'>Batch Number {row}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {expanded === `panel${index}` && 
                            <BatchTracking batchNum={row} siteNum={siteNum}  submitCount={submitCount}/>
                        }
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};