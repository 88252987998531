export interface MenuOptions {
  value: string;
  lable: string;
}

export const roles: MenuOptions[] = [
  {value: 'Facility Staff', lable: 'Facility Staff'},
  {value: 'Prescriber', lable: 'Prescriber'},
  {value: 'CMC', lable: 'CMC'},
  {value: 'Admin', lable: 'Admin'}
];

export const states: MenuOptions[] = [];
