// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    font-size: 20px !important;
    color: black !important;
    padding-bottom: 10px !important;
}

.searchWithHeader {
    font-size: 16px !important; 
    color: black !important;
}

.subheader {
    font-size: 16px; 
    color: black;
}

.headerRow {
    background-color: #F3F3F3 !important;
    border-top: 1px solid #00000038 !important;
    border-bottom: 1px solid #CBCCCD !important;
}

.headerRowCells {
    font-weight: bold !important;
}

.statRow {
    font-weight: bold !important; 
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.tableStyle {
    border: 1px solid #00000038;
    margin-top: 40px;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/common/components/AutoSuggestion/SearchBox.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,uBAAuB;IACvB,+BAA+B;AACnC;;AAEA;IACI,0BAA0B;IAC1B,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,0CAA0C;IAC1C,2CAA2C;AAC/C;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;IAC7B,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".header {\n    font-size: 20px !important;\n    color: black !important;\n    padding-bottom: 10px !important;\n}\n\n.searchWithHeader {\n    font-size: 16px !important; \n    color: black !important;\n}\n\n.subheader {\n    font-size: 16px; \n    color: black;\n}\n\n.headerRow {\n    background-color: #F3F3F3 !important;\n    border-top: 1px solid #00000038 !important;\n    border-bottom: 1px solid #CBCCCD !important;\n}\n\n.headerRowCells {\n    font-weight: bold !important;\n}\n\n.statRow {\n    font-weight: bold !important; \n    padding-right: 5px !important;\n    padding-left: 5px !important;\n}\n\n.tableStyle {\n    border: 1px solid #00000038;\n    margin-top: 40px;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
