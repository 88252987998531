import {useNavigate} from 'react-router-dom';
import '../App/app.css';
import {Button} from '@mui/material';
import React from 'react';

interface ChoosePharmacyPageProps {
  setActivePath: (input: string) => void;
}

export const NotFoundPage = () => {
  return (
    <div className="horizontal-center">
      <header className="page-title">
        <b className="not-found">404 Not Found</b>
      </header>
    </div>
  );
};

export const ChoosePharmacyPage: React.FC<ChoosePharmacyPageProps> = ({ setActivePath }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="horizontal-center">
        <div className="page-title">
          <b className="not-found">Please go back to the home page to select a pharmacy first</b>
        </div>
      </div>
      <div className="horizontal-center">
        <Button
          sx={{justifySelf: 'center'}}
          variant="contained"
          onClick={() => {
            navigate('/');
            setActivePath('/');
          }}
        >
          Home
        </Button>
      </div>
    </div>
  );
};
