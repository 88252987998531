import { FormControl, MenuItem, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";
import { RxTracking1DTO } from "../../Interfaces/TrackingDTOModels"
import "./LandingPage.css"
import React, { useEffect, useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';

// Define the interface for table props
interface TablesProps {
    rxData: RxTracking1DTO[] | null;
}

// Define the FilterableRxTable component
export const FilterableRxTable: React.FC<TablesProps> = ({ rxData }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState({rxNumber: '', patName: '', drugName: '', status: ''});
    const [rxNumFilter, setRxNumFilter] = useState('');
    const [patNameFilter, setPatNameFilter] = useState('');
    const [drugNameFilter, setDrugNameFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [filteredRxData, setFilteredRxData] = useState<RxTracking1DTO[]>(rxData);

    // Handle page change event
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    // Handle rows per page change event
    const handleChnageRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Handle input change event for filters
    const handleInputChange = (setter: any) => (event: any) => {
        setter(event.target.value);
    };

    // Update filters state when any of the filter values change
    useEffect (() => {
        setFilters({rxNumber: rxNumFilter, patName: patNameFilter, drugName: drugNameFilter, status: statusFilter});
    }, [rxNumFilter, patNameFilter, drugNameFilter, statusFilter]);

    // Apply filters to the data
    const handleApplyFilters = () => {
        setFilteredRxData(rxData.filter(model => {
            const passesRxNumFilter = !filters.rxNumber || model.rxNumber === filters.rxNumber;
            const passesPatNameFilter = !filters.patName || (model.patName.toUpperCase()).includes(filters.patName.toUpperCase());
            const passesDrugNameFilter = !filters.drugName || (model.drug.toUpperCase()).includes(filters.drugName.toUpperCase());
            const passesStatusFilter = !filters.status || filters.status === "All" || model.status === filters.status;
            return passesRxNumFilter && passesPatNameFilter && passesDrugNameFilter && passesStatusFilter;
        }));
        setPage(0) // Reset page to 0 after applying filters
        
    };

    // Reset filters and data
    const handleResetFilters = () => {
        setFilteredRxData(rxData);
        setRxNumFilter('');
        setPatNameFilter('');
        setDrugNameFilter('');
        setStatusFilter('All');
    }

    // Get row color based on index
    const getRowColor = (index: number) =>  {
        return index % 2 === 0 ? 'white' : '#FAFAFA';
    }

    return (
        <>
            <div className="filters-container" style={{ paddingLeft: '10px', paddingRight: '10px'}}>
                <div className="filter-container-short">
                    <label htmlFor="textbox">Rx Number</label>
                    <input className="input-field" placeholder='Rx Number' type="text" value={rxNumFilter} onChange={handleInputChange(setRxNumFilter)} />
                </div>
                <div className="filter-container">
                    <label htmlFor="textbox">Patient Name</label>
                    <input className="input-field" placeholder='Patient Name' type="text" value={patNameFilter} onChange={handleInputChange(setPatNameFilter)} />
                </div>
                <div className="filter-container">
                    <label htmlFor="textbox">Drug Name</label>
                    <input className="input-field" placeholder='Drug Name' type="text" value={drugNameFilter} onChange={handleInputChange(setDrugNameFilter)} />
                </div>
                <div className="filter-container-med">
                    <label htmlFor="textbox">Status</label>
                    <FormControl style={{ width: '100%' }}>
                        <Select
                            value={statusFilter}
                            onChange={handleInputChange(setStatusFilter)}
                            className="status-dropdown"
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Request for Rx/Refill">Request for Rx/Refill</MenuItem>
                            <MenuItem value="Prescription Filled">Prescription Filled</MenuItem>
                            <MenuItem value="Drug/NDC Verification">Drug/NDC Verification</MenuItem>
                            <MenuItem value="Pharmacist Verification #1">Pharmacist Verification #1</MenuItem>
                            <MenuItem value="Pharmacist Verification #2">Pharmacist Verification #2</MenuItem>
                            <MenuItem value="Placed in Tote">Placed in Tote</MenuItem>
                            <MenuItem value="Tote Closed/Awaiting Pick-Up">Tote Closed/Awaiting Pick-Up</MenuItem>
                            <MenuItem value="In Delivery">In Delivery</MenuItem>
                            <MenuItem value="Delivered">Delivered</MenuItem>
                            <MenuItem value="Picked Up">Picked Up</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <button className="applyFilter-Button" onClick={handleApplyFilters}>Apply Filters</button>
                <button className="reset-Button" onClick={handleResetFilters}>
                    <RefreshIcon/>
                    <div style={{ paddingLeft: '5px'}}>Reset</div>
                </button>            
            </div>
            <Table style={{border: '1px solid #F3F3F3'}}>
                <TableHead>
                    <TableRow className="table-header-row">
                        <TableCell className="table-header-title">Rx Number</TableCell>
                        <TableCell className="table-header-title">Patient Name</TableCell>
                        <TableCell className="table-header-title">Drug Name</TableCell>
                        <TableCell className="table-header-title">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0 ? filteredRxData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRxData).map((row, index) => (
                        <TableRow key={row.rxNumber} sx={{ backgroundColor: getRowColor(index)}}>
                            <TableCell>{row.rxNumber}</TableCell>
                            <TableCell>{row.patName}</TableCell>
                            <TableCell>{row.drug}</TableCell>
                            <TableCell>{row.status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={filteredRxData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChnageRowsPerPage}
            />
         </>
    );
};