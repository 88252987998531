import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import '../../App/app.css';
import './UserProfile.css';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {states} from '../../States';
import {reactSelect} from '../../States';
import Button from '@mui/material/Button';
import {AuthUser} from '../../Interfaces/Authentication';
import {useEffect, useState} from 'react';
import {fetchSites} from '../../actions/ProScriptAPI';
import {GetAuthUser, GetUserAccessesByUserId, PostRequestNewSiteAccess} from '../../actions/UsersAPI';
import {ProdigySites} from '../../Interfaces/ProdigySites';
import {StringAPIResponse} from '../../States';
import {UserSiteAccessInfoInterface, NewSiteRequestInterface} from '../../Interfaces/Users';
import React from 'react';
import {FormControl, InputLabel, MenuItem, Stack, TextField} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ErrorMessage, SuccessMessage } from '../../common/components/AlertMessages/AlertMessages';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import PatientOverlayComponent from '../PatientInformation/PatientOverlay';

export const UserProfile = () => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageVisibility, setErrorMessageVisibility] = useState<boolean>(false);

  useEffect(() => {
    fetchUserSiteAccess();
    const fetchData = async () => {
      try {
        const result = await GetAuthUser();
        const authUser: AuthUser = {
          firstName: result.firstName,
          lastName: result.lastName,
          username: result.username,
          userId: result.userId,
          isInternal: result.isInternal,
          pharmacies: []
        };
        setUser(authUser);
      }
      catch (error) {
        setUser(null);
    }
    };
    fetchData();
  }, []);

  const pharmacy = 'Pharmacy';
  const state = 'State';
  const statesList: reactSelect[] = [];
  const [pharmacyList, setPharmacyList] = useState<reactSelect[]>([]);

  const [userPharmacies, setUserPharmacies] = useState<UserSiteAccessInfoInterface[]>([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedPharmacy, setSelectedPharmacy] = useState('');
  const [requestForm, setRequestForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successMessageVisibility, setSuccessMessageVisibility] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState('');

  states.forEach(function (element) {
  statesList.push({label: element, value: element});
  });
  const handleSelectStateChange = async (event: SelectChangeEvent) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectedState(event.target.value);
    let a: ProdigySites[] = await fetchSites(event.target.value);
    
    setSelectedPharmacy('');
    setPharmacyList([]);
    a.forEach(function (element) {
      setPharmacyList((pharmacy) => [...pharmacy, {label: element.siteName, value: element.siteNumber}]);
    });
  };

  const handleSelectPharmacyChange = (event: SelectChangeEvent) => {
      event.stopPropagation()
      event.preventDefault()
    setSelectedPharmacy(event.target.value);
  };

  const fetchUserSiteAccess = async () => {
    let siteAccesses: UserSiteAccessInfoInterface[] = await GetUserAccessesByUserId();
    setUserPharmacies(siteAccesses);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const {name, value} = event.target;
    switch (name) {
      case 'additionalDetails':
        setAdditionalDetails(value);
        break;
    }
  };

  const handleRequestAccess = (event: React.MouseEvent) => {
      event.preventDefault();
      setRequestForm(true);
    }
    const handleSubmit = async (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
    let parsedRequestBody: StringAPIResponse;
    //UI only checks
    if (selectedState.length <= 0 && selectedPharmacy.length <= 0)
    {
    setErrorMessage("State and Pharmacy must be selected");
    setErrorMessageVisibility(true);
    }
    else if (selectedState.length <= 0)
    {
      setErrorMessage("State must be selected");
      setErrorMessageVisibility(true);
    }
    else if (selectedPharmacy.length <= 0)
    {
      setErrorMessage("Pharmacy must be selected");
      setErrorMessageVisibility(true);
    }
    //check if user is requesting access for a site they already have access to
    else if (userPharmacies.find(e => { return e.siteName == selectedPharmacy }))
    {
      setErrorMessage("Access Already Exists");
      setErrorMessageVisibility(true);
    }
    //check other api dependent checks
    else if (selectedState.length > 0 && selectedPharmacy.length > 0)
    {
      if (additionalDetails.length > 0){
        const newRequestInterface:NewSiteRequestInterface = {siteName:selectedPharmacy, additionalNotes:additionalDetails}
        parsedRequestBody = await PostRequestNewSiteAccess(newRequestInterface)
        if (parsedRequestBody.data === 'Pending or submitted status, do not add') {
          setErrorMessage("Already Submitted");
          setErrorMessageVisibility(true);
        } else if (parsedRequestBody.data === 'Created User Access' || parsedRequestBody.data === 'Updated User Access') {
            setSuccessMessage("Submitted Request");
            setErrorMessageVisibility(false);
            setSuccessMessageVisibility(true);
        } else {
          setErrorMessage("Error in Submitting");
          setErrorMessageVisibility(true);
        }
      } else {
        setErrorMessage("Please add additional details");
        setErrorMessageVisibility(true);
        setSuccessMessageVisibility(false);
      }
      
    };
  };

  const handleReset = (event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedPharmacy('');
    setSelectedState('');
    setAdditionalDetails('');
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (event: React.MouseEvent) => {
    event.preventDefault();
    setOpen(true);
  };

    const handleErrorMessageClose = () => {
    setErrorMessageVisibility(false);
    setErrorMessage('');
  };

  const handleSuccessMessageClose = () => {
    setSuccessMessageVisibility(false);
    setSuccessMessage('');
  };

  if (user === null) {
    return (
      <LoadingSpinner {...{ height:"200px", width:"50%"}}/>    
    )
  }

  return (
    <>
    {errorMessageVisibility&&
        <ErrorMessage message={errorMessage} onClose={handleErrorMessageClose}/>
    }
      {successMessageVisibility &&
        <SuccessMessage message={successMessage} onClose={handleSuccessMessageClose}/>
      }
      {open && 
        <PatientOverlayComponent                   
          onClose={() => setOpen(false)}
          items={userPharmacies.map(access => access.siteName)}
          title="My Pharmacy Access"/>
      }
      <Grid container id="profile-card">
        <Grid item sx={{ml: '5%'}}>
        <div className="patientInfoContainer">
        {/* Name Box */}
          <div className="nameBox" style={{color: 'black', display: 'flex', flexDirection: 'column'}}>
            <div style={{ flexGrow: 0}}>
              {user?.firstName} {user?.lastName}
            </div>
            <div style={{ flexGrow: 1, alignContent: 'end' }}>
              <Button
                onClick={(e) => {
                  handleRequestAccess(e)
                }}
                id="access-button"
                style={{ padding: '0px' }}
                >
                Request Pharmacy Access
              </Button>
            </div>
          </div>
        
          <div className='infoBox'  style={{ alignSelf: 'start'}}>
            <div className='infoRow' style={{ paddingTop: '15px'}}>
              <div className='title-content'>
                <div className='infoTitle'>Username</div>
                <div className="infoValue">{user?.username}</div>
            </div>
            <div className='title-content'>
              <div className='infoTitle'>Email</div>
              <div className='infoValue'>{user?.username}</div>
            </div>
            <div className='title-content' style={{ borderLeft: '1px solid #cecece'}}>
              <Button className="overlay-link-patient" style={{ marginBottom: '15px' }}
                      onClick={(e) => {
                        handleOpen(e);
                      }}>
                      My {pharmacy} Accesses
              </Button>
            </div>
          </div>
        </div>
        </div>
          <Grid id="card-layout">
            {requestForm && (
              <Card id="white-card">
                <Typography variant="h5" id="request-pharmacy-access">
                  Request Pharmacy Access
                </Typography>
                <Grid container spacing={3} id="pharmacy-form">
                  <Grid item xs={4} id="select">
                    <Stack>
                      <Typography variant='subtitle1' fontWeight={'bold'}>Select State</Typography>
                      <FormControl size="small" id="select-form">
                        <InputLabel id="select-state-label">{state}</InputLabel>
                        <Select
                          labelId="select-state-label"
                          id="select-state"
                          label={state}
                          value={selectedState}
                          onChange={handleSelectStateChange}
                        >
                          {statesList.map((row)=>row.value).map((row, key) => (
                            <MenuItem key={key} value={row}> {row} </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={4} id="select">
                    <Stack>
                      <Typography variant='subtitle1' fontWeight={'bold'}>Select Pharmacy</Typography>
                      <FormControl size="small" id="select-form">
                        <InputLabel id="select-pharmacy-label">{pharmacy}</InputLabel>
                        <Select
                          labelId="select-pharmacy-label"
                          id="select-pharmacy"
                          label={pharmacy}
                          value={selectedPharmacy}
                          onChange={handleSelectPharmacyChange}
                        >
                          {pharmacyList.map((row)=>row.label).map((row, key) => (
                            <MenuItem key={key} value={row}> {row} </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={2} textAlign={'end'}>
                    <button id="select-button" onClick={handleSubmit}>
                      Submit
                    </button>
                  </Grid>
                  <Grid item xs={1} textAlign={'end'} style={{ paddingLeft: '16px'}}>
                    <button id="reset-button" onClick={handleReset}>
                      <RefreshIcon/>
                      <div style={{ paddingLeft: '5px'}}>Reset</div>
                    </button>
                  </Grid>
                  <Grid item xs={12} textAlign={'end'} id='additionalDetailsGrid'>
                    <TextField
                      fullWidth
                      id="additionalDetails"
                      name="additionalDetails"
                      label="Additional Details - Please specify full facility name you need access to"
                      multiline
                      rows={4}
                      value={additionalDetails}
                      onChange={handleInputChange}
                      inputProps={{
                        maxLength: 255
                    }}
                    required
                    />
                </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
