import React from 'react';
import {Box, Divider} from '@mui/material';
import {RoutingComponent} from '@skyline/types';

import {Navbar} from './Navbar';
import {Logout, LogoutProps} from './Logout';
import {HeaderContent} from './types/HeaderContent';
import {NavbarContent} from './Navbar/NavbarContent';

export interface HeaderProps {
  content: HeaderContent;
  memberNavigation: NavbarContent | null;
  // onLogout: () => void;
  name: string | undefined;
  hasUserDropdown: boolean;
  activePath: string;
  routingComponent: RoutingComponent;
  userAuthenticated: boolean | null; 
  siteName: string;
  setActivePath: (input: string) => void;
}

export const Header: React.FC<HeaderProps> = ({
  content,
  memberNavigation,
  // onLogout,
  name,
  hasUserDropdown,
  activePath,
  setActivePath,
  routingComponent, 
  userAuthenticated,
  siteName
}) => {
  const {signOutText} = content.headerMain.userDropdown;
  const {logo, logoAlt} = content.headerLogo;
  const logoutProps: LogoutProps = {
    userDropdownLinks: content.headerMain.userDropdown.userDropdownLinks,
    // onSignOutClick: () => onLogout(),
    name,
    hasUserDropdown,
    signOutText,
    routingComponent,
    siteName
  };
  return (
    <>
      <div style={{ backgroundColor: '#D22630', height: '7px'}}></div>
      <Box sx={{display: {xs: 'none', md: 'block', margin: '10px'}}} >
        {/* <Button
          href={skipToTag}
          sx={{
            '&.MuiButton-root': {position: 'absolute'},
            left: 5,
            transform: 'translateY(-100%)',
            transition: 'transform 0.3s',
            '&:focus': {transform: 'translateY(0%)', top: 5}
          }}
        >
          {skipToText}
        </Button> */} 
        <Box
          justifyContent="space-between"
          alignItems="center"
          sx={{display: 'flex', height: 60, maxWidth: '100%', mx: 'auto', px: 4}}
        >
          <Box component="img" src={logo} alt={logoAlt} sx={{ height: 50, width: "auto"}} />
          {userAuthenticated && <Logout {...logoutProps} />}
          
        </Box>
      </Box>
      <Box sx={{position: 'sticky', top: 0, bgcolor: 'common.white', zIndex: 100, marginTop: '5px', marginBottom: '30px'}}>
        <Box sx={{display: 'flex', alignItems: 'center', height: {xs: 50, md: '100%'}}}>
          {memberNavigation && 
          <Navbar
            routingComponent={routingComponent}
            memberNavigation={memberNavigation}
            logoutProps={logoutProps}
            activePath={activePath}
            setActivePath={setActivePath}
          />
          }
          {memberNavigation === null && <hr style={{borderTop: '3px solid #004C97', width: '100%'}} /> }
        </Box>
        <Divider sx={{display: {xs: 'block', md: 'none'}}} />
      </Box>
    </>
  );
};
