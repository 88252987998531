import {SxProps} from '@mui/system';

//toggle button customization
export const CustomToggleButton : SxProps= {

  "&.Mui-selected": {
   color: "#fff",
   backgroundColor: "#004C97",
    "&:hover": {
     color: "#fff",
     backgroundColor: "#004C97",
    },
  },
  height:40,
  marginBottom: 0,
  textTransform: 'capitalize',
  fontWeight: 'bold',
  color: 'black'
};

export const buttonStyle: SxProps = {
  backgroundColor: '#004C97', // Set the button color
  '&:hover': {
    backgroundColor: '#003a70' // Darken the color slightly on hover
  },
  height: 40,
  marginRight:2,
  borderRadius:20,
  textTransform: 'capitalize',
  fontWeight:'bold'
};
export const buttonStyleGray: SxProps = {
  backgroundColor: 'transparent', // Set the button color
  '&:hover': {
    backgroundColor: 'lightgray' // Darken the color slightly on hover
  },
  height: 40,
  marginRight:2,
  borderRadius:20,
  textTransform:'capitalize',
  fontWeight:'bold',
  color:"#004C97",
  border: '1px solid #004C97'
};