import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface CustomTooltipProps {
    title: string;
    children?: React.ReactNode;
}

export const CustomTooltip = ({ title, children }: CustomTooltipProps) => {
    return (
        <Tooltip placement="right" title={<div id="helper-text">{title}</div>}>
            <IconButton style={{ padding: '0px', paddingLeft: '5px', color: '#004C97' }}>
                {children || <InfoOutlinedIcon fontSize="small" />}
            </IconButton>
        </Tooltip>
    );
};