import {isClient} from '@skyline/common';

export function getSize(el: HTMLElement | null) {
  if (!el) {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      offsetWithElementHeight: 0,
      offsetWithElementHeightFromTop: 0
    };
  }

  const boundingClient: ClientRect | DOMRect = el.getBoundingClientRect();
  const height = document.body.scrollHeight - boundingClient.bottom;
  return {
    width: boundingClient.width,
    height: boundingClient.height,
    top: boundingClient.top,
    left: boundingClient.left,
    offsetWithElementHeightFromTop: boundingClient.top,
    offsetWithElementHeight: height < 40 ? boundingClient.bottom : height
  };
}

export const scrollToTop = () => {
  if (isClient()) {
    window.scrollTo(0, 0);
  }
};
