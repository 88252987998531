import React, { useContext, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { buttonStyle, buttonStyleGray, CustomToggleButton } from '../PatientProfile/PatientProfileStyle';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../../common/components/AutoSuggestion/SearchBox.css';
import '../DrugInformationPage/DrugInformationOverylays.css';
import './DrugMonograph.css';
import '../../App/app.css';
import { DrugMonographDTO } from '../../Interfaces/DrugMonographDTOModel';
import AutoSuggestion from '../../common/components/AutoSuggestion/autoSuggestion';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import { ErrorMessage } from '../../common/components/AlertMessages/AlertMessages';
import { fetchDrugLookup, fetchDrugMonograph, fetchDrugSearch } from '../../actions/ProScriptAPI';
import { FormControlLabel, IconButton, Switch, Tooltip } from '@mui/material';
import pharmacyNumContext from '../Authorization/AuthContext';
import { DrugSearch } from '../../Interfaces/DrugInteractionsDTOModels';
import { formatNDC } from '../PatientInformation/Helpers';
import { DrugMonographResults } from './Results';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const DrugMonographPage = () => {
    const context = useContext(pharmacyNumContext);
    const siteNum = context.siteInfo.siteNumber;
    const [inputValue, setInputValue] = useState("");
    const [selectedNDC, setSelectedNDC] = useState("");
    const [monograph, setMonograph] = useState<DrugMonographDTO>();
    const [showMonograph, setShowMonograph] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);
    const [checkedSpanish, setCheckedSpanish] = useState(false);
    const printRef = useRef<HTMLDivElement>(null);
    const [drugName, setDrugName] = useState('');

    //consts for handling toggling search option
    const [searchBy, setSearchBy] = React.useState<string>('DrugName');
    const handleSearchBy = (
        event: React.MouseEvent<HTMLElement>,
        newSearchBy: string,
    ) => {
        if(newSearchBy!=null){
            setInputValue('')
            setSearchBy(newSearchBy);
        }
    };

    const handleChangeSearchBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Updates text box value
        const input = event.target.value;
        setInputValue(input)
    };

    const handleClear = () => {
        setInputValue('');
        setShowMonograph(false);
        setShowLoading(false);
    };

    const handleErrorMessageClose = () => {
        setErrorMessageVisibility(false);
        setErrorMessage('');
    };

    const handleSwitch = (event: any) => {
        setCheckedSpanish(event.target.checked);
    }

    // Automatically update Drug Monograph if Spanish is switched
    useEffect(() => {
        if (showMonograph) {
            fetchDrugDataFromNDC(selectedNDC);
        }
    }, [checkedSpanish]);

    function handleDrugName(name: string, ndc: string) {
        setDrugName(name + " (" + formatNDC(ndc) + ")");
    }

    async function fetchAutoSuggestionData(drugName: string) : Promise<[string, string][]> {
        try {
            const result: DrugSearch[] = await fetchDrugSearch(drugName, siteNum, true);
            const results: [string, string][] = result.map((item) => [item.drugName, item.ndc]);
            return results;
        }
        catch (error) {
            setErrorMessage("Error Fetching Drug Search. Please Try Again.");
            setErrorMessageVisibility(true);
            console.error('Error fetching drug serach', error);
        }

        return [["Try again", ""]];
    };

    async function fetchDrugDataFromNDC (ndc: string) {
        setShowMonograph(false);
        setShowLoading(true);

        let strippedNDC = ndc.replace(/-/g, '');

        if (strippedNDC.length !== 11) {
            setErrorMessage('NDC Number Needs to be 11 Digits. Please Try Again.');
            setErrorMessageVisibility(true);
            setShowLoading(false);
            return;
        }

        try { 
            const drugInfo : DrugSearch = await fetchDrugLookup(strippedNDC, siteNum);
            if (drugInfo === null || drugInfo.drugName === "" ) {
                setErrorMessage("Unable to Locate Drug Based on Given NDC. \nInvalid NDC Number or Drug Might be Inactive.")
                setErrorMessageVisibility(true);
                setShowLoading(false);
                return;
            }

            handleDrugName(drugInfo.drugName, drugInfo.ndc);
        }
        catch (error) {
            setErrorMessage("An Error Occurred. Please Try Again.")
            setErrorMessageVisibility(true);
            setShowLoading(false);
            return;
        }

        setSelectedNDC(strippedNDC);

        try {
            // get image info 
            const result : DrugMonographDTO = await fetchDrugMonograph(strippedNDC, checkedSpanish);
            if (result === undefined || result.drugName === undefined || result.pronunciation === "") {
                setErrorMessage(`Unable to Find Drug Monograph for ${formatNDC(strippedNDC)}. Please Try Again.`)
                setShowLoading(false);
                setErrorMessageVisibility(true);
                return;
            }
            setMonograph(result);
            setShowLoading(false);
            setShowMonograph(true);
            setInputValue('');
        }
        catch (error) {
            console.error('Error fetching drug information from ndc', error);
            setErrorMessage(`Unable to Fetch Drug Monograph for ${formatNDC(ndc)}. Please Try Again.`)
            setShowLoading(false);
            setErrorMessageVisibility(true);
        }
        setInputValue('');
    }

    async function handleSuggestionClick (suggestion: [string, string]) {
        setInputValue('');
        fetchDrugDataFromNDC(suggestion[1]);
    };

    return(
        <div id='drug-layout'>
        {errorMessageVisibility && 
            <ErrorMessage message={errorMessage} onClose={handleErrorMessageClose}/>
        }
        <Grid id='patientDisplay' style={{ paddingBottom: '20px'}}>
            <Typography className='header'><b>Search Drug</b></Typography>
                <Grid container spacing={0} alignItems="end">
                    <Grid id="toggleSpacing">
                    <Typography className='searchWithHeader'><b>Search With</b></Typography>
                        <ToggleButtonGroup
                            value={searchBy}
                            exclusive
                            onChange={handleSearchBy}
                            >
                            <ToggleButton sx={CustomToggleButton} value="DrugName">
                                Drug Name
                            </ToggleButton>
                            <ToggleButton data-testid="toggle-account-button" sx={CustomToggleButton} value="NDC">
                                NDC Number
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid>
                        {searchBy=='DrugName'?<Typography className='subheader'><b>Drug Name</b></Typography>:
                            <Typography className='subheader'>
                                <b>NDC Number</b>
                                <Tooltip placement="right" title={<div id="helper-text">Please provide full NDC Number.</div>}>
                                    <IconButton  style={{padding: '1px', paddingLeft: '5px', marginBottom: '2px', color: '#004C97'}}>
                                        <InfoOutlinedIcon fontSize='small'/>
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        }
                        {searchBy=='DrugName'?<AutoSuggestion
                            onSuggestionSelected={handleSuggestionClick}
                            searchType="drugSearch"
                            isRequired={true}
                            setSelectedValue={() => {}}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            fetchData={fetchAutoSuggestionData}
                            placeholder='Drug Name'
                            secondColNDC={true}
                        />:
                            <input placeholder='NDC Number' value={inputValue} onChange={handleChangeSearchBox} id='patientSearch'></input>
                        }
                    </Grid>
                    <Grid container item xs={1.5} id = "searchSpacing">
                        {searchBy=='DrugName'?
                            <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
                                <RefreshIcon/>&nbsp;Reset
                            </Button>:
                            <div id='buttonsPosition'>
                                <Button variant='contained'sx={buttonStyle} onClick={() => fetchDrugDataFromNDC(inputValue)}>Search</Button>
                                <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
                                    <RefreshIcon/>&nbsp;Reset
                                </Button>
                            </div>
                        }         
                    </Grid>
                    <Grid>
                        <FormControlLabel
                            control={ <Switch
                                        checked={checkedSpanish}
                                        onChange={handleSwitch}
                                    />}
                            label='View Monograph in Spanish' 
                            classes={{ label: 'labelClass'}}
                            style={{ paddingLeft: '30px' }}/>
                    </Grid>
                </Grid>

        {showLoading && <LoadingSpinner {...{ height:"200px", width:"50%"}}/>}
        
        {showMonograph &&
            <DrugMonographResults printRef={printRef} monograph={monograph} checkedSpanish={checkedSpanish} drugName={drugName}/>
        }

        </Grid>
        </div>
    );
}
