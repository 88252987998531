// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-message-container {
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 35px;
    left: 0;
    animation: slideDown 0.5s ease forwards;
}

.error-message-content {
    display: flex;
    color: #C40000;
    max-width: 40%;
    min-width: 25%;
    border-radius: 10px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    background-color: #FCF0F0;
    border: 1px solid #C40000;
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
}

.success-message-content {
    display: flex;
    color: #007000;
    max-width: 40%;
    min-width: 25%;
    border-radius: 10px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    background-color: #EFF6EF;
    border: 1px solid #007000;
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
}

.message-display {
    align-content: center !important;
    margin-left: 10px !important;
    white-space: pre-line !important;
}`, "",{"version":3,"sources":["webpack://./src/common/components/AlertMessages/AlertMessages.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,aAAa;IACb,SAAS;IACT,OAAO;IACP,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,cAAc;IACd,cAAc;IACd,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,cAAc;IACd,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,gCAAgC;IAChC,4BAA4B;IAC5B,gCAAgC;AACpC","sourcesContent":[".alert-message-container {\n    position: fixed;\n    width: 100%;\n    z-index: 1000;\n    top: 35px;\n    left: 0;\n    animation: slideDown 0.5s ease forwards;\n}\n\n.error-message-content {\n    display: flex;\n    color: #C40000;\n    max-width: 40%;\n    min-width: 25%;\n    border-radius: 10px;\n    position: absolute;\n    top: 45%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: auto;\n    height: auto;\n    background-color: #FCF0F0;\n    border: 1px solid #C40000;\n    font-size: 16px;\n    font-weight: bold;\n    padding: 20px;\n}\n\n.success-message-content {\n    display: flex;\n    color: #007000;\n    max-width: 40%;\n    min-width: 25%;\n    border-radius: 10px;\n    position: absolute;\n    top: 45%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: auto;\n    height: auto;\n    background-color: #EFF6EF;\n    border: 1px solid #007000;\n    font-size: 16px;\n    font-weight: bold;\n    padding: 20px;\n}\n\n.message-display {\n    align-content: center !important;\n    margin-left: 10px !important;\n    white-space: pre-line !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
