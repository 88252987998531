import React, { useState } from "react";
import { IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { PatientSearchResultsDTO } from "../../Interfaces/PatientDTOModel";
import { LoadingSpinner } from "./LoadingSpinner/LoadingSpinner";

interface PatientOverlayProps {
    patientSearchResults: PatientSearchResultsDTO[] | null,
    onClose: () => void;
    searchFunction: (account: string, patName: string) => void;
}

export const PatientSearchOverlay: React.FC<PatientOverlayProps> = ({ patientSearchResults, onClose, searchFunction}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRowClick = (row: PatientSearchResultsDTO) => {
        searchFunction(row.account, row.name);
        onClose();
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getRowColor = (index: number) =>  {
        return index % 2 === 0 ? 'white' : '#FAFAFA';
    };

    if (patientSearchResults === null || patientSearchResults === undefined) {
        return (
            <div className='overlay-patient'>
                <div className='overlay-content-rx' style={{ width: '80%'}}>
  
                    <div className='overlay-top'>
                        <div className='title-patient'>Please Select a Patient</div>
                        <IconButton className='close-button' onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div> 
                    <div className='info-box' style={{margin: '20px', marginTop: '20px'}}>
                        <LoadingSpinner {...{ height:"200px", width:"50%"}}/>   
                    </div>         
                </div>
            </div>
        )
    }

    return (
        <div className='overlay-patient'>
            <div className='overlay-content-rx' style={{ width: '80%', overflowY: 'auto'}}>
  
                <div className='overlay-top'>
                    <div className='title-patient'>Please Select a Patient</div>
                    <IconButton className='close-button' onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </div> 
                <div className='info-box'>
                    <Table className='tableStyle-Rx'>
                        <TableHead>
                            <TableRow className='headerRow'>
                                <TableCell className='headerRowCells'>Patient Name</TableCell>
                                <TableCell className='headerRowCells'>Account Number</TableCell>
                                <TableCell className='headerRowCells'>Date of Birth</TableCell>
                                <TableCell className='headerRowCells'>Gender</TableCell>
                                <TableCell className='headerRowCells'>Facility</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? patientSearchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : patientSearchResults).map((row, index) => (
                                <TableRow key={row.account} sx={{ backgroundColor: getRowColor(index),  cursor: 'pointer', '&:hover': {backgroundColor: '#e0e0e0'}}} onClick={() => handleRowClick(row)} >
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.account}</TableCell>
                                    <TableCell>{row.bDate}</TableCell>
                                    <TableCell>{row.sex}</TableCell>
                                    <TableCell>{row.fac}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        component="div"
                        count={patientSearchResults.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    );
};