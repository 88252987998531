// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#layout{
    width:100%;
    margin:0px;
    height:calc(100vh - 238px);
}

.viewButton {
    position: fixed;
    bottom: 70px;
    right: 40px;
    z-index: 900;
    background-color: white;
    color: #004C97;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    border: 1px solid #004C97;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
    font-size: 17px;
}

.overlay-content-rxlist {
    color: black;
    max-width: 80%;
    min-width: 40%;
    border-radius: 10px;
    height: auto;
    background-color: #FBF9F4;
    width: auto;
}

.processButton-overlay {
    z-index: 1000;
    background-color: #004C97;
    color: white;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
    font-size: 17px;
    width: fit-content;
    float: right;
    margin-right: 20px;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/OrderRefills/OrderRefills.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,UAAU;IACV,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,cAAc;IACd,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":["#layout{\n    width:100%;\n    margin:0px;\n    height:calc(100vh - 238px);\n}\n\n.viewButton {\n    position: fixed;\n    bottom: 70px;\n    right: 40px;\n    z-index: 900;\n    background-color: white;\n    color: #004C97;\n    padding: 15px;\n    padding-left: 25px;\n    padding-right: 25px;\n    border: 1px solid #004C97;\n    border-radius: 25px;\n    cursor: pointer;\n    font-weight: bold;\n    font-size: 17px;\n}\n\n.overlay-content-rxlist {\n    color: black;\n    max-width: 80%;\n    min-width: 40%;\n    border-radius: 10px;\n    height: auto;\n    background-color: #FBF9F4;\n    width: auto;\n}\n\n.processButton-overlay {\n    z-index: 1000;\n    background-color: #004C97;\n    color: white;\n    padding: 15px;\n    padding-left: 25px;\n    padding-right: 25px;\n    border: none;\n    border-radius: 25px;\n    cursor: pointer;\n    font-weight: bold;\n    font-size: 17px;\n    width: fit-content;\n    float: right;\n    margin-right: 20px;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
