import Oidc from 'oidc-client';

const config = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: 'netrx.ui',
    redirect_uri: `${process.env.REACT_APP_BASE_PATH}/authenticate`,
    response_type: 'code',
    scope: 'openid demographics ops_roles loginid netrx.api email netrx_resources role', // change this to be any scopes you require
    post_logout_redirect_uri: `${process.env.REACT_APP_BASE_PATH}`, // Where to redirect if logout is available
    // The following options are only required if you want to do a silent refresh
    automaticSilentRenew: true,  // will work on silent refresh later so seeing to false for now
    silent_redirect_uri: `${process.env.REACT_APP_BASE_PATH}/silent-refresh.html`
};

const mgr = new Oidc.UserManager(config);

// If the user object exists, then we know that the user has been authenticated, however this does not tell us if the access has expired, you can check that with user.expired 
export const getUser = () => {
      return mgr.getUser().then((user) => {
        return user;
      });}

export const authenticationRedirect = () => {
    mgr.signinRedirect();
}

export const getAccessToken = () => {
    return mgr.getUser().then(user => {
        return user.access_token
    })
}

export const checkAuthenticationResponse = () => {
    return new Oidc.UserManager({ response_mode: "query" })
        .signinRedirectCallback()
        .then(() => {
            return true;
        })
        .catch(e => {
            console.error(e);
            return false;
        });
}

export const logout = () => {
    mgr.signoutRedirect();
}
