import { ErrorMessages } from '../Interfaces/ErrorMessages';
import {
    getUser,
    getAccessToken,
    authenticationRedirect,
    checkAuthenticationResponse
} from './lib/auth'

export const isAuthenticated = () => {
    return getUser();
}

export const authenticateUser = () => {
    return authenticationRedirect();
}

export const didAuthenticate = () => {
    return checkAuthenticationResponse();
}

// In order to make a call to our API, we need to attach the user's access token to the http request as a bearer token
export const fetchData = () => {
    return getAccessToken().then(token =>
        fetch('api/SampleData/WeatherForecasts',
            {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else if (response.status === 401) {
                    return response.status;
                }
                else {
                    // handle any other response types
                }
            })
    )
}

const header = (method, additnlHeaders, body) => ({
    method: method,
    headers: additnlHeaders,
    body: body
})

const authHeader = (method, token, additnlHeaders = null, body = null) => ({
    method: method,
    withCredentials: true,
    credentials: 'include',
    headers: additnlHeaders ?
        {
            ...additnlHeaders,
            'Authorization': `Bearer ${token}`,
            'Pragma': 'no-cache'
        }
        : {
            'Authorization': `Bearer ${token}`,
            'Pragma': 'no-cache'
        },
    body: body
})

export const callApi = (endpoint, method, additnlHeaders, body = string, auth = bool) => {
    return getAccessToken().then(token => {
        return (auth ? fetch(endpoint, authHeader(method, token, additnlHeaders, body)) : fetch(endpoint, header(method, additnlHeaders, body)))
            .then(response => {
                // 401 needs to be reauthenticated
                // 403 is denied access to the resource, needs to gracefully fail...
                if (response.status === 403) {
                    const responseClone = response.clone();
                    responseClone.text().then((data) => {
                        if (JSON.parse(data) !== ErrorMessages.noFacAccess)
                            window.alert("You are not authorized to perform that action\n Please reach out to IT if you believe you are getting this message in error");
                    });
                }
                else if (response.status === 401) {
                    // TODO: Update this for redux-oidc library?
                    // need to handle 401 redirects
                    console.log("Need to reauthenticate");
                    let { pathname, search, hash } = window.location;
                    sessionStorage.setItem('history', JSON.stringify({ pathname, search, hash }));
                    authenticationRedirect();
                }
                // TODO: look into making a status 400 throw response.json? That way we can use then/catch instead of having then handle both
                else if (response.status === 200 || response.status === 400) {
                    return response.json();
                }
                else if (response.status === 204) {
                    return null;
                }
                // else{
                //     return response.json(); // every response from the server, except for 204, should have a body with a type & message.
                // }
                // What is supposed to happen here? - this gets hit on an un-expected/supported response type, such as a 500.
                // 
                // 
                else if (response.status === 500) {
                    throw "Internal Server Error";
                }
                
                throw response.json();
            })
            .catch(e => {
                if (navigator.onLine) {
                    throw e;
                }
                else {
                    // TODO: Alert the user of a network issue here?
                    if (!('serviceWorker' in navigator && 'SyncManager' in window)) {
                        window.alert("You appear to be offline, Any changes you make will not be saved.");
                    }
                }
            });
    });
}

// When we do an authentication redirect, we will want to save the address of what the unauthenticated user was trying to access so we can redirect them back after a successful authentication.

export const setHistory = (history) => {
    let historyString = JSON.stringify(history)
    sessionStorage.setItem('history', historyString);
}

export const getHistory = () => {
    let history = sessionStorage.getItem('history');
    sessionStorage.removeItem('history');
    return JSON.parse(history);
}