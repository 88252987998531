import React, { useContext, useEffect } from 'react';

import './App/app.css';
import { ErrorBoundary } from './App/ErrorBoundary';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LandingPage } from './Components/LandingPage/LandingPage';
import { RegistrationForm } from './Components/RegistrationForm/RegistrationForm';
import { OrderRefill } from './Components/OrderRefills/OrderRefills';
import { OrderRefillConfirmationPage } from './Components/OrderRefills/OrderRefillConfirmation';
import { UserProfile } from './Components/UserProfile/UserProfilePage';
import { PatientProfile } from './Components/PatientProfile/PatientProfile';
import { PatientInformationPage } from './Components/PatientInformation/PatientInformation';
import { TrackingPage } from './Components/TrackBatch/TrackingPage';
import { UserRequest } from './Components/AdminApproval/UserRequest';
import { NotFoundPage, ChoosePharmacyPage } from './Components/NotFoundPage';
import pharmacyNumContext, { selectedPharmacyInfo } from './Components/Authorization/AuthContext';
import { PendingRegistration } from './Components/UserProfile/PendingRegistration';
import PrivateRoute from './actions/PrivateRoute';
import Login from './common/components/Login';
import Logout from './common/components/Logout';
import Authenticate from './common/components/Authenticate';
import { genoaonline } from './environment';
import { DrugInformationPage } from './Components/DrugInformationPage/DrugInformationPage';

interface RoutesPageProps {
  userAuthenticated: boolean | null;
  setUserAuthenticated: (input: boolean | null) => void,
  setUser: (input: Oidc.User) => void,
  siteInfo: selectedPharmacyInfo,
  setShowNavbar: (input: boolean) => void,
  isPharmacyChosen: boolean,
  setSiteInfo: (selectedPharmacy: selectedPharmacyInfo) => void,
  user: Oidc.User | undefined;
  setActivePath: (input: string) => void;
}

const RoutesPage: React.FC<RoutesPageProps> = ({ userAuthenticated, setUserAuthenticated, setUser, siteInfo, setShowNavbar, isPharmacyChosen, setSiteInfo, user, setActivePath }) => {
  // @ts-ignore
  const context = useContext(pharmacyNumContext);
  const userId = user?.profile.sub ? user.profile.sub : "";
  const userName = user?.profile.email ? user.profile.email : "";
  const NetRxAdminRole = "NetRxAdmin";
  const appAccess = (user?.profile["idp"] === "Windows" || user?.profile["idp"] === "WindowsCredentials" || user?.profile["netrx_site"] || user?.profile["netrx_site_facility"]) ? true : false;
  
  useEffect(() => {
    if (appAccess) {
      setShowNavbar(true);
    }
  }, [userAuthenticated]);

    return (
      <ErrorBoundary>
    <BrowserRouter>
      <pharmacyNumContext.Provider value={{ siteInfo, setSiteInfo, userId, userName }}>
        <Routes>
          <Route path='/login' element={<Login setUserAuthenticated={setUserAuthenticated} setUser={setUser}/>} />
          <Route path='/authenticate' element={<Authenticate userIsAuthenticated={userAuthenticated} />} />
          <Route path='/logout' element={<Logout />} />
          {appAccess && <Route path="/" element={<PrivateRoute outlet={<LandingPage />} userIsAuthenticated={userAuthenticated} />} />}
          {appAccess && <Route path="/secure/genoa" element={<PrivateRoute outlet={<Redirect />} userIsAuthenticated={userAuthenticated} />} />}
          {(user?.profile["role"] === NetRxAdminRole) && <Route path="/secure/admin" element={<PrivateRoute outlet={<UserRequest />} userIsAuthenticated={userAuthenticated} />} />}
          {/*Registration page does not need site to be chosen so don't add that check here*/}
          {!appAccess && <Route path="secure/registration" element={<PrivateRoute outlet={<RegistrationForm setShowNavbar={setShowNavbar} />} userIsAuthenticated={userAuthenticated} />} />}
          {appAccess && <Route
            path="secure/user-profile"
            element={<PrivateRoute outlet={<UserProfile />} userIsAuthenticated={userAuthenticated} />}
          />}
          {appAccess && <Route path="/secure/home" element={<PrivateRoute outlet={<LandingPage />} userIsAuthenticated={userAuthenticated} />} />}
          {appAccess && <Route path="secure/order-refills">
            <Route index element={<PrivateRoute outlet={isPharmacyChosen ? <OrderRefill /> : <ChoosePharmacyPage setActivePath={setActivePath}/>} userIsAuthenticated={userAuthenticated} />} />
            <Route path="confirmation" element={<PrivateRoute outlet={<OrderRefillConfirmationPage />} userIsAuthenticated={userAuthenticated} />} />
          </Route>}
          {appAccess && <Route path="secure/patients" element={<PrivateRoute outlet={isPharmacyChosen ? <PatientProfile /> : <ChoosePharmacyPage setActivePath={setActivePath}/>} userIsAuthenticated={userAuthenticated} />} />}
          {appAccess && <Route path="secure/drug-information" element={<PrivateRoute outlet={isPharmacyChosen ? <DrugInformationPage /> : <ChoosePharmacyPage setActivePath={setActivePath}/>} userIsAuthenticated={userAuthenticated} />} />}
          {/* if no data passed in, errors out anyway*/}
          {appAccess && <Route path="confirmation" element={<PrivateRoute outlet={<OrderRefillConfirmationPage />} userIsAuthenticated={userAuthenticated} />} />}
          {appAccess && <Route path="secure/tracking" element={<PrivateRoute outlet={isPharmacyChosen ? <TrackingPage /> : <ChoosePharmacyPage setActivePath={setActivePath}/>} userIsAuthenticated={userAuthenticated} />} />}
          {appAccess && <Route
            path="secure/patients/patient-information/order-refill-confirmation"
            element={<PrivateRoute outlet={<OrderRefillConfirmationPage />} userIsAuthenticated={userAuthenticated} />}
          />}
          {appAccess && <Route path="secure/patients/patient-information" element={<PrivateRoute outlet={<PatientInformationPage />} userIsAuthenticated={userAuthenticated} />} />}
          <Route path="*" element={<PrivateRoute outlet={<NotFoundPage />} userIsAuthenticated={userAuthenticated} />} />
          {!appAccess && <Route path="secure/pending-registration" element={<PrivateRoute outlet={<PendingRegistration setShowNavbar={setShowNavbar} />} userIsAuthenticated={userAuthenticated} />} />}
        </Routes>
      </pharmacyNumContext.Provider>
            </BrowserRouter>
        </ErrorBoundary>
  );
};

function Redirect() {
  window.location.replace(genoaonline);
  return <></>;
}

export default RoutesPage;
