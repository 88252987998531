import React, { useEffect } from 'react';
import { logout } from '../../actions/lib/auth.js';

const Logout = () => {
    useEffect(() => {
        logout();      
    }, [])
     
    return (
        <div>
            <span style={{ paddingLeft: '20px'}}>
                Logging you out of the Authentication Server
            </span>
        </div>
    );   
};
export default Logout;

