import {Grid, Link} from '@mui/material';
import React from 'react';
import {Outlet} from 'react-router-dom';
import './app.css';
import {ThemeCacheProvider} from '../providers/ThemeCacheProvider';
import {Header} from '../common/components/Header/Header';
import headerContentMock from '../common/components/Header/header-mock.json';
import {HeaderContent} from '../common/components/Header/types/HeaderContent';
import {headerlogo} from '../environment';
const content = headerContentMock as HeaderContent;
content.headerLogo.logo = headerlogo;
content.headerLogo.logoAlt = 'OptumRx logo';

// Footer imports
import {Footer} from '../common/components/Footer';
import footerContentMock from '../common/components/Footer/footer-mock.json';
import { selectedPharmacyInfo } from '../Components/Authorization/AuthContext';
const {footerMain, footerSections} = footerContentMock;

interface LayoutProps {
  children:  React.ReactNode;
  userAuthenticated: boolean | null; 
  user: Oidc.User | undefined;
  siteInfo: selectedPharmacyInfo;
  setActivePath: (input: string) => void;
}

// Same layout without navbar
const NoAccessLayout: React.FC<LayoutProps> = ({children, userAuthenticated, user, setActivePath}) => (
  //{}: {children: React.ReactNode}
  <ThemeCacheProvider>
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Header
          content={content}
          memberNavigation={null}
          // onLogout={() => null}
          name={user?.profile.given_name + " " + user?.profile.family_name}
          hasUserDropdown={false}
          activePath={''}
          routingComponent={Link}
          userAuthenticated={userAuthenticated}
          siteName={''}
          setActivePath={setActivePath}
        />
      </Grid>
      <Outlet />

      <Grid item xs={12}>
        <Footer footerMain={footerMain} footerSections={footerSections} />
      </Grid>
    </Grid>
  </ThemeCacheProvider>
);

export default NoAccessLayout;
