import React from 'react';
import {Box, Link, Typography, Stack, Divider} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {FooterMain} from './types/FooterMain';
import {FooterSection} from './types/FooterSection';

export interface FooterProps {
  footerMain: FooterMain;
  footerSections: FooterSection[];
}


export const Footer: React.FC<FooterProps> = ({footerMain}) => {
  return (
    <Box component="footer" sx={{display: {xs: 'none', md: 'block'},borderTop: 2, borderTopColor: 'grey.200', borderTopStyle: 'solid', left: 0, right: 0, bottom: 0, backgroundColor: 'white', zIndex: '100'}} position={'fixed'}>
      <Stack
        sx={{py: 2, maxWidth: 1230, mx: 'auto',justifyContent: 'center'}}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Typography variant="body2">{footerMain.disclosure}</Typography>
        {footerMain.disclosureLinks.map((link) => (
          <Link key={link.title} href={link.url} target={link.target}>
            {link.title}
            {link.target && (
              <OpenInNewIcon aria-label="Opens in new tab." sx={{'&.MuiSvgIcon-root': {height: 0, width: 12}}} />
            )}
          </Link>
        ))}
      </Stack>
    </Box>
  );
};
