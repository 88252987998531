import {useRef, useEffect, MutableRefObject} from 'react';

import {scrollToTop as scrollClientToTop} from '../../utils/utils';

export const useFocusAndScrollPageToTop = <T extends HTMLElement>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: ReadonlyArray<any> = [],
  scrollToTop = false
): {
  focusElement: MutableRefObject<T>;
} => {
  const focusElement = useRef<HTMLElement>(null) as MutableRefObject<T>;

  useEffect(() => {
    if (focusElement.current) {
      focusElement.current.focus();
      if (scrollToTop) {
        scrollClientToTop();
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  }, [focusElement.current, ...deps]);
  return {
    focusElement
  };
};
