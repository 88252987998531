// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles for the patient profile search page */

.boxContainer {
    padding-bottom: 65px;
    padding-top: 0px;
}

#toggleSpacing{
    margin-right: 50px;
}

#nameSpacing{
    margin-left:20px;
}

#searchSpacing{
    margin-left: 20px;
    justify-content: space-between;
    align-items: flex-end;
    width: fit-content !important;
    max-width: fit-content;
}

#buttonsPosition{
    display: inline-flex;
}

#patientDisplay{
    width:95%;
    background-color: white;
    margin:0 auto;
    padding-bottom: 5%;
}
.MuiDataGrid-columnHeaderTitle{
    font-weight: 900
  }

.MuiDataGrid-row:nth-child(even){
    background-color: #f6f6f6;
  }


.pageTitle {
text-align: left;
color:white;
background-color:#004C97;
margin-bottom: 27px;
padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PatientProfile/PatientProfile.css"],"names":[],"mappings":"AAAA,+CAA+C;;AAE/C;IACI,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,8BAA8B;IAC9B,qBAAqB;IACrB,6BAA6B;IAC7B,sBAAsB;AAC1B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,SAAS;IACT,uBAAuB;IACvB,aAAa;IACb,kBAAkB;AACtB;AACA;IACI;EACF;;AAEF;IACI,yBAAyB;EAC3B;;;AAGF;AACA,gBAAgB;AAChB,WAAW;AACX,wBAAwB;AACxB,mBAAmB;AACnB,aAAa;AACb","sourcesContent":["/* styles for the patient profile search page */\n\n.boxContainer {\n    padding-bottom: 65px;\n    padding-top: 0px;\n}\n\n#toggleSpacing{\n    margin-right: 50px;\n}\n\n#nameSpacing{\n    margin-left:20px;\n}\n\n#searchSpacing{\n    margin-left: 20px;\n    justify-content: space-between;\n    align-items: flex-end;\n    width: fit-content !important;\n    max-width: fit-content;\n}\n\n#buttonsPosition{\n    display: inline-flex;\n}\n\n#patientDisplay{\n    width:95%;\n    background-color: white;\n    margin:0 auto;\n    padding-bottom: 5%;\n}\n.MuiDataGrid-columnHeaderTitle{\n    font-weight: 900\n  }\n\n.MuiDataGrid-row:nth-child(even){\n    background-color: #f6f6f6;\n  }\n\n\n.pageTitle {\ntext-align: left;\ncolor:white;\nbackground-color:#004C97;\nmargin-bottom: 27px;\npadding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
