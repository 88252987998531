import React, { useContext, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { buttonStyle, buttonStyleGray, CustomToggleButton } from '../PatientProfile/PatientProfileStyle';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../../common/components/AutoSuggestion/SearchBox.css';
import '../DrugInformationPage/DrugInformationOverylays.css';
import AutoSuggestion from '../../common/components/AutoSuggestion/autoSuggestion';
import { ErrorMessage, SuccessMessage } from '../../common/components/AlertMessages/AlertMessages';
import { DrugSearch } from '../../Interfaces/DrugInteractionsDTOModels';
import { fetchD2DPatientRx, fetchDrugLookup, fetchDrugSearch, fetchPatientSearch, fetchPatientSearchResults } from '../../actions/ProScriptAPI';
import pharmacyNumContext from '../Authorization/AuthContext';
import { IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './DrugInteraction.css'
import { ResultsList } from './Results';
import DeleteIcon from '@mui/icons-material/Delete';
import { PatientSearchResultsDTO } from '../../Interfaces/PatientDTOModel';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import { formatDate, formatNDC } from '../PatientInformation/Helpers';
import { PatientSearchOverlay } from '../../common/components/PatientSearchOverlay';
import { PrintButton } from '../../common/components/PrintButton';

// The main component for the drug interaction page
export const DrugInteractionPage = () => {
    const context = useContext(pharmacyNumContext);
    const siteNum = context.siteInfo.siteNumber;

    // State variables for the input value, loading indicator, error message, and more
    const [inputValue, setInputValue] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [successMessageVisibility, setSuccessMessageVisibility] = useState(false);
    const printRef = useRef<HTMLDivElement>(null);
    const [drugList, setDrugList] = useState<DrugSearch[]>([]);
    const [searchList, setSearchList] = useState<DrugSearch[]>(drugList);
    const [showPatOverlay, setShowPatOverlay] = useState(false);
    const [patOverlayData, setPatOverlayData] = React.useState<PatientSearchResultsDTO[] | null>(() => [{name: '', account: '', bDate: '', sex: '', fac: ''}]);
    const [showResults, setShowResults] = useState(false);

    //consts for handling toggling search option
    const [searchBy, setSearchBy] = React.useState<string>('DrugName');
    // Handle search by toggle button click
    const handleSearchBy = (
        event: React.MouseEvent<HTMLElement>,
        newSearchBy: string,
    ) => {
        if(newSearchBy!=null){
            setInputValue('')
            setSearchBy(newSearchBy);
        }
    };

    // Handle clear button click
    const handleClear = () => {
        setInputValue('');
        setShowLoading(false);
    };

    // Handle input value change
    const handleChangeSearchBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Updates text box value
        const input = event.target.value;
        setInputValue(input)
    };

    // Handle error message close
    const handleErrorMessageClose = () => {
        setErrorMessageVisibility(false);
        setErrorMessage('');
    };

    // Handle error message close
    const handleSuccessMessageClose = () => {
        setSuccessMessageVisibility(false);
        setSuccessMessage('');
    };

    // Fetch auto suggestion data for drug search
    async function fetchAutoSuggestionData(drugName: string) : Promise<[string, string][]> {
        try {
            const result: DrugSearch[] = await fetchDrugSearch(drugName, siteNum, true);
            const results: [string, string][] = result.map((item) => [item.drugName, item.ndc]);
            return results;
        }
        catch (error) {
            setErrorMessage("Error Fetching Drug Search. Please Try Again.");
            setErrorMessageVisibility(true);
            console.error('Error fetching drug search', error);
        }

        return [["Try again", ""]];
    };

    // Handle suggestion click
    function handleSuggestionClick (suggestion: [string, string]) {
        setInputValue('');
        setShowLoading(true);

        const ndcExists = drugList.some(model => model.ndc === suggestion[1]);

        if (ndcExists) {
            
            setErrorMessage('This Drug Has Already Been Added.');
            setShowLoading(false);
            setErrorMessageVisibility(true);
            return;
        }
        
        const selectedDrug: DrugSearch = {
            ndc: suggestion[1],
            drugName: suggestion[0]
        };

        setShowLoading(false);
        setShowResults(false);
        setDrugList([selectedDrug, ...drugList])
        setSuccessMessage(selectedDrug.drugName + " Has Been Added");
        setSuccessMessageVisibility(true);
    };

    // Handles when a user selects a patient from the auto suggestion
    const handleSuggestionSelectedPat = async (suggestion: [string, string]) => {
        setShowPatOverlay(true);

        try {
            const patName = suggestion[0];
            const result : PatientSearchResultsDTO[] = await fetchPatientSearchResults({patName, siteNum});

            result.forEach(row => {
                row.bDate = formatDate(row.bDate.toString());
            })
            setPatOverlayData(result);
        } 
        catch (error) {
            setErrorMessage("Error Fetching Patient Information. Please Try Again.");
            setErrorMessageVisibility(true);
            console.error('Error fetching patient search information', error);
        }
    };

    // Function to be passed to the auto suggestion component (retrieves suggestions for the auto suggestion)
    async function fetchAutoSuggestionDataPat(patName: string) : Promise<[string, string][]>  {
        try {
            const result : string[] = await fetchPatientSearch({patName, siteNum});
            const results: [string, string][] =  result.map((item) => [item, ""]);
            return results;
        } 
        catch (error) {
            setErrorMessage("Error Fetching Patient Search. Please Try Again.");
            setErrorMessageVisibility(true);
            console.error('Error fetching patient search', error);
        }
        return [["Try again", ""]];
    };

    const handlePatientCloseOverlay = () => {
        setShowPatOverlay(false);
    };

    async function handleNDCSearch () {
        setShowLoading(true);

        let strippedNDC = inputValue.replace(/-/g, '').replace(' ', '');
        const ndcExists = drugList.some(model => model.ndc === strippedNDC);
        setInputValue('');

        if (strippedNDC.length !== 11) {
            setErrorMessage('Invalid NDC Number. Please Try Again.');
            setErrorMessageVisibility(true);
            setShowLoading(false);
            return;
        }

        try {
            const result : DrugSearch = await fetchDrugLookup(strippedNDC, siteNum);

            if (ndcExists) {
                
                setErrorMessage('This Drug Has Already Been Added.');
                setShowLoading(false);
                setErrorMessageVisibility(true);
            }
            else if (result != null && result.drugName !== "") {

                setDrugList([result, ...drugList])
                setShowLoading(false);
                setShowResults(false);
                setSuccessMessage(result.drugName + " Has Been Added");
                setSuccessMessageVisibility(true);
            }
            else {
                setErrorMessage("Could Not Find Drug. Please Try Again.");
                setShowLoading(false);
                setErrorMessageVisibility(true);
            }
        }
        catch {
            setShowLoading(false);
            setErrorMessage("An Error Occurred. Please Try Again.");
            setErrorMessageVisibility(true);
            return;
        }
    };

    async function handlePatientSearch (account: string) {
        setInputValue('');
        setShowLoading(true);
        setPatOverlayData(null);

        function filterDrugList(item: DrugSearch) {
            return !drugList.some(model => model.ndc === item.ndc);
        }

        try {
            const result : DrugSearch[] = await fetchD2DPatientRx(siteNum.toString(), account);
            if (result != null) {
                const filteredResult = result.filter(filterDrugList);
                setDrugList(drugList => [...drugList, ...filteredResult]);
                setShowResults(false);
                setShowLoading(false);
                setSuccessMessage("All Patient Drugs Have Been Added");
                setSuccessMessageVisibility(true);
            }
        }
        catch {
            setErrorMessage("Error Fetching D2D Patient Rx Information. Please Try Again.");
            setErrorMessageVisibility(true);
            setShowLoading(false);
            return;
        }
    };

    function handleDelete (ndc: string) {
        setDrugList(drugList.filter((item) => item.ndc !== ndc));
        setShowResults(false);
    };

    function handleInteractionSearch () {
        setSearchList(drugList);
        setShowResults(true);
    }

    return (
        <>
            {showPatOverlay && 
                <PatientSearchOverlay patientSearchResults={patOverlayData} onClose={handlePatientCloseOverlay} searchFunction={handlePatientSearch} />
            }
            {errorMessageVisibility && 
                <ErrorMessage message={errorMessage} onClose={handleErrorMessageClose}/>
            }
            {successMessageVisibility && 
                <SuccessMessage message={successMessage} onClose={handleSuccessMessageClose}/>
            }
            <div id='drug-layout'>
                <Grid id='patientDisplay'>
                    <Typography className='header'><b>Search Drug</b></Typography>
                        <Grid container spacing={0} alignItems="end">
                            <Grid id="toggleSpacing">
                            <Typography className='searchWithHeader'><b>Search With</b></Typography>
                                <ToggleButtonGroup
                                    value={searchBy}
                                    exclusive
                                    onChange={handleSearchBy}
                                    >
                                    <ToggleButton sx={CustomToggleButton} value="DrugName">
                                        Drug Name
                                    </ToggleButton>
                                    <ToggleButton data-testid="toggle-account-button" sx={CustomToggleButton} value="NDC">
                                        NDC Number
                                    </ToggleButton>
                                    <ToggleButton sx={CustomToggleButton} value="Patient">
                                        Patient Name
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid>
                                {searchBy=='DrugName' && <Typography className='subheader'><b>Drug Name</b></Typography> }
                                {searchBy==='NDC' && <Typography className='subheader'>
                                    <b>NDC Number</b>
                                    <Tooltip placement="right" title={<div id="helper-text">Please provide full NDC Number.</div>}>
                                        <IconButton  style={{padding: '1px', paddingLeft: '5px', marginBottom: '2px', color: '#004C97'}}>
                                            <InfoOutlinedIcon fontSize='small'/>
                                        </IconButton>
                                    </Tooltip>
                                </Typography> }
                                {searchBy==='Patient' && <Typography className='subheader'><b>Patient Name</b></Typography>}
                                {searchBy=='DrugName' && <AutoSuggestion
                                    onSuggestionSelected={handleSuggestionClick}
                                    searchType="drugSearch"
                                    isRequired={true}
                                    setSelectedValue={() => {}}
                                    inputValue={inputValue}
                                    setInputValue={setInputValue}
                                    fetchData={fetchAutoSuggestionData}
                                    placeholder='Drug Name'
                                    secondColNDC={true}
                                />}
                                {searchBy==='NDC' && <input placeholder='NDC Number' value={inputValue} onChange={handleChangeSearchBox} id='patientSearch'></input> }
                                {searchBy==='Patient' &&<AutoSuggestion
                                    onSuggestionSelected={handleSuggestionSelectedPat}
                                    searchType="drugSearch"
                                    isRequired={true}
                                    setSelectedValue={() => {}}
                                    inputValue={inputValue}
                                    setInputValue={setInputValue}
                                    fetchData={fetchAutoSuggestionDataPat}
                                    placeholder='Patient Name'
                                    secondColNDC={false}
                                />}
                            </Grid>
                            <Grid container item xs={1.5} id = "searchSpacing">
                                {searchBy=='DrugName' || searchBy=='Patient'?
                                    <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
                                        <RefreshIcon/>&nbsp;Reset
                                    </Button>:
                                    <div id='buttonsPosition'>
                                        <Button variant='contained'sx={buttonStyle} onClick={(handleNDCSearch)}>Search</Button>
                                        <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
                                            <RefreshIcon/>&nbsp;Reset
                                    </Button>
                                    </div>
                                }         
                            </Grid>
                        </Grid>

                    <div className='info-container' ref={printRef}>
                        <div className='info-box'>
                            <PrintButton printRef={printRef} />
                            <div className='drug-title'>Drug List</div>
                            {drugList.length === 0 ? 
                                <p className='drug-list'>No Drugs Added</p>
                            : 
                            <div className="result-container">
                                <div className="result-row">
                                    <div className="result-title-row">Drug Name</div>
                                    <div className="result-title-row">NDC Number</div>
                                    <div className="result-title-row">Action</div>
                                </div>
                                {drugList.map((item, index) => (
                                    <div className="result-row" key={`${item.ndc}`}>
                                        <div className="result-content-row">{item.drugName}</div>
                                        <div className="result-content-row">{formatNDC(item.ndc)}</div>
                                        <div className="result-content-row">
                                            <IconButton onClick={() => handleDelete(item.ndc)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            }
                        </div>
                        {showResults && 
                            <ResultsList drugList={searchList} setErrorMessage={setErrorMessage} setVisibility={setErrorMessageVisibility} setShowResults={setShowResults}/>
                        }
                    </div>
                </Grid>
                {showLoading && <LoadingSpinner {...{ height:"200px", width:"50%"}}/>}
                <div className='processButton' onClick={handleInteractionSearch}>Check Interactions</div>
            </div>
        </>
    );
}
