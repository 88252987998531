import React, { useEffect, useState } from 'react';
import './PatientInformation.css';
import {formatDate} from './Helpers';
import {PatientRxDTO} from '../../Interfaces/PatientDTOModel';
import {DrugRow} from './DrugImageOverlay';
import {RxRow, RefillRow} from './RxOverlay';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import '../../common/components/AutoSuggestion/SearchBox.css';

export const PatientRx: React.FC<PatientRxDTO> = ({ data, updateFunction, requestedRefills, siteNum, user }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filters, setFilter] = useState({fromDate: '', toDate: '', rxNumber: '', drugName: '', docName: ''});
    const [fromDateFilter, setFromDateFilter] = useState('');
    const [toDateFilter, setToDateFilter] = useState('');
    const [rxNumFilter, setRxNumFilter] = useState('');
    const [drugNameFilter, setDrugNameFilter] = useState('');
    const [docNameFilter, setDocNameFilter] = useState('');
    const [filteredRxData, setFilteredRxData] = useState(data);

    useEffect (() => {
        setFilteredRxData(data);
    }, [data]);

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleInputChange = (setter: any) => (event: any) => {
        setter(event.target.value);
    };

    useEffect (() => {
        setFilter({fromDate: fromDateFilter, toDate: toDateFilter, rxNumber: rxNumFilter, drugName: drugNameFilter, docName: docNameFilter});
    }, [fromDateFilter, toDateFilter, rxNumFilter, drugNameFilter, docNameFilter]);

    const handleApplyFilters = () => {
        if (data) {
            setFilteredRxData(data.filter(model => {
                let filterFromDate = new Date(filters.fromDate);
                let filterToDate = new Date(filters.toDate);
                
                // Retrieve most recent rx date to display in filtering
                let allDates = [...model.refills.map(model => model.refillDate), model.rxDate];
                let highestDate = Math.max(...allDates).toString();
                let rxDate = new Date(
                    parseInt(highestDate.slice(0, 4)), 
                    parseInt(highestDate.slice(4, 6)) - 1,
                    parseInt(highestDate.slice(6, 8))
                );

                const passesFromDate = !filters.fromDate || rxDate >= filterFromDate;
                const passesToDate = !filters.toDate || rxDate <= filterToDate;
                const passesRxNumFilter = !filters.rxNumber || model.rxNum.toString() === filters.rxNumber;
                const passesDrugNameFilter = !filters.drugName || (model.drugDesc.toUpperCase()).includes(filters.drugName.toUpperCase());
                const passesDocNameFilter = !filters.docName || (model.docName.toUpperCase()).includes(filters.docName.toUpperCase());
    
                return passesFromDate && passesToDate && passesRxNumFilter && passesDrugNameFilter && passesDocNameFilter;
            }));
            setPage(0)
            
        }
    };

    const handleResetFilters = () => {
        setFilteredRxData(data);
        setFromDateFilter('');
        setToDateFilter('');
        setRxNumFilter('');
        setDrugNameFilter('');
        setDocNameFilter('');

        
    }

    if (data == null || filteredRxData == null) {
        return <p>Prescription Information For This Patient Not Found: Please Try Again</p>;
    }

    if (data.length === 0) {
        return <p>This Patient Does Not Have Any Prescriptions</p>
    }

    const getRowColor = (index: number) =>  {
        return index % 2 === 0 ? 'white' : '#FAFAFA';
    }

    function isInRequestedRefills(rxNumber: number) {
        for (let i = 0; i < requestedRefills.length; i++) {
            if (requestedRefills[i]?.Rx === rxNumber.toString()) {
                return true;
            }
        }
        return false;
    }

    return(
        <>
            <div className="filters-container">
                <div className="filter-container-short">
                    <label htmlFor="date">From Date</label>
                    <input placeholder='From Date' type="date" id="datePicker" value={fromDateFilter} onChange={handleInputChange(setFromDateFilter)} />
                </div>
                <div className="filter-container-short">
                    <label htmlFor="datePicker">To Date</label>
                    <input placeholder='To Date' type="date" id="datePicker" value={toDateFilter} onChange={handleInputChange(setToDateFilter)} />
                </div>
                <div className="filter-container-short">
                    <label htmlFor="textbox">Rx Number</label>
                    <input className='filter-input-field' placeholder='Rx Number' type="text" value={rxNumFilter} onChange={handleInputChange(setRxNumFilter)} />
                </div>
                <div className="filter-container">
                   <label htmlFor="textbox">Drug Name</label>
                    <input className='filter-input-field' placeholder='Drug Name' type="text" value={drugNameFilter} onChange={handleInputChange(setDrugNameFilter)} />
                </div>
                <div className="filter-container">
                    <label htmlFor="textbox">Doctor Name</label>
                    <input className='filter-input-field' placeholder='Doctor Name' type="text" value={docNameFilter} onChange={handleInputChange(setDocNameFilter)} />
                </div>
                <button className="applyFilter-Button" onClick={handleApplyFilters}>Apply Filters</button>
                <button className="reset-Button" onClick={handleResetFilters}>
                    <RefreshIcon/>
                    <div style={{ paddingLeft: '5px'}}>Reset</div>
                </button>
            </div>
            <Table className='tableStyle-Rx'>
                <TableHead>
                    <TableRow className='headerRow'>
                        <TableCell className='headerRowCells' sx={{ minWidth: '120px'}}>Rx Number</TableCell>
                        <TableCell className='headerRowCells' sx={{ minWidth: '200px'}}>Drug Name</TableCell>
                        <TableCell className='headerRowCells' sx={{ whiteSpace: 'nowrap' }}>Fills/Quan Remaining</TableCell>
                        <TableCell className='headerRowCells' sx={{ minWidth: '120px'}}>Directions</TableCell>
                        <TableCell className='headerRowCells' sx={{ minWidth: '180px'}}>Doctor</TableCell>
                        <TableCell className='headerRowCells' sx={{ minWidth: '120px'}}>Date</TableCell>
                        <TableCell className='headerRowCells' sx={{ minWidth: '80px'}}>Quan</TableCell>
                        <TableCell className='headerRowCells' sx={{ minWidth: '120px'}}>Drug Image</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0 ? filteredRxData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRxData).map((row, index) => (
                        <React.Fragment key={row.rxNum}>
                        <TableRow key={row.rxNum} sx={{ backgroundColor: getRowColor(index)}}>
                            <TableCell>
                                <input type='checkbox' checked={isInRequestedRefills(row.rxNum)} className='centeredCheckbox' onChange={() => updateFunction({StoreNum: siteNum, Username: user, Rx: row.rxNum.toString(), IsStat: false})}/> 
                                <RxRow rxNum={row.rxNum} refillNum={0} siteNum={siteNum}></RxRow>
                            </TableCell>
                            <TableCell>{row.drugDesc}</TableCell>
                            <TableCell>{row.fills}/#{row.prAuthQuanAccum}</TableCell>
                            <TableCell>{row.directions}</TableCell>
                            <TableCell>{row.docName}</TableCell>
                            <TableCell>{formatDate(row.rxDate.toString())}</TableCell>
                            <TableCell align='center'>{row.quanDisp}</TableCell>
                            <TableCell align='center'>                
                                <DrugRow ndc={row.ndc} drugDesc={row.drugDesc}></DrugRow>
                            </TableCell>
                        </TableRow>
                        {row.refills.map((item, innerIndex) => (
                            <TableRow key={`${row.rxNum}-${innerIndex}`} sx={{ backgroundColor: getRowColor(index)}}>
                                <TableCell></TableCell>
                                <TableCell>
                                    <RefillRow rxNum={row.rxNum} refillNum={item.refillNum} siteNum={siteNum}></RefillRow>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{item.directions}</TableCell>
                                <TableCell>{item.doctName}</TableCell>
                                <TableCell>{formatDate(item.refillDate.toString())}</TableCell>
                                <TableCell align='center'>{item.quanDisp}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={filteredRxData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </>
    );
};

export default PatientRx;
