import { AdminSiteFacsDTO, UserRequestDTO } from "../Interfaces/AccessRequests";
import { callApi } from './index.js';
const apiUrl = process.env['REACT_APP_NETRX_API'];

export async function GetAllRequestsForAdmin() {
    const response = await callApi(`${apiUrl}api/Admin/GetAllRequestsForAdmin`, 'GET', null, null, true);
    const result: UserRequestDTO[] = await response;
    return result;
};

export async function GetAdminSiteFac( role: string ) {
  const response = await callApi(`${apiUrl}api/Admin/GetListSiteFac?role=${role}`, 'GET', null, null, true);
  const result: AdminSiteFacsDTO[] = await response;
  return result;
};

export async function GetIsAdmin () {
  const response = await callApi(`${apiUrl}api/Admin/GetIsAdmin`, 'GET', null, null, true);
  const result: boolean = await response;
  return result;
}

export async function GetAccessesForUser(userId: string){
  const response = await callApi(`${apiUrl}api/Admin/GetUserSiteFac?userId=${userId}`, 'GET', null, null, true);
  const result: any = await response;
  return result;
}