import React, {useEffect, useState} from 'react';
import './PatientInformation.css';
import PatientOverlayComponent from './PatientOverlay';
import {formatDate} from './Helpers';
import {PatientDetailDTO} from '../../Interfaces/PatientDTOModel';
import Tooltip from '@mui/material/Tooltip';
import { Button, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// PatientDetails component that takes a PatientDetailDTO object as a prop
export const PatientDetails: React.FC<PatientDetailDTO> = ({patient}) => {
  // State to store patient address
  const [patientAddress, setPatientAddress] = useState<string>('');

  // Overlay controls
  const [overlayAllergyVisible, setOverlayAllergyVisible] = useState(false);
  const [overlayDiagnosisVisible, setOverlayDiagnosisVisible] = useState(false);
  
  const formatAddress = (fullAddress: string) => {
    if (patient === null) return "";

    if (patient.patCity !== "" || patient.patState !== "" || patient.patZip !== "") {
      if (fullAddress !== "") fullAddress += ", ";
      if (patient.patCity !== "") {
        fullAddress += patient.patCity;
        if (patient.patState) {
          fullAddress += ", " + patient.patState;
          if (patient.patZip !== "") {
            fullAddress += " " + patient.patZip;
          }
        }
      } 
    }

    return fullAddress;
  }

  // useEffect hook to update patient address when patient data changes
    useEffect(() => {
        if (!patient) {
          setPatientAddress("-");
          return;
        }

        let fullAddress = "";

        // Construct full address from patient address components
        if (patient.patAdd1 !== "") fullAddress += patient.patAdd1;

        fullAddress = formatAddress(fullAddress);

        // If full address is empty, set it to "-"
        if (fullAddress === "") fullAddress = "-";

        setPatientAddress(fullAddress);
    }, [patient]);

    // If patient data is null, display "Patient Details Not Found" message
    if (patient == null) {
        return <p>Patient Details Not Found: Please Try Again</p>;
    }

    const retrieveGender = (gender: string) => {
      if (gender === 'F') return "Female";
      if (gender === 'M') return "Male";
      return "-";
    }

  return (
    <div>
      <div className="patientInfoContainer">
        {/* Name Box */}
        <div className="nameBox">{patient.name}</div>
        
        <div className='infoBox'>
          <div className='infoRow'>
            <div className='title-content'>
              <div className='infoTitle'>Date of Birth</div>
              <div className="infoValue">
                {formatDate(patient.bDate.toString()) ? formatDate(patient.bDate.toString()) : '-'}
              </div>
              <div className='infoTitle' style={{ marginTop: '15px'}}>Account Number
                <Tooltip placement="right" title={<div id="helper-text">Internal patient ID number. Not insurance related.</div>}>
                  <IconButton  style={{padding: '0px', paddingLeft: '5px', color: '#004C97'}}>
                    <InfoOutlinedIcon fontSize='small'/>
                  </IconButton>
                </Tooltip>
              </div>              
              <div className="infoValue">{patient.account}</div>
            </div>
            <div className='title-content'>
              <div className='infoTitle'>Gender</div>
              <div className='infoValue'>{retrieveGender(patient.sex)}</div>
              <div className='infoTitle' style={{ marginTop: '15px'}}>Primary Physician</div>
              <div className='infoValue'>{patient.priDocName === "" ? "-" : patient.priDocName}</div>
            </div>
            <div className='title-content'>
              <div className='infoTitle'>Address</div>
              <div className="infoValue">{patientAddress}</div>
              <div className="infoTitle" style={{ marginTop: '15px'}}>Facility
                  <Tooltip placement="right" title={<div id="helper-text">Internal patient placement.</div>}>
                  <IconButton  style={{padding: '0px', paddingLeft: '5px', color: '#004C97'}}>
                      <InfoOutlinedIcon fontSize='small'/>
                    </IconButton>
                  </Tooltip>
              </div>               
              <div className="infoValue">{patient.fac} - {patient.facName}</div>
            </div>
            <div className='title-content' style={{ borderLeft: '1px solid #cecece'}}>
              <Button className="overlay-link-patient" style={{ marginBottom: '20px' }} onClick={() => setOverlayAllergyVisible(!overlayAllergyVisible)}>Allergies</Button>
              {overlayAllergyVisible && (
                <PatientOverlayComponent
                  onClose={() => setOverlayAllergyVisible(false)}
                  items={patient.allergies}
                  title="Allergies"
              />
              )}
              <Button className="overlay-link-patient" style={{ marginTop: '20px' }} onClick={() => setOverlayDiagnosisVisible(!overlayDiagnosisVisible)}>Diagnosis</Button>
              {overlayDiagnosisVisible && (
              <PatientOverlayComponent
                onClose={() => setOverlayDiagnosisVisible(false)}
                items={patient.diagnoses}
                title="Diagnosis"
              />
            )}
            </div>
          </div>
        </div>
        </div>
      </div>
  );
};

export default PatientDetails;
