import { OrderRefillsDTO, OrderRefillsPageDTO, OrderRefillProcessResponse } from "../Interfaces/OrderRefills";
import { callApi } from './index.js';

const apiUrl = process.env['REACT_APP_NETRX_API'];

export async function addOrderRefill(orderRefill: OrderRefillsDTO) {
    let body = JSON.stringify(orderRefill);
    const response = await callApi(`${apiUrl}api/OrderRefills/AddRxToList`, 'POST', {'Content-Type': 'application/json'}, body, true);
    const result : OrderRefillsPageDTO = await response;
    return result;
}

export async function processOrderRefill(orderRefills: OrderRefillsDTO[]) {
    let body = JSON.stringify(orderRefills);
    const response = await callApi(`${apiUrl}api/OrderRefills/ProcessOrderRefillsFromList`, 'POST', {'Content-Type': 'application/json'}, body, true);
    const result : OrderRefillProcessResponse[] = await response;
    return result;
}

export async function deleteOrderRefill(orderRefills: OrderRefillsDTO) {
    let body = JSON.stringify(orderRefills);
    const response = await callApi(`${apiUrl}api/OrderRefills/DeleteRxFromList`, 'DELETE', {'Content-Type': 'application/json'}, body, true);
    const result : string = await response;
    return result;
}

export async function fetchAllOrderRefillsByUsername(storeNum: string) {
    try {
        const response = await callApi(`${apiUrl}api/OrderRefills/GetAllOrderRefillsByUsername?storeNum=${storeNum}`, 'GET', null, null, true);
        const result : OrderRefillsPageDTO[] = await response;
        return result;
    }
    catch {
        console.error("Unable to retrieve Order Refills based on Username");
        return ([]);
    }
}

