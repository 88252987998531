import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { didAuthenticate, getHistory } from '../../actions/index.js';

interface AuthenticateProps {
    userIsAuthenticated: boolean | null;
}

const Authenticate: React.FC<AuthenticateProps> = ({ userIsAuthenticated }) => {
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [authenticating, setAuthenticating] = useState(true);
    const [history, setHistory] = useState({});


    useEffect(() => {
        didAuthenticate().then((success: boolean) => {
            if (success && !redirectToReferrer) {
                setRedirectToReferrer(true);
                setAuthenticating(false);
            }
            else if (!success) {
                setAuthenticating(false);
            }
        });

        setHistory(getHistory());
    }, [])


    if (redirectToReferrer) {
        return <Navigate to={history} />
    }

    if (!redirectToReferrer && !authenticating) {
        return <Navigate to={'/unauthorized'} />
    }

    else {
        return (
            <div>
                <p style={{ paddingLeft: '20px'}}> Checking login status </p>
                <p style={{ paddingLeft: '20px'}}> You will be redirected soon </p>
                <p style={{ paddingLeft: '20px'}}> Please wait... </p>
            </div>
        )
    }
};

export default Authenticate;