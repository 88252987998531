import { FormControl, Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import '../LandingPage/LandingPage.css'
import React, { useEffect, useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import { UserRequestDTO } from "../../Interfaces/AccessRequests";
import { GetAllRequestsForAdmin } from "../../actions/Admins";
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import UserRequestForm from "./UserRequestForm";
import { LoadingSpinner } from "../../common/components/LoadingSpinner/LoadingSpinner";

// Defining the UserRequest component
export const UserRequest = () => {
    // State variables for pagination, filters, and data
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState({userName: '', firstName: '', lastName: '', pharmacy: '', status: ''});
    const [usernameFilter, setUsernameFilter] = useState('');
    const [firstNameFilter, setFirstNameFilter] = useState('');
    const [lastNameFilter, setLastNameFilter] = useState('');
    const [pharmacyFilter, setPharmacyFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [filteredData, setFilteredData] = useState<UserRequestDTO[]>([]);
    const [userRequests, setUserRequests] = useState<UserRequestDTO[]>([]);
    const [showRequest, setShowRequest] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState<UserRequestDTO>({userAccessId: -1, userId: '', firstName: '', lastName: '', email: '', siteId: -1, siteName: '', statusId: '', createdDate: '', updatedTime: '', additionalNotes: ''});
    const [showLoading, setShowLoading] = useState(false);

    // Fetching user requests when the component mounts or when showRequest changes
    useEffect(() => {
        setShowLoading(true);
        const fetchRequests = async () => 
        {
          const requests:UserRequestDTO[] = await GetAllRequestsForAdmin();
          setUserRequests(requests);
          setFilteredData(requests);
          setShowLoading(false);
        };
        fetchRequests();
    }, [showRequest])

    // Handling page change
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    // Handling rows per page change
    const handleChnageRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Handling input changes for filters
    const handleInputChange = (setter: any) => (event: any) => {
        setter(event.target.value);
    };

    // Updating filters when filter values change
    useEffect (() => {
        setFilters({userName: usernameFilter, firstName: firstNameFilter, lastName: lastNameFilter, pharmacy: pharmacyFilter, status: statusFilter});
    }, [usernameFilter, firstNameFilter, lastNameFilter, pharmacyFilter, statusFilter]);

    // Applying filters to the data
    const handleApplyFilters = () => {
        setFilteredData(userRequests.filter(model => {
            const passesUsernameFilter = !filters.userName || (model.email.toUpperCase()).includes(filters.userName.toUpperCase());
            const passesFirstNameFilter = !filters.firstName || (model.firstName.toUpperCase()).includes(filters.firstName.toUpperCase());
            const passesLastNameFilter = !filters.lastName || (model.lastName.toUpperCase()).includes(filters.lastName.toUpperCase());
            const passesPharmacyFilter = !filters.pharmacy || (model.siteName.toUpperCase()).includes(filters.pharmacy.toUpperCase());
            const passesStatusFilter = filters.status === 'All' || model.statusId === filters.status;

            return passesUsernameFilter && passesFirstNameFilter && passesLastNameFilter && passesPharmacyFilter && passesStatusFilter;
        }));
        setPage(0)
    };

    // Resetting filters
    const handleResetFilters = () => {
        setFilteredData(userRequests);
        setUsernameFilter('');
        setFirstNameFilter('');
        setLastNameFilter('');
        setPharmacyFilter('');
        setStatusFilter('All');
    };

    // Getting row color based on index
    const getRowColor = (index: number) =>  {
        return index % 2 === 0 ? 'white' : '#FAFAFA';
    };

    // Handling request click
    const handleRequestClick = (request: UserRequestDTO) => {
        setSelectedRequest(request);
        setShowRequest(true);
    };

    // Handling request close
    const handleCloseRequest = () => {
        setShowRequest(false);
        setSelectedRequest({userAccessId: -1, userId: '', firstName: '', lastName: '', email: '', siteId: -1, siteName: '', statusId: '', createdDate: '', updatedTime: '', additionalNotes: ''});
    }

    // Rendering the component
    return (
        <div className='patientInformation'>
            <Grid id='patientDisplay' style={{ paddingBottom: '40px'}}>
            <Typography className='header'><b>User Requests</b></Typography>
            <div className="filters-container">
                <div className="filter-container-med">
                    <label htmlFor="textbox">Email</label>
                    <input className="input-field" placeholder='Email' type="text" value={usernameFilter} onChange={handleInputChange(setUsernameFilter)} />
                </div>
                <div className="filter-container">
                    <label htmlFor="textbox">First Name</label>
                    <input className="input-field" placeholder='First Name' type="text" value={firstNameFilter} onChange={handleInputChange(setFirstNameFilter)} />
                </div>
                <div className="filter-container">
                    <label htmlFor="textbox">Last Name</label>
                   <input className="input-field" placeholder='Last Name' type="text" value={lastNameFilter} onChange={handleInputChange(setLastNameFilter)} />
                </div>
                <div className="filter-container-med">
                   <label htmlFor="textbox">Pharmacy</label>
                    <input className="input-field" placeholder='Pharmacy' type="text" value={pharmacyFilter} onChange={handleInputChange(setPharmacyFilter)} />
                </div>
                <div className="filter-container-med">
                    <label htmlFor="textbox">Status</label>
                    <FormControl style={{ width: '100%' }}>
                        <Select
                            value={statusFilter}
                            onChange={handleInputChange(setStatusFilter)}
                            className="status-dropdown"
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                            <MenuItem value="Approved">Approved</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <button className="applyFilter-Button" onClick={handleApplyFilters}>Apply Filters</button>
                <button className="reset-Button" onClick={handleResetFilters}>
                    <RefreshIcon/>
                    <div style={{ paddingLeft: '5px'}}>Reset</div>
                </button>            
            </div>
            {showLoading ? <LoadingSpinner {...{ height:"200px", width:"50%"}}/> : 
            <div>
            <Table style={{border: '1px solid #F3F3F3'}}>
                <TableHead>
                    <TableRow className="table-header-row">
                        <TableCell className="table-header-title">Name</TableCell>
                        <TableCell className="table-header-title">Email</TableCell>
                        <TableCell className="table-header-title">Pharmacy</TableCell>
                        <TableCell className="table-header-title">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0 ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredData).map((row, index) => (
                        <TableRow key={row.userAccessId} sx={{ backgroundColor: getRowColor(index)}}>
                            <TableCell style={{ padding: '0px', paddingLeft: '5px' }}>
                                <IconButton style={{ color: "#004C97"}} onClick={() => handleRequestClick(row)}>
                                    <RateReviewOutlinedIcon/>
                                </IconButton>
                                {row.firstName} {row.lastName}
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.siteName}</TableCell>
                            <TableCell>{row.statusId}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChnageRowsPerPage}
            />
            </div>
            }
         </Grid>
         {showRequest && 
         <UserRequestForm request={selectedRequest} onClose={handleCloseRequest}/>}
    </div>
    );
};