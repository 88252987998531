import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { DrugInteractionPage } from '../Drug2DrugInteraction/DrugInteractionPage';
import {DrugMonographPage} from '../DrugMonograph/DrugMonographPage';
import {DrugImagePage} from '../DrugImage/DrugImagePage';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index} = props;
  return (
    <div hidden={value !== index} id={`vertical-tabpanel-${index}`} style={{width: '100%'}}>
      {value === index && (
        <Box sx={{p: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const DrugInformationPage = () => {
  const [value, setValue] = React.useState(0); // 0: drug-to-drug interaction, 1: drug monograph, 2: drug image

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const drugToDrugDescription = 'A drug interaction is a reaction between two (or more) drugs';
  const drugMonographDescription =
    'A monograph is a written document that reflects the quality attributes of medicines approved by the U.S. Food and Drug Administration (US FDA).';
  const drugImageDescription =
    'The easiest way to look up drug information, identify pills, check interactions, and set up your own personal medication records.';

  return (
    <Box sx={{display: 'flex', height: '900px', marginTop: '-30px'}}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        sx={{ borderRight: 1, borderColor: 'divider', minWidth: '15%', float: 'left', bgcolor: '#EBEBEB' }}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#D22630',
            left: 0,
            width: '4px'
          }
        }}
      >
        <Tab
          sx={{textTransform: 'none', color: 'black', ':hover': {textDecoration: 'underline'}, textAlign: 'start', minHeight: '120px'}}
          label={
            <div>
              <div style={{fontSize: '22px', paddingBottom: '5px'}} className={value === 0 ? 'selected-tab' : ''}>{'Drug to Drug Interaction'}</div>
              <div style={{fontSize: '15px', color: 'black'}}>{drugToDrugDescription}</div>
            </div>
          }
        />
        <Tab
          sx={{textTransform: 'none', color: 'black', ':hover': {textDecoration: 'underline'}, textAlign: 'start'}}
          label={
            <div>
              <div style={{fontSize: '22px', paddingBottom: '5px'}} className={value === 1 ? 'selected-tab' : ''}>{'Drug Monograph'}</div>
              <div style={{fontSize: '15px', color: 'black'}}>{drugMonographDescription}</div>
            </div>
          }
        />
        <Tab
          sx={{textTransform: 'none', color: 'black', ':hover': {textDecoration: 'underline'}, textAlign: 'start'}}
          label={
            <div>
              <div style={{fontSize: '22px', paddingBottom: '5px'}} className={value === 2 ? 'selected-tab' : ''}>{'Drug Image'}</div>
              <div style={{fontSize: '15px', color: 'black'}}>{drugImageDescription}</div>
            </div>
          }
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <DrugInteractionPage />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DrugMonographPage />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DrugImagePage />
      </TabPanel>
    </Box>
  );
};
