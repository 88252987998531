import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './App/app.css';
import reportWebVitals from './reportWebVitals';
import RoutesPage from './Routes';
import { getUser } from './actions/lib/auth';
import Layout from './App/layout';
import NoAccessLayout from './App/noAccess';
import { selectedPharmacyInfo } from './Components/Authorization/AuthContext';

const App = () => {
  const [userAuthenticated, setUserAuthenticated] = useState<boolean | null>(null);
  const [showNavbar, setShowNavbar] = useState(false);
  let item = sessionStorage.getItem('siteInfo')?JSON.parse(sessionStorage.getItem('siteInfo')!):{siteNum:0,siteName:''};
  const [siteInfo, setSiteInfo] = useState<selectedPharmacyInfo>(item);
  const [isPharmacyChosen, setIsPharmacyChosen] = useState(false);
  const [user, setUser] = useState<Oidc.User>();
  const [activePath, setActivePath] = useState(window.location.pathname);


if (process.env["NODE_ENV"] !== "development") {
    console.log = () => { };
    console.debug = () => { };
    console.info = () => { };
    console.warn = () => { };
};

  useEffect(() => {
    if (!Number.isNaN(Number(siteInfo.siteNumber))) setIsPharmacyChosen(true);
  }, [siteInfo]);

  useEffect(() => {
    getUser().then(user => {
      if ((user && user.expired) || (!user)) {
        setUserAuthenticated(false);
      }
      else {
        setUserAuthenticated(true);
        setUser(user)
      }
    });
  }, [])

  return (
    <div className="App container-fluid">
      {showNavbar ? <Layout children={siteInfo.siteNumber} userAuthenticated={userAuthenticated} user={user} siteInfo={siteInfo} activePath={activePath} setActivePath={setActivePath} /> : <NoAccessLayout children={siteInfo.siteName} userAuthenticated={userAuthenticated} user={user} siteInfo={siteInfo} setActivePath={setActivePath} />}
      {userAuthenticated !== null && <RoutesPage
        userAuthenticated={userAuthenticated}
        setUserAuthenticated={setUserAuthenticated}
        setUser={setUser}
        siteInfo={siteInfo}
        setSiteInfo={setSiteInfo}
        isPharmacyChosen={isPharmacyChosen}
        setShowNavbar={setShowNavbar}
        user={user}
        setActivePath={setActivePath}
      />}
    </div>
  )
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
        <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
