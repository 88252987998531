import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
export const LoadingSpinner=(props:{ height:string, width:string }) => {
  return (
    <div style={{display:"block",textAlign:"center",justifyContent:"center",backgroundColor:"fff"}}>
      <div style={{ opacity: '1', backgroundColor: 'fff'}}>
      <Backdrop
        sx={{ color: '#fff', 
        position: 'relative',
        minHeight:props.height, 
        width: props.width,
        margin: "auto",
        backgroundColor: 'inherit'
      }}
        open={true}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      </div>
    </div>
  );
}