// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#monograph-result-header {
    font-size: 18px;
    color: black;
    font-weight: 700;
}

#monograph-result-top {
    margin-top: -10px;
}
#monograph-result1 {
    margin-bottom: 20px;
}

.labelClass {
    font-size: 16px !important;
    font-weight: bold !important;
    color: black;
}

.monograph-result-content {
    font-size: 18px;
    padding-bottom: 3px;
    color: black;
}

.result-container {
    padding-bottom: 15px;
    max-height: 500px;
    overflow-y: auto;
}

@media print {
    @page {
        size: auto;
        margin: 30px;
        margin-top: 0px;
    }

    .info-container {
        width: 100vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/DrugMonograph/DrugMonograph.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,UAAU;QACV,YAAY;QACZ,eAAe;IACnB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":["#monograph-result-header {\n    font-size: 18px;\n    color: black;\n    font-weight: 700;\n}\n\n#monograph-result-top {\n    margin-top: -10px;\n}\n#monograph-result1 {\n    margin-bottom: 20px;\n}\n\n.labelClass {\n    font-size: 16px !important;\n    font-weight: bold !important;\n    color: black;\n}\n\n.monograph-result-content {\n    font-size: 18px;\n    padding-bottom: 3px;\n    color: black;\n}\n\n.result-container {\n    padding-bottom: 15px;\n    max-height: 500px;\n    overflow-y: auto;\n}\n\n@media print {\n    @page {\n        size: auto;\n        margin: 30px;\n        margin-top: 0px;\n    }\n\n    .info-container {\n        width: 100vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
