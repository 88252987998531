'use client';

import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {GenoaTheme} from './theme';

/**
 * The cache provider for theme styles
 *
 * @remarks
 * The ThemeProvider requires the 'use client' directive
 *
 * @param children - The child node(s)
 * @returns The the provider component
 */
const ThemeCacheProvider = ({children}: {children: React.ReactNode}) => {
  return (
      <ThemeProvider theme={GenoaTheme}>
        {/* CssBaseline => global style normalizations from MUI */}
        <CssBaseline />
        {children}
      </ThemeProvider>
  );
};

export default ThemeCacheProvider;