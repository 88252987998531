import {ReactNode} from 'react';

import {useMobile, Breakpoint} from '../useMobile';

export interface ElementSwapOptions {
  reverseForBreakpoint?: boolean;
  callback?: () => void;
  breakpoint?: Breakpoint;
}

export const useElementSwap = (elements: ReactNode[], options: ElementSwapOptions = {}) => {
  const {reverseForBreakpoint = true, callback, breakpoint = 'sm'} = options;
  const isBreakpoint = useMobile(breakpoint);
  if (isBreakpoint && reverseForBreakpoint) {
    elements.reverse();
  }
  if (callback) {
    callback();
  }
  return elements;
};
