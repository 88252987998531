import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {ApproveInterface, DenyInterface, RemoveAccessInterface} from '../../Interfaces/Users';
import {PostApproveRegistration, PutRemoveAccess, PostDenyRegistration} from '../../actions/UsersAPI';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import '../../App/app.css';
import CheckboxTree from 'react-checkbox-tree';
import {AdminSiteFacsDTO, UserRequestDTO, UserSiteFacsDTO} from '../../Interfaces/AccessRequests';
import {buttonStyle, buttonStyleGray} from '../PatientProfile/PatientProfileStyle';
import {GetAccessesForUser, GetAdminSiteFac} from '../../actions/Admins';
import {LoadingSpinner} from '../../common/components/LoadingSpinner/LoadingSpinner';
import {ErrorMessage} from '../../common/components/AlertMessages/AlertMessages';

// Define the form state interface
interface FormState {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  requestedSiteFacs: number[];
  siteFacilityList: {siteNumber: number; facilityCodes: number[]}[];
  applicationAccess: {
    netRx: boolean;
    sampleManagement: boolean;
    medAnalyzer: boolean;
    monthlyEmailReport: boolean;
  };
  requestStatus: 'approved' | 'denied' | 'updated' | '';
  reason: string;
}

// Define the initial form state
const initialFormState: FormState = {
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  requestedSiteFacs: [],
  siteFacilityList: [],
  applicationAccess: {
    netRx: false,
    sampleManagement: false,
    medAnalyzer: false,
    monthlyEmailReport: false
  },
  requestStatus: '',
  reason: ''
};

// Define the request form props interface
interface RequestFormProp {
  request: UserRequestDTO;
  onClose: () => void;
}

// Define the UserRequestForm component
export const UserRequestForm: React.FC<RequestFormProp> = ({request, onClose}) => {
  //make API call here to get the admin's sites and facilities
  const role = 'siteManager';
  const [actualRequest, setActualRequest] = useState<AdminSiteFacsDTO[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [receivedUserandAdminAccessInfo, setReceivedUserandAdminAccessInfo] = useState(false);
  const [userAccesses, setUserAccesses] = useState<UserSiteFacsDTO>();
  const [newlyAddedAccesses, setNewlyAddedAccesses] = useState<string[]>([]);
  const [wholeSiteCheckedList, setWholeSiteCheckedList] = useState<string[]>([]);
  const [showSiteFacs, setShowSiteFacs] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);
  const RequestedSiteNotChosenError = "You must check at least one facility under the requested pharmacy before approving"
  const PendingRequest = 'Pending';

  // Fetch data when the component mounts
  useEffect(() => {
    setShowLoading(true);
    const fetchData = async () => {
      try {
        if (request.statusId === PendingRequest)
          setFormState({
            ...formState,
            requestStatus: ''
          });
        else {
          setShowSiteFacs(true);
          setFormState({
            ...formState,
            requestStatus: 'updated'
          });
        }
        const result: AdminSiteFacsDTO[] = await GetAdminSiteFac(role);
        const UserAccesses: UserSiteFacsDTO = await GetAccessesForUser(request.userId);
        setUserAccesses(UserAccesses);
        setShowLoading(false);
        setActualRequest(result);

        checkSites(UserAccesses);

        setReceivedUserandAdminAccessInfo(true);
      } catch {
        console.log('Unable to Fetch Admin Sites/Facs');
        setActualRequest([]);
        setShowLoading(false);
      }
    };
    fetchData();
  }, [request]);

  //set the checked sites that represent all its facs should be checked
  const checkSites = (UserAccesses: UserSiteFacsDTO) => {
    UserAccesses.siteFacPairs.forEach((siteFacPair) =>
      setChecked((checked) => [...checked, remove_prepending_zeros(siteFacPair)])
    );
    setChecked((checked) => [...checked, ...UserAccesses.siteIds.map((a) => Number(a).toString())]);
  }

  const checkAssignedFacs = async () => {
    checked.forEach((access) => {
      if (!access.includes('_')) {
        const result: AdminSiteFacsDTO[] = actualRequest;
        const res = result.find((object) => object.siteNum.toString() === access);
        const resFacs: string[] = res.facs.map((a) => Number(a.siteFinanceKey) + '_' + a.code);
        //then set the checked facs for the sites where all facs are checked for existing user access
        setChecked((checked) => [...checked, ...resFacs]);
      }
    });
  };

  // Check assigned facilities
  useEffect(() => {
    try {
      checkAssignedFacs();
    } catch {
      console.log('Error retrieving values from checked state and/or result state');
    }
  }, [receivedUserandAdminAccessInfo]);

  // Handle error message close
  const handleErrorMessageClose = () => {
    setErrorMessageVisibility(false);
    setErrorMessage('');
  };

  // Remove prepending zeros
  const remove_prepending_zeros = (siteFac: string) => {
    const siteFacArr = siteFac.split('_');
    return Number(siteFacArr[0]) + '_' + siteFacArr[1];
  };

  // Define the nodes for the checkbox tree
  const nodes = actualRequest.sort((a, b) => {
    if (a.siteName !== request.siteName && b.siteName === request.siteName) return 1;
    if (a.siteName === request.siteName && b.siteName !== request.siteName) return -1;
    return a.siteName.localeCompare(b.siteName)}).map((result) => ({
    disabled:
      request.statusId === PendingRequest &&
      userAccesses?.siteIds.map((a) => Number(a).toString()).includes(result.siteNum.toString()),
    value: result.siteNum.toString(),
    label:
      result.siteName === request.siteName && request.statusId === PendingRequest ? (
        <b style={{color: 'red'}}>{result.siteName} (REQUIRED TO APPROVE)</b>
      ) : (
        result.siteName
      ),
    children: result.facs
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((fac) => ({
        disabled:
          request.statusId === PendingRequest &&
          userAccesses?.siteFacPairs.includes(result.siteNum.toString().padStart(5, '0') + '_' + fac.code),
        value: result.siteNum + '_' + fac.code,
        label: fac.name
      }))
  }));

  // Define the expanded state for the checkbox tree
  const [expanded, setExpanded] = useState<string[]>([]);
  const [formState, setFormState] = useState<FormState>(initialFormState);

  // Handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, checked, type} = event.target;
    if (type === 'checkbox') {
      setFormState({
        ...formState,
        applicationAccess: {
          ...formState.applicationAccess,
          [name]: checked
        }
      });
    } else {
      setFormState({
        ...formState,
        [name]: value
      });
    }
  };

  // Handle radio change
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setFormState({
      ...formState,
      requestStatus: value as 'approved' | 'denied' | 'updated' | ''
    });
    if (value === 'approved' || value === 'updated') setShowSiteFacs(true);
  };

  const updateRequest = () => {
    checked.forEach((access) => {
      if (!access.includes('_')) {
        wholeSiteCheckedList.push(access)
      }
    });
    //get the old netrx_site and netrx_site_facility claims, and merge them into one list
    let oldAccesses = userAccesses?.siteIds.map((a) => Number(a).toString());
    if (oldAccesses === null || typeof(oldAccesses) === 'undefined')
      oldAccesses = userAccesses?.siteFacPairs.map((a) => remove_prepending_zeros(a));
    else userAccesses?.siteFacPairs.forEach((sf) => oldAccesses?.push(remove_prepending_zeros(sf)));
    let newAccesses = checked.filter((x) => !oldAccesses?.includes(x));
    //for all the newly checked values, format them and then add to formState for site/fac approval
    newAccesses.forEach((a) => {
      if (wholeSiteCheckedList.includes(a)) {
        formState.siteFacilityList = [...formState.siteFacilityList, {siteNumber: Number(a), facilityCodes: []}];
      } else {
        let split = a.split('_');
        if (!wholeSiteCheckedList.includes(split[0])) {
          formState.siteFacilityList = [
            ...formState.siteFacilityList,
            {siteNumber: parseInt(split[0], 10), facilityCodes: [parseInt(split[1], 10)]}
          ];
        }
      }
    });

    //remove where the old accesses contain values not in newly checked
    const newRemovals = oldAccesses?.filter((x) => !checked?.includes(x));
    //remove single facility claims if the whole site is checked (whole site checked would check all the facs)
    //don't want to deal with those
    const facRemovals = checked.filter(
      (x) =>
        x.includes('_') &&
        wholeSiteCheckedList.includes(x.split('_')[0]) &&
        oldAccesses?.includes(x)
    );
    const mergedRemovals = newRemovals?.concat(facRemovals);

    //use below var to check which approved sites to remove from database if no
    //facilities for the site are checked compared to before
    //as we are currently still storing who approved what sites in NetRx DB
    let removeSiteFromDBSet = new Set<number>();
    oldAccesses?.forEach((access) => {
      if (access.includes('_')) {
        const siteId = access.split('_')[0];
        if (!checked.find((x) => x.startsWith(siteId + '_'))) removeSiteFromDBSet.add(Number(siteId));
      } else if (!checked.find((x) => x.startsWith(access + '_'))) {
          removeSiteFromDBSet.add(Number(access));
      }
    });

    const approveInterface: ApproveInterface = {
      username: request.email,
      reason: formState.reason,
      site: -1,
      siteName: request.siteName,
      approvedFacs: [],
      siteFacPairs: formState.siteFacilityList
    };

    const removeInterface: RemoveAccessInterface = {
      userId: request.userId,
      fullSiteAccessIds:
        typeof mergedRemovals === 'undefined' || mergedRemovals === null
          ? []
          : mergedRemovals.filter((x) => !x.includes('_')).map((x) => x.padStart(5, '0')),
      siteFacPairs:
        typeof mergedRemovals === 'undefined' || mergedRemovals === null
          ? []
          : mergedRemovals
              .filter((x) => x.includes('_'))
              .map((x) => x.split('_')[0].padStart(5, '0') + '_' + x.split('_')[1]),
      removeSiteFromDBList: Array.from(removeSiteFromDBSet),
      reason: formState.reason
    };

    const updateUserAccess = async () => {
      if (removeInterface.fullSiteAccessIds.length > 0 || removeInterface.siteFacPairs.length > 0) {
        setShowLoading(true);
        await PutRemoveAccess(removeInterface);
        setShowLoading(false);
      }
      if (approveInterface.siteFacPairs.length > 0) {
        setShowLoading(true);
        await PostApproveRegistration(approveInterface);
        setShowLoading(false);
      }
      onClose();
    };
    updateUserAccess();
  }

  // Handle form submit
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('Form submitted:', formState);
    if (formState.requestStatus === 'denied') {
      const denyInterface: DenyInterface = {
        username: request.email,
        site: request.siteId,
        siteName: request.siteName,
        reason: formState.reason
      };
      PostDenyRegistration(denyInterface);
      onClose();
    } else if (formState.requestStatus === 'approved') {
      let split: string[];
      //get the list of parent checked nodes
      newlyAddedAccesses.forEach((siteFacPair) => {
        if (!siteFacPair.includes('_')) {
          wholeSiteCheckedList.push(siteFacPair);
          if (Number(siteFacPair) !== request.siteId) {
            formState.siteFacilityList = [
              ...formState.siteFacilityList,
              {siteNumber: Number(siteFacPair), facilityCodes: []}
            ];
          }
        }
      });
      newlyAddedAccesses.forEach((siteFacPair) => {
        split = siteFacPair.split('_');
        if (parseInt(split[0], 10) === request.siteId) {
          //site Id matches the centerId requested by user
          if (!wholeSiteCheckedList.find((a) => a === split[0])) {
            //if the whole site's facs have not been checked then add in fac codes that were checked under it
            if (!formState.requestedSiteFacs.includes(parseInt(split[1], 10))) {
              formState.requestedSiteFacs.push(parseInt(split[1], 10));
            }
          } else {
            //everything for this site was checked, so do nothing
          }
        } else {
          //site Id is admin assigned
          if (wholeSiteCheckedList.find((a) => a === split[0])) {
            //siteId appears to have all facilities checked, do not add facility code
            //do nothing as site was already stored
            
          } else {
            //siteId does not have all facilities checked, assign fac code
            formState.siteFacilityList = [
              ...formState.siteFacilityList,
              {siteNumber: parseInt(split[0], 10), facilityCodes: [parseInt(split[1], 10)]}
            ];
          }
        }
      });

      //check if there are any sites that are mandatory for approval
      if (formState.requestedSiteFacs.length > 0 || wholeSiteCheckedList.includes(request.siteId.toString())) {
        setErrorMessageVisibility(false)
        const approveInterface: ApproveInterface = {
          username: request.email,
          reason: formState.reason,
          site: request.siteId,
          siteName: request.siteName,
          approvedFacs: formState.requestedSiteFacs,
          siteFacPairs: formState.siteFacilityList
        };
        const awaitApproval = async () => {
          setShowLoading(true);
          await PostApproveRegistration(approveInterface);
          setShowLoading(false);
          onClose();

        };
        awaitApproval();
      } else {
        setErrorMessage(RequestedSiteNotChosenError)
        setErrorMessageVisibility(true)
      }
    } else if (formState.requestStatus === 'updated') {
      updateRequest();
    }
    formState.siteFacilityList = [];
    formState.requestedSiteFacs = [];
    setWholeSiteCheckedList([]);
    formState.reason = '';
  };

  return (
    <div className="overlay-patient">
    {errorMessageVisibility &&
        <ErrorMessage message={errorMessage} onClose={handleErrorMessageClose}/>
        }
      <div className="overlay-content-rx">
        <div className="overlay-top">
          <div className="title-patient">User Request Form</div>
        </div>
        <form onSubmit={handleSubmit}>
          <Paper elevation={3} sx={{p: 4, mb: 4}} style={{marginBottom: '0px'}}>
            {/* Account Information Section */}
            <Box mb={2} component={Paper} elevation={2} sx={{p: 2}}>
              <FormLabel component="legend">Account Information</FormLabel>
              <Grid container spacing={2} rowSpacing={0.5}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    margin="normal"
                    name="firstName"
                    value={request.firstName}
                    //onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    margin="normal"
                    name="lastName"
                    value={request.lastName}
                    //onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Title"
                    variant="outlined"
                    margin="normal"
                    name="title"
                    value={formState.title}
                    //onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    name="email"
                    value={request.email}
                    //onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Center"
                    variant="outlined"
                    margin="normal"
                    name="center"
                    value={request.siteName}
                    //onChange={handleInputChange}
                  />
                </Grid>
                {request.statusId===PendingRequest &&
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="additionalDetails"
                    name="additionalDetails"
                    label="Additional Details"
                    multiline
                    value={request.additionalNotes}
                  />
                </Grid>
                }
              </Grid>
            </Box>

            {/*Site & Facility Access Section */}
            {/*RT*/}
            {showSiteFacs && (
              <Box mb={2} component={Paper} elevation={2} sx={{p: 2}}>
                <FormLabel component="legend">Grant Pharmacy and Facility Access</FormLabel>
                {showLoading ? (
                  <LoadingSpinner {...{height: '200px', width: '50%'}} />
                ) : (
                  <FormGroup row>
                    <CheckboxTree
                      icons={{
                        expandClose: <ArrowRightOutlinedIcon />,
                        expandOpen: <ArrowRightOutlinedIcon />
                      }}
                      nodes={nodes}
                      checked={checked}
                      expanded={expanded}
                      checkModel="all"
                      onCheck={(checked, node) => {
                        if (node.checked === true) {
                          setNewlyAddedAccesses([...newlyAddedAccesses, node.value]);
                        } else {
                          setNewlyAddedAccesses(newlyAddedAccesses.filter((element) => element !== node.value));
                        }
                        setChecked(checked);
                      }}
                      onExpand={(expanded) => {
                        setExpanded(expanded);
                      }}
                    />
                  </FormGroup>
                )}
              </Box>
            )}

            {/* Request Status Section */}
            <Box mb={2} component={Paper} elevation={2} sx={{p: 2}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Request Status</FormLabel>
                {request.statusId === PendingRequest && (
                  <RadioGroup row name="requestStatus" value={formState.requestStatus} onChange={handleRadioChange}>
                    <FormControlLabel value="approved" control={<Radio />} label="Approved" />
                    <FormControlLabel value="denied" control={<Radio />} label="Denied" />
                  </RadioGroup>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Reason"
                      variant="outlined"
                      margin="normal"
                      name="reason"
                      value={formState.reason}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Box>

            {/* Action Buttons */}
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant="contained" sx={buttonStyleGray} onClick={onClose}>
                  Cancel
                </Button>
                {request.statusId === PendingRequest ? (
                  <Button variant="contained" sx={buttonStyle} type="submit">
                    Submit
                  </Button>
                ) : (
                  <Button variant="contained" sx={buttonStyle} type="submit">
                    Update
                  </Button>
                )}
              </div>
          </Paper>
        </form>
      </div>
    </div>
  );
};

export default UserRequestForm;
