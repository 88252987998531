import { OrderRefillsPageDTO } from "../../Interfaces/OrderRefills"
import React, { useState } from 'react';
import { formatDate } from "../PatientInformation/Helpers";
import DeleteIcon from '@mui/icons-material/Delete';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton, TablePagination } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface RxListOverlayProps {
    rxList: OrderRefillsPageDTO[];
    onClose: () => void;
    handleRemove: (rxNumber: string, event: React.MouseEvent) => void;
    handleProcessPrescriptions: (event: React.MouseEvent) => void;
};

export const RxListOverlay: React.FC<RxListOverlayProps> = ({ rxList, onClose, handleRemove, handleProcessPrescriptions }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // If user clicks outside of the overlay, overlay closes
    const handleOverlaysClick = (e: React.MouseEvent) => {
        if ((e.target as HTMLElement).classList.contains('overlay-patient')) { onClose(); }
    };

    const getRowColor = (index: number) =>  {
        return index % 2 === 0 ? 'white' : '#FAFAFA';
    }

    if (rxList.length === 0) {
        return(
            <div className='overlay-patient' onClick={handleOverlaysClick}>
                <div className='overlay-content-rxlist'>
                    <div style={{backgroundColor: '#004C97', borderRadius: '8px' }}>
                        <div className='title-patient'>There Are No Selected Order Refills</div>
                        <IconButton className='close-button' onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>
            </div>   
        )
    }

    return (
        <div className='overlay-patient' onClick={handleOverlaysClick} data-testid='overlay-rxlist'>
            <div className='overlay-content-rx'>
                <div className='overlay-top'>
                    <div className='title-patient'>Pending Order Refills</div>
                    <IconButton className='close-button' onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div style={{ padding: '20px', paddingBottom: '0px'}}>
                <Table style={{ width: '100%', overflowY: 'auto'}}>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#F3F3F3', borderTop: 'none', borderBottom: 'none'}}>
                            <TableCell className="table-header-title"sx={{ minWidth: '120px'}}>Rx Number</TableCell>
                            <TableCell className="table-header-title" sx={{ minWidth: '200px'}}>Patient Name</TableCell>
                            <TableCell className="table-header-title" sx={{ minWidth: '120px'}}>Date of Birth</TableCell>
                            <TableCell align="center" className="table-header-title" sx={{ minWidth: '40px'}}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {(rowsPerPage > 0 ? rxList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rxList).map((item, index) => (
                        <TableRow key={item.rxNum} sx={{ backgroundColor: getRowColor(index)}}> 
                            <TableCell>{item.rxNum} {item.isStat ? 'STAT' : ''}</TableCell>
                            <TableCell>{item.patName}</TableCell>
                            <TableCell>{formatDate(item.patBdate.toString())}</TableCell>
                            <TableCell align="center">
                                <IconButton style={{ padding: '0px'}} onClick={(event) => handleRemove(item.rxNum, event)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={rxList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </div>
                <div className='processButton-overlay' onClick={handleProcessPrescriptions}>Process Refills</div>
            </div>
        </div>
    );
};
