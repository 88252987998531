import React, { useEffect, useLayoutEffect, useState } from "react";
import { AuthUser } from "../../Interfaces/Authentication";
import { UserAccessDTO } from "../../Interfaces/Users";
import './PendingRegistration.css';
import { GetAllUserAccessByUserId, GetAuthUser } from "../../actions/UsersAPI";
import { formattedDateTime } from "../PatientInformation/Helpers";
import { LoadingSpinner } from "../../common/components/LoadingSpinner/LoadingSpinner";
import { NotFoundPage } from "../NotFoundPage";

interface Props {
  setShowNavbar: (value: boolean) => void;
}

export const PendingRegistration: React.FC<Props> = ({ setShowNavbar }) => {
  const [user, setUser] = useState<AuthUser | null>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await GetAuthUser();
        const authUser: AuthUser = {
          firstName: result.firstName,
          lastName: result.lastName,
          username: result.username,
          userId: result.userId,
          isInternal: result.isInternal,
          pharmacies: []
        };
        setUser(authUser);
      }
      catch (error) {
        setUser(null);
        console.error('Error fetching User information', error);
    }
    };
    fetchData();
  }, []);
  useLayoutEffect(() => {
    setShowNavbar(false);
  }, []);

  const [userAccess, setUserAccess] = useState<UserAccessDTO[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result : UserAccessDTO[] = await GetAllUserAccessByUserId();
        setUserAccess(result);
      }
      catch (error) {
        setUserAccess(null);
        console.error('Error fetching User Access information', error);
    }
    };
    fetchData();
  }, []);

  function showPendingRegistrationForm(userAccess: UserAccessDTO[]) {
    // check if the user access has at least one pending status
    // only show pending registration page if there is at least one pending status
    for (const access of userAccess) {
      if (access?.status === "Pending") {
        return true;
      }
    }
    return false;
  }

  if (!userAccess || !user) {
    return (
      <LoadingSpinner {...{ height:"200px", width:"50%" }}/>
    );
  } else if (showPendingRegistrationForm(userAccess)) {
    return (
      <div className='pageContainer'>
        <div className='registrationPage'>
        <h1 className='pageTitle-registration'>Access Denied: Pending Registration</h1>
        <div className="registrationInfoContainer">
          {/* Name Box */}
          <div className="nameBox-pending">{user?.lastName + ", " + user?.firstName}</div>

          {/* User Information Box*/}
          <table className="infoTable-pending">
            <tbody>
            {/* First Row */}
            <tr className="infoRow">
              <td className="infoBox-pending">
                <div>
                  <div className="infoTitle">Username</div>
                  <div className="infoValue">
                    {user?.username}
                  </div>
                </div>
              </td>
              <td className="infoBox">
                <div>
                  <div className="infoTitle">Email</div>
                  <div className="infoValue">{user?.username}</div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
        <h1 className="headerTitle">User Access Requests Overview</h1>
          {/* User Access Information Box*/}
          <table className="userAccessTable">
            <tbody>
            {/* Header Row */}
            <tr className='headerRow'>
              <td className='headerCol'>
                <div>Site Name</div>
              </td>
              <td className='headerCol'>
                <div>Status</div>
              </td>
              <td className='headerCol'>
                <div>Reason</div>
              </td>
              <td className='headerCol'>
                <div>Request Date</div>
              </td>
              <td className='headerCol'>
                <div>Created By</div>
              </td>
              <td className='headerCol'>
                <div>Created Date</div>
              </td>
              <td className='headerCol'>
                <div>Updated By</div>
              </td>
              <td className='headerCol'>
                <div>Updated Date</div>
              </td>
              </tr>
              {userAccess.map((row, rowIndex) => (
                <React.Fragment key={row.userAccessId}>
                  <tr className={`rxRow-${rowIndex % 2 === 0 ? 'even' : 'odd'}`}>
                    <td className='columns'>{row.siteName}</td>
                    <td className='columns'>{row.status}</td>
                    <td className='columns'>{row.reason}</td>
                    <td className='columns'>{formattedDateTime(row.requestDate)}</td>
                    <td className='columns'>{row.createdBy}</td>
                    <td className='columns'>{formattedDateTime(row.createdDate)}</td>
                    <td className='columns'>{row.updatedBy}</td>
                    <td className='columns'>{formattedDateTime(row.updatedTime)}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    );
  }
  return (
    <NotFoundPage />
  );
}