import {useState, useLayoutEffect} from 'react';
import {isClient} from '@skyline/common';

export interface ScrollPosition {
  x: number;
  y: number;
}

function getScrollPosition(): ScrollPosition {
  if (isClient()) {
    return {x: window.pageXOffset, y: window.pageYOffset};
  } else {
    return {x: 0, y: 0};
  }
}

export function useScrollPosition(): ScrollPosition {
  const [position, setScrollPosition] = useState<ScrollPosition>(getScrollPosition());

  useLayoutEffect(() => {
    let requestRunning: number | null = null;

    function handleScroll() {
      if (requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return position;
}

export function useScrollXPosition(): number {
  const {x} = useScrollPosition();
  return x;
}

export function useScrollYPosition(): number {
  const {y} = useScrollPosition();
  return y;
}
