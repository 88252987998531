import React, { ErrorInfo } from 'react';
import { ErrorMessages } from "../Interfaces/ErrorMessages";
import { Button } from '@mui/material';
import { buttonStyle } from '../Components/PatientProfile/PatientProfileStyle';
interface State {
    error: any; // Could be an exception thrown in synchronous code or could be a rejection from promise
};
export class ErrorBoundary extends React.Component<any, State>{
   constructor(props:any) {
       super(props);
        this.state = { error: null };
    };

    private handleRefresh = () => {
        window.location.reload();
    };

    private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
        this.setState({
            error: event.reason
        });
    };
    
    public static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    };

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    };

    componentDidMount() {
        // Add an event listener to the window to catch unhandled promise rejections set error in state
        window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
    };

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
    };

    public render() {
        if (this.state.error) {
            const env = process.env["REACT_APP_ENV"];
            let errorMessage = "";
            //show stack trace in all envs except prod and uat for business users
            if (env === 'development' || env === 'local' || env === 'qa') {
                errorMessage += ErrorMessages.UncaughtRuntimeErrorDevelopment + ":\n\n" + this.state.error.stack + "\n";
            }
            else {
                errorMessage += ErrorMessages.UncaughtRuntimeErrorProduction;
            }
            return (
                <div id="error-boundary-msg">
                    <div id="error-msg">
                        <b>{errorMessage}</b>
                    </div>
                    <div className="horizontal-center">
                        <br></br>
                        <Button
                            variant="contained"
                            sx={ buttonStyle }
                            onClick={this.handleRefresh}>
                            Refresh
                        </Button>
                    </div>
                </div>
            );
        }
            return this.props["children"];
    };
}