import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PatientDetails } from './PatientDetails';
import { PatientRx } from './PatientRx';
import './PatientInformation.css';
import { PatientDetailDTO, PatientRxDTO } from '../../Interfaces/PatientDTOModel';
import { fetchPatientDetails, fetchPatientRx } from '../../actions/ProScriptAPI';
import { addOrderRefill, deleteOrderRefill, processOrderRefill } from '../../actions/OrderRefill';
import { OrderRefillsDTO, OrderRefillsPageDTO, OrderRefillProcessResponse, ConfirmationPageRxDTO } from '../../Interfaces/OrderRefills';
import pharmacyNumContext from '../Authorization/AuthContext';
import { ErrorMessage } from '../../common/components/AlertMessages/AlertMessages';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';

// PatientInformationPage component
export const PatientInformationPage = () => {
    const context = useContext(pharmacyNumContext);
    const siteNum = context.siteInfo.siteNumber;
    const userName = context.userName;

    // Get account from location state
    const location = useLocation();
    const account = location.state?.account;

    // Handling ProcessSelectedRefill
    const [requestedRefills, setRequestedRefills] = useState<OrderRefillsDTO[]>([]);
    const navigate = useNavigate();
    const [erroMessage, setErrorMessage] = useState('');
    const [errorMessageVisbility, setErrorMessageVisibility] = useState(false);
    // Patient information state
    const [patientInfo, setPatientInfo] = useState<PatientDetailDTO["patient"]>({account: -1, fac: -1, name: "", sex: "", bDate: 0, priDoct: -1, patAdd1: "", patAdd2: "", patCity: "", patZip: "", patState: "", priDocName: "", facName: "", diagnoses: [], allergies: []});
    // Patient Rx information state
    const [patientRx, setPatientRx] = useState<PatientRxDTO["data"]>([{acct: -1, rxNum: -1, patName: "", ndc: "", code: "", rxDate: 0, doct: 0, quanDisp: 0, maxFills: 0, lstRef: 0, dir: "", actualFills: 0, prAuthQuanAccum: 0, docName: "", drugDesc: "", directions: "", drugImage: "", fills: 0, refills: []}]);

    // Fetch patient details and Rx information on mount
    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const result : PatientDetailDTO["patient"] = await fetchPatientDetails({siteNum, account});
                setPatientInfo(result);
            }
            catch (error) {
                setPatientInfo(null);
                setErrorMessage("Error Fetching Patient Details. Please Try Again.");
                setErrorMessageVisibility(true);
                console.error('Error fetching patient details', error);
            }
        };

        const fetchRx = async () => {
            try {
                const result2 : PatientRxDTO["data"] = await fetchPatientRx(siteNum.toString(), account, true, true);
                setPatientRx(result2);
            }
            catch (error) {
                setErrorMessage("Error Fetching Patient Rx Information. Please Try Again.");
                setErrorMessageVisibility(true);
                setPatientRx(null);
                console.error('Error fetching patient rx information', error);
            }
        };

        fetchDetails();
        fetchRx();
    }, []);

    // Update requested refills
    const updateRequestedRefills = async ( rx : OrderRefillsDTO) => {
        if (patientInfo) {
            // Check if the value is already in the list
            const isInList = requestedRefills.some((requestedRefill) => requestedRefill?.Rx === rx.Rx);
    
            if (isInList) {
                // Remove if already in list
                const updatedList = requestedRefills.filter((item) => item.Rx !== rx.Rx);
                
                try {
                    const result : string = await deleteOrderRefill(rx)

                    console.log(result);
                }
                catch (error) {
                    console.error('Error deleting order refill', error);
                }
                
                setRequestedRefills(updatedList);
            }
            else {
                // If item is not in the list, add it
                setRequestedRefills([...requestedRefills, rx]);

                try {
                    const result : OrderRefillsPageDTO = await addOrderRefill(rx)

                    console.log(result);
                }
                catch (error) {
                    console.error('Error adding order refill', error);
                }
            }
        }
    };

    // // Handle Process Refill Orders
    const handleRefillOrder = async () => {
        console.log('Process Refill Order');

        if (requestedRefills.length === 0) {
            setErrorMessage('There are no refills selected to process. Please try again.');
            setErrorMessageVisibility(true);
            return;
        };

        let data : ConfirmationPageRxDTO[] = [];

        try {
            if (patientInfo !== null) {
                const responses : OrderRefillProcessResponse[] = await processOrderRefill(requestedRefills);
                data = requestedRefills.map(refill => {
                    const response = responses.find(rx => rx.rxNum === refill.Rx);
                    const patientRxInfo = patientRx?.find(rx => rx.rxNum.toString() === refill.Rx);
                    let event = 'Refill Request'
                    if (refill.IsStat) event = 'Stat Refill Request';
          
                    let status = 'Unable to Process Request';
                    if (response?.status) status = response.status;
                    
                    let drugDesc = "";
                    if (patientRxInfo?.drugDesc) drugDesc = patientRxInfo.drugDesc;
          
                    return {RxNumber: refill.Rx, Event: event, PatientName: patientInfo?.name, BDate: patientInfo?.bDate, Description: drugDesc, Status: status}
                  });
                navigate('order-refill-confirmation', {state: data});
            }
        }
        catch (error) {
            console.error('Error processing order refill', error);
            setErrorMessage('There was an error processing your refills. Please try again.');
            setErrorMessageVisibility(true);
        }
    };

    // Handle error message close
    const handleErrorMessageClose = () => {
        setErrorMessageVisibility(false);
        setErrorMessage('');
    }

    // Render component
    if (patientInfo?.account == -1 && (patientRx?.length === 1 && patientRx[0]?.acct === -1)) {
        return (
            <LoadingSpinner {...{ height:"200px", width:"50%"}}/>            
        )
    };

    if (patientInfo?.account != -1 && (patientRx?.length === 1 && patientRx[0]?.acct === -1)) {
        return (
            <div className='patientInformation'>
                <PatientDetails patient={patientInfo}></PatientDetails>
                <LoadingSpinner {...{ height:"200px", width:"50%"}}/>            
        </div>
        )
    };

    if (patientInfo?.account == -1 && !(patientRx?.length === 1 && patientRx[0]?.acct === -1)) {
        return (
            <div className='patientInformation'>
                <LoadingSpinner {...{ height:"200px", width:"50%"}}/>            
                <PatientRx data = {patientRx} updateFunction={updateRequestedRefills} requestedRefills={requestedRefills} siteNum={siteNum} user={userName}></PatientRx>
            </div>
        )
    }

    return(
        <div className='patientInformation'>
            <PatientDetails patient={patientInfo}></PatientDetails>
            <PatientRx data = {patientRx} updateFunction={updateRequestedRefills} requestedRefills={requestedRefills} siteNum={siteNum} user={userName}></PatientRx>
            <div className='processButton' onClick={handleRefillOrder}>Process Refills</div>
            {errorMessageVisbility && 
                <ErrorMessage message={erroMessage} onClose={handleErrorMessageClose}></ErrorMessage>}
        </div>
    );
};

export default PatientInformationPage;
