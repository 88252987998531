// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#image-result-title {
    font-size: 16px;
    color: black;
    font-weight: 700;
    margin-bottom: 10px;
}

#image-result-body {
    margin-left: 20px;
}

#info-result{
    margin-top: 10px;
}

#image-result {
    line-height: 24px;
}

@media print {
    @page {
        size: auto;
        margin: 30px;
        margin-top: 0px;
    }

    .info-container {
        width: 100vw;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/DrugImage/DrugImage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,UAAU;QACV,YAAY;QACZ,eAAe;IACnB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":["#image-result-title {\n    font-size: 16px;\n    color: black;\n    font-weight: 700;\n    margin-bottom: 10px;\n}\n\n#image-result-body {\n    margin-left: 20px;\n}\n\n#info-result{\n    margin-top: 10px;\n}\n\n#image-result {\n    line-height: 24px;\n}\n\n@media print {\n    @page {\n        size: auto;\n        margin: 30px;\n        margin-top: 0px;\n    }\n\n    .info-container {\n        width: 100vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
