import React, { useContext, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import './DrugImage.css';
import '../../App/app.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { buttonStyle, buttonStyleGray, CustomToggleButton } from '../PatientProfile/PatientProfileStyle';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../../common/components/AutoSuggestion/SearchBox.css';
import '../DrugInformationPage/DrugInformationOverylays.css';
import { fetchDrugImageInfo, fetchDrugLookup, fetchDrugSearch} from '../../actions/ProScriptAPI';
import { DrugImageInfoDTO } from '../../Interfaces/DrugImageDTOModels';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import AutoSuggestionDrug from '../../common/components/AutoSuggestion/autoSuggestionDrug';
import { ErrorMessage } from '../../common/components/AlertMessages/AlertMessages';
import { DrugSearch } from '../../Interfaces/DrugInteractionsDTOModels';
import pharmacyNumContext from '../Authorization/AuthContext';
import { formatNDC } from '../PatientInformation/Helpers';
import { PrintButton } from '../../common/components/PrintButton';
import { IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Define the DrugImagePage component
export function DrugImagePage() {
    const context = useContext(pharmacyNumContext);
    const siteNum = context.siteInfo.siteNumber;
    // Initialize state variables
    const [inputValue, setInputValue] = useState("");
    const [drugImageInfo, setDrugImageInfo] = useState<DrugImageInfoDTO>({ drugName: '', drugImage: null, manufacturer: '', imprint1: '', imprint2: '', desc: '', addInfo: '', dosageForm: ''});
    const [showDrugImageInfo, setShowDrugImageInfo] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);
    const printRef = useRef<HTMLDivElement>(null);
    const [drugName, setDrugName] = useState('');

    //consts for handling toggling search option
    const [searchBy, setSearchBy] = React.useState<string>('DrugName');
    const handleSearchBy = (
        event: React.MouseEvent<HTMLElement>,
        newSearchBy: string,
    ) => {
        if(newSearchBy!=null){
            setInputValue('')
            setSearchBy(newSearchBy);
        }
    };

    // Handle clear button click
    const handleClear = () => {
        setShowLoading(false);
        setInputValue('');
        setShowDrugImageInfo(false);
    };

    // Handle search box change
    const handleChangeSearchBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Updates text box value
        const input = event.target.value;
        setInputValue(input)
    };

    // Handle error message close
    const handleErrorMessageClose = () => {
        setErrorMessageVisibility(false);
        setErrorMessage('');
    }

    function handleDrugName(name: string, ndc: string) {
        setDrugName(name + " (" + formatNDC(ndc) + ")");
    }

    // Fetch drug image data
    async function fetchImageData (ndc: string) {
        setShowDrugImageInfo(false);
        setShowLoading(true);

        try {
            // get image info 
            const result : DrugImageInfoDTO = await fetchDrugImageInfo(ndc);
            setDrugImageInfo(result);
            setShowLoading(false);
            setShowDrugImageInfo(true);
            console.log("image info: ", result);

            if (result.drugImage === null || result.drugImage === "data:image/jpeg;base64,") {
                result.drugImage = null;
                setShowLoading(false);
                setErrorMessage("No Drug Image Available");
                setErrorMessageVisibility(true);
            }
            setInputValue('');
        }
        catch (error) {
            console.error('Error fetching drug information from ndc', error);
            setShowLoading(false);
            setErrorMessage(`Unable to Fetch Drug Information for ${formatNDC(ndc)}. Please Try Again.`)
            setErrorMessageVisibility(true);
        }

        setInputValue('');
    }

    // Fetch drug data from NDC
    async function fetchDrugDataFromNDC (ndc: string) {
        setShowDrugImageInfo(false);
        setShowLoading(true);

        let strippedNDC = ndc.replace(/-/g, '').replace(' ', '');

        if (strippedNDC.length !== 11) {
            setErrorMessage('NDC Number Needs to be 11 Digits. Please Try Again.');
            setErrorMessageVisibility(true);
            setShowLoading(false);
            return;
        }

        try { 
            const drugInfo : DrugSearch = await fetchDrugLookup(strippedNDC, siteNum);
            if (drugInfo === null ) {
                setErrorMessage("Unable to Locate Drug Based on Given NDC. \nInvalid NDC Number or Drug Might be Inactive.")
                setErrorMessageVisibility(true);
                setShowLoading(false);
                return;
            }
            handleDrugName(drugInfo.drugName, drugInfo.ndc);

        }
        catch (error) {
            setErrorMessage("An Error Occurred. Please Try Again.")
            setErrorMessageVisibility(true);
            setShowLoading(false);
            return;
        }

        fetchImageData(strippedNDC);
    }

    // Fetch auto suggestion data
    async function fetchAutoSuggestionData(drugName: string) : Promise<[string, string][]> {
        try {
            const result: DrugSearch[] = await fetchDrugSearch(drugName, siteNum, true);
            const results: [string, string][] = result.map((item) => [item.drugName, item.ndc]);
            return results;
        }
        catch (error) {
            setErrorMessage("Error Fetching Drug Search. Please Try Again.");
            setErrorMessageVisibility(true);
            console.error('Error fetching drug serach', error);
        }
        return [["Try again", ""]];
    };

    async function handleSuggestionClick (suggestion: [string, string]) {
        setInputValue('');
        fetchImageData(suggestion[1]);
        handleDrugName(suggestion[0], suggestion[1]);
    };

    return(
        <div id='drug-layout'>
            {errorMessageVisibility && 
                <ErrorMessage message={errorMessage} onClose={handleErrorMessageClose}/>
            }
            <Grid id='patientDisplay'>
                <Typography className='header'><b>Search Drug</b></Typography>
                    <Grid container spacing={0} alignItems="end">
                        <Grid id="toggleSpacing">
                        <Typography className='searchWithHeader'><b>Search With</b></Typography>
                            <ToggleButtonGroup
                                value={searchBy}
                                exclusive
                                onChange={handleSearchBy}
                                >
                                <ToggleButton sx={CustomToggleButton} value="DrugName">
                                    Drug Name
                                </ToggleButton>
                                <ToggleButton data-testid="toggle-account-button" sx={CustomToggleButton} value="NDC">
                                    NDC Number
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid>
                            {searchBy=='DrugName'?<Typography className='subheader'><b>Drug Name</b></Typography>:
                                <Typography className='subheader'>
                                    <b>NDC Number</b>
                                    <Tooltip placement="right" title={<div id="helper-text">Please provide full NDC Number.</div>}>
                                        <IconButton  style={{padding: '1px', paddingLeft: '5px', marginBottom: '2px', color: '#004C97'}}>
                                            <InfoOutlinedIcon fontSize='small'/>
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            }
                            {searchBy=='DrugName'?<AutoSuggestionDrug
                                onSuggestionSelected={handleSuggestionClick}
                                searchType="drugSearch"
                                isRequired={true}
                                setSelectedValue={() => {}}
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                                fetchData={fetchAutoSuggestionData}
                                placeholder='Drug Name'
                                secondColNDC={true}
                            />:
                                <input placeholder='NDC Number' value={inputValue} onChange={handleChangeSearchBox} id='patientSearch'></input>
                            }
                        </Grid>
                        <Grid container item xs={1.5} id = "searchSpacing">
                            {searchBy=='DrugName'?
                                <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
                                    <RefreshIcon/>&nbsp;Reset
                                </Button>:
                                <div id='buttonsPosition'>
                                    <Button variant='contained'sx={buttonStyle} onClick={() => fetchDrugDataFromNDC(inputValue)}>Search</Button>
                                    <Button variant="contained" onClick={handleClear} sx={buttonStyleGray}>
                                        <RefreshIcon/>&nbsp;Reset
                                </Button>
                                </div>
                            }         
                        </Grid>
                    </Grid>

                {showLoading && <LoadingSpinner {...{ height:"200px", width:"50%"}}/>}

                {showDrugImageInfo &&
                <div className='info-container' ref={printRef}>
                    <div className='info-box'>
                        <PrintButton printRef={printRef} />
                        <div className='drug-title'>{drugName}</div>
                        <Grid container id='info-result' style={{ gap: "24px", paddingBottom: '20px' }}>
                            {drugImageInfo.drugImage && 
                                <Grid item >
                                    <img src={`${drugImageInfo.drugImage}`} alt="Drug Image"></img>
                                </Grid>
                            }
                            <Grid item xs="auto">
                                <div id='image-result-body'>
                                    <div id='image-result-title'>Imprint Information</div>
                                    <div id='image-result'>Manufacturer: {drugImageInfo.manufacturer}</div>
                                    <div id='image-result'>Imprint 1: {drugImageInfo.imprint1}</div>
                                    <div id='image-result'>Imprint 2: {drugImageInfo.imprint2}</div>
                                    <div id='image-result'>Description: {drugImageInfo.desc}</div>
                                    {drugImageInfo.addInfo && <div id='image-result'>Additional Information: {drugImageInfo.addInfo}</div>}
                                    <div id='image-result'>Dosage Form: {drugImageInfo.dosageForm}</div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                }
            </Grid>
        </div>
    );
}