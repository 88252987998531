import {Box, IconButton, Drawer, Tab} from '@mui/material';
import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {RoutingComponent} from '@skyline/types';
import {useState} from 'react';

import {NavLink} from '../NavLink';
import {Logout, LogoutProps} from '../Logout';
import {useBreakpointChangeEffect} from '../../../hooks';

import {NavbarContent} from './NavbarContent';
import { TabContext, TabList } from '@mui/lab';

export interface NavbarProps {
  memberNavigation: NavbarContent;
  logoutProps: Omit<LogoutProps, 'routingComponent' | 'onNavigate'>;
  activePath: string;
  routingComponent: RoutingComponent;
  setActivePath: (input: string) => void;
}

export const Navbar: React.FC<NavbarProps> = ({memberNavigation, logoutProps, activePath, routingComponent, setActivePath}) => {
  const navLinks = memberNavigation.navbar;
  const disabledTabValue = "disabled-tab"

  const [hideIndicator, setHideIndicator] = useState(false);

  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const closeHamburgerMenu = () => {
    setHamburgerMenuOpen(false);
  };

  useBreakpointChangeEffect(() => {
    setHamburgerMenuOpen(false);
  });

  const activeTabValue = (activePath: string) => {
    for (let i = 0; i < navLinks.length; i++) {
      if (activePath === navLinks[i]?.path) {
        return activePath;
      }
    }
    if (activePath === "/authenticate") {
      return "/";
    }
    // if the active path is not any of the tabs, set it to the default tab
    return disabledTabValue;
  };

  React.useEffect(() => {
    if (activeTabValue(activePath) === disabledTabValue) {
      setHideIndicator(true);
    } else {
      setHideIndicator(false);
    }
  }, [activePath]);

  return (
    <>
      <Box sx={{display: {xs: 'block', md: 'none' }}}>
        <IconButton
          disableRipple
          onClick={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}
          color="inherit"
          //sx={{mx: 1, height: 40, width: 40}}
        >
          {hamburgerMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Drawer
          open={hamburgerMenuOpen}
          onClose={closeHamburgerMenu}
          BackdropProps={{invisible: true}}
          sx={{
            '& .MuiDrawer-paper': {
              bgcolor: '#434448',
              minWidth: 375,
              height: 'auto',
              maxHeight: 'calc(100vh - 50px)',
              top: 50,
              overflowX: 'hidden',
              borderRadius: 0
            }
          }}
        >
          {navLinks.map((navItem) => (
            <NavLink
              routingComponent={routingComponent}
              key={navItem.title}
              active={
                navItem.path === activePath || navItem.items?.map((link) => link.path).includes(activePath) || false
              }
              onNavigate={closeHamburgerMenu}
              {...navItem}
            />
          ))}
          <Logout {...logoutProps} routingComponent={routingComponent} onNavigate={closeHamburgerMenu} />
        </Drawer>
      </Box>

      <Box sx={{width: '100%', bgcolor: '#004C97'}}>
        <TabContext value={activeTabValue(activePath)}>
          <Box
            sx={{
              display: {xs: 'none', md: 'block'},
              bgcolor: '#004C97', // genoa blue
              maxHeight: '44px',
              width: '1300px'
            }}
          >
            <TabList
              textColor="secondary"
              sx={{
                height: '0px'
              }}
              TabIndicatorProps={{ hidden: hideIndicator, style: {backgroundColor: '#D22630'} }}
            >
            {navLinks.map((navItem, i) => (
                <Tab key={i}
                  label=
                    {<div style={activePath === navItem.path ? {fontSize: '15px', color: 'white', fontWeight: 'bold'} : {fontSize: '14px', color: 'white', fontWeight: 'normal'}}>
                      {navItem.title}
                    </div>}
                  value={navItem.path}
                  onClick={() => {
                    location.href = navItem.path;
                  }}
                />
            ))}
              {/* other pages that don't use tabs */}
              <Tab sx={{maxWidth: "0px"}} disabled value={disabledTabValue}/>
            </TabList>
          </Box>
        </TabContext>
      </Box>
    </>
  );
};
