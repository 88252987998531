import React, {useEffect, useState} from 'react';
import './Overlays.css';
import {formatDate, formatTime} from './Helpers';
import {RxTrackingDTO} from '../../Interfaces/TrackingDTOModels';
import {fetchRxTracking} from '../../actions/ProScriptAPI';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomTooltip } from '../TrackBatch/CustomToolTip';
import { Box } from '@mui/system';

interface RxOverlayComponentProps {
    onClose: () => void;
    RxNum: number | null;
    RefillNum: number | null;
    siteNum: number | null;
}

export const RxOverlayComponent: React.FC<RxOverlayComponentProps> = ({onClose, RxNum, RefillNum, siteNum}) => {
  const [rxTracking, setRxTracking] = useState<RxTrackingDTO['trackingInfo']>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result: RxTrackingDTO['trackingInfo'] = await fetchRxTracking({siteNum, RxNum, RefillNum});
        setRxTracking(result);
      } catch (error) {
        console.error('Error fetching Rx Tracking info', error);
      }
    };
    fetchData();
  }, []);

  // If user clicks outside of the overlay, overlay closes
  const handleOverlaysClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).classList.contains('overlay-patient')) {
      onClose();
    }
  };

  if (rxTracking == null) {
    return (
      <div className='overlay-patient' onClick={handleOverlaysClick}>
      <div className='overlay-content-patient'>
          <div className='overlay-top-none'>
              <div className='title-patient'>Loading...</div>
              <IconButton className='close-button' onClick={onClose}>
                <CloseIcon/>
              </IconButton>
          </div>
      </div>
  </div>
    );
  }

  return (
    <div className='overlay-patient' onClick={handleOverlaysClick}>
      <div className='overlay-content-rx'>

        <div className='overlay-top'>
          <div className='title-patient'>Rx Number: {RxNum}/{RefillNum}</div>
          <IconButton className='close-button' onClick={onClose}>
            <CloseIcon/>
          </IconButton>
        </div>

        <div style={{ display: 'flex', padding: '20px', paddingBottom: '5px', marginLeft: '20px', marginRight: '20px'}}>
          <div style={{ flex: 1}}>
            <div className="rx-info">Batch Number</div>
            <div className="rx-tracking-value" style={{paddingBottom: '10px'}}>{rxTracking.batch || '-'}</div>
          </div>
          <div style={{ flex: 1}}>
            <div className="rx-info">Facility</div>
            <div className="rx-tracking-value" style={{paddingBottom: '10px'}}>{(rxTracking.fac + " - " + rxTracking.facName) || '-'}</div>
          </div>
          <div style={{ flex: 1}}>
            <div className="rx-info">Status</div>
            <div className="rx-tracking-value" style={{paddingBottom: '10px'}}>{rxTracking.status || '-'}</div>
          </div>
        </div>
        
        <Box className='rx-info-box' sx={{overflow: "auto"}}>
          <div className='patient-rx-tracking-title'>Tracking Information</div>
          <div className="result-container" style={{ fontSize: '18px'}}>
            <div className="result-row">
                <div className="result-title-row">Request for Rx/Refill
                    <CustomTooltip title="Requested date for Rx/Refill.">
                      <InfoOutlinedIcon/>
                    </CustomTooltip>
                </div>
                <div className="result-title-row">Prescription Filled
                    <CustomTooltip title="Date when prescription was filled.">
                      <InfoOutlinedIcon/>
                    </CustomTooltip>
                </div>
                <div className="result-title-row">Drug/NDC Verification
                    <CustomTooltip title="Drug still in pharmacy and packaging complete.">
                      <InfoOutlinedIcon/>
                    </CustomTooltip>
                </div>
            </div>
            <div className="content-row">
                <div className="result-content-row">{formatDate(rxTracking.rxStep1Date)} {formatTime(rxTracking.rxStep1Time)}</div>
                <div className="result-content-row">{formatDate(rxTracking.rxStep2Date)} {formatTime(rxTracking.rxStep2Time)}</div>
                <div className="result-content-row">{formatDate(rxTracking.rxStep3Date)} {formatTime(rxTracking.rxStep3Time)}</div>
            </div>
            <div className="result-row" style={{ marginTop: '10px'}}>
                <div className="result-title-row">Pharmacist Verification #1
                    <CustomTooltip title="Rx verified for correct information prior to packaging.">
                      <InfoOutlinedIcon/>
                    </CustomTooltip>
                </div>
                <div className="result-title-row">Pharmacist Verification #2
                    <CustomTooltip title="Rx complete and ready for shipping/delivery/pickup.">
                      <InfoOutlinedIcon/>
                    </CustomTooltip>
                </div>
                <div className="result-title-row">Placed in Tote
                    <CustomTooltip title="Shipping/Delivery option identified and assigned.">
                      <InfoOutlinedIcon/>
                    </CustomTooltip>
                </div>
            </div>
            <div className="content-row">
                <div className="result-content-row">{formatDate(rxTracking.rxStep4Date)} {formatTime(rxTracking.rxStep4Time)}</div>
                <div className="result-content-row">{formatDate(rxTracking.rxStep5Date)} {formatTime(rxTracking.rxStep5Time)}</div>
                <div className="result-content-row">{formatDate(rxTracking.rxStep6Date)} {formatTime(rxTracking.rxStep6Time)}</div>
            </div>
            <div className="result-row" style={{ marginTop: '10px'}}>
                <div className="result-title-row">Tote Closed/Awaiting Pick-Up
                    <CustomTooltip title="Still in pharmacy waiting for delivery option pickup.">
                      <InfoOutlinedIcon/>
                    </CustomTooltip>
                </div>
                <div className="result-title-row">In Delivery
                    <CustomTooltip title="In transit either shipping/driver/courier.">
                      <InfoOutlinedIcon/>
                    </CustomTooltip>
                </div>
                <div className="result-title-row">Delivered
                    <CustomTooltip title="Time and date of actual delivery at home.">
                      <InfoOutlinedIcon/>
                    </CustomTooltip>
                </div>
            </div>
            <div className="content-row" style={{ marginBottom: '10px'}}>
                <div className="result-content-row">{formatDate(rxTracking.batchStep2Date)} {formatTime(rxTracking.batchStep2Time)}</div>
                <div className="result-content-row">{formatDate(rxTracking.batchStep3Date)} {formatTime(rxTracking.batchStep3Time)}</div>
                <div className="result-content-row">{formatDate(rxTracking.batchStep4Date)} {formatTime(rxTracking.batchStep4Time)}</div>
            </div>
            {rxTracking.status === "Picked Up" && 
            <>
                <div className="result-row" style={{ marginTop: '10px'}}>
                    <div className="result-title-row">Picked Up
                      <CustomTooltip title="Date of pickup at pharmacy.">
                        <InfoOutlinedIcon/>
                      </CustomTooltip>
                    </div>
                </div>
                <div className="content-row" style={{ marginBottom: '10px' }}>
                    <div className="result-content-row">{formatDate(rxTracking.pickUpDate)} {formatTime(rxTracking.pickUpTime)}</div>
                </div>
            </>
            }
          </div>
        </Box>
      </div>
    </div>
  );
};

interface RowProps {
    rxNum: number;
    refillNum: number;
    siteNum: number;
}

// Displays overlay for refill selected
export const RefillRow: React.FC<RowProps> = ({rxNum, refillNum, siteNum}) => {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [SelectedRxNum, setSelectedRxNum] = useState<number | null>(null);
  const [SelectedRefill, setSelectedRefill] = useState<number | null>(null);

  const handleOverlaysClick = (rxNum: number, refillNum: number) => {
    setSelectedRxNum(rxNum);
    setSelectedRefill(refillNum);
    setOverlayVisible(!overlayVisible);
  };

  const handleCloseOverlay = () => {
    setSelectedRxNum(null);
    setSelectedRefill(null);
    setOverlayVisible(false);
  };

  return (
    <span className="overlay-link-rx" onClick={() => handleOverlaysClick(rxNum, refillNum)}>
      Refill #{refillNum}
      {overlayVisible && (
        <RxOverlayComponent
          onClose={handleCloseOverlay}
          RxNum={SelectedRxNum}
          RefillNum={SelectedRefill}
          siteNum={siteNum}
        />
      )}
    </span>
  );
};

// Displays overlay for rx selected
export const RxRow: React.FC<RowProps> = ({rxNum, refillNum, siteNum}) => {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [SelectedRxNum, setSelectedRxNum] = useState<number | null>(null);
  const [SelectedRefill, setSelectedRefill] = useState<number | null>(null);

  const handleOverlaysClick = (rxNum: number, refillNum: number) => {
    setSelectedRxNum(rxNum);
    setSelectedRefill(refillNum);
    setOverlayVisible(!overlayVisible);
  };

  const handleCloseOverlay = () => {
    setSelectedRxNum(null);
    setSelectedRefill(null);
    setOverlayVisible(false);
  };

  return (
    <span className="overlay-link-rx" onClick={() => handleOverlaysClick(rxNum, refillNum)}>
      {rxNum}
      {overlayVisible && (
        <RxOverlayComponent
          onClose={handleCloseOverlay}
          RxNum={SelectedRxNum}
          RefillNum={SelectedRefill}
          siteNum={siteNum}
        />
      )}
    </span>
  );
};
