import React from 'react';
import {useFormikContext} from 'formik';

interface AnalyticsEvent {
  event: string;
  eventInfo: Record<string, unknown> & {
    user?: Record<string, unknown>;
    content?: Record<string, unknown>;
  };
}

export function useTrackFormikAnalytics(
  formName: string,
  formType: string,
  onTrackAnalytics: (event: AnalyticsEvent) => void,
  additionalFormProps?: Record<string, unknown>,
  submitButtonText?: string
) {
  const {touched, submitCount, errors, isValid, isSubmitting, values, initialValues} = useFormikContext();

  React.useEffect(() => {
    onTrackAnalytics({
      event: 'formloaded',
      eventInfo: {
        content: {
          form: {
            name: formName.toLowerCase(),
            type: formType.toLowerCase(),
            ...additionalFormProps
          }
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (initialValues !== values) {
      onTrackAnalytics({
        event: 'formstart',
        eventInfo: {
          content: {
            form: {
              name: formName.toLowerCase(),
              type: formType.toLowerCase(),
              ...additionalFormProps
            }
          }
        }
      });
    }
  }, [initialValues === values]);

  React.useEffect(() => {
    if (
      Object.keys(touched).length > 0 &&
      Object.keys(errors).length === 0 &&
      submitCount > 0 &&
      initialValues !== values
    ) {
      onTrackAnalytics({
        event: 'formsubmitted',
        eventInfo: {
          content: {
            form: {
              name: formName.toLowerCase(),
              type: formType.toLowerCase(),
              ...additionalFormProps
            }
          }
        }
      });
    }
  }, [submitCount]);

  React.useEffect(() => {
    if (Object.keys(touched).length > 0 && !isValid && !isSubmitting) {
      onTrackAnalytics({
        event: 'error',
        eventInfo: {
          error: {
            message: errors,
            source: formType.toLowerCase(),
            friendly: '',
            sourceURL: '',
            errorType: ''
          }
        }
      });
    }
  }, [Object.keys(touched).length, isSubmitting]);

  React.useEffect(() => {
    if (isSubmitting && submitButtonText) {
      onTrackAnalytics({
        event: 'link track',
        eventInfo: {
          innerText: submitButtonText.toLowerCase(),
          destinationURL: '/',
          linkType: 'link'
        }
      });
    }
  }, [isSubmitting]);
}
