import React, {useRef, useState} from 'react';
import {Backdrop, Typography, Stack, Divider, Button, Box, Link, Collapse, Popover} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {RoutingComponent} from '@skyline/types';

import {useMobile} from '../../../hooks/useMobile';
import {UserDropdownLink} from '../types/UserDropdownLink';
import {useBreakpointChangeEffect} from '../../../hooks';
import { ProfileButton } from './ProfileButton';


interface UserDropdownLinkProps extends UserDropdownLink {
  routingComponent: RoutingComponent;
  onNavigate?: (event: React.MouseEvent<HTMLParagraphElement | HTMLSpanElement, MouseEvent>) => void;
}

const DropdownSection: React.FC<UserDropdownLinkProps> = ({value, description, url, routingComponent, onNavigate}) => {
  return (
    <Box sx={{px: 3, py: 2}}>
      <Typography variant="body2" sx={{mb: 1}}>
        <strong>{value}</strong>
      </Typography>
      <Link component={routingComponent} onClick={onNavigate} href={url}>
        {description}
      </Link>
    </Box>
  );
};

export interface LogoutProps {
  userDropdownLinks: UserDropdownLink[];
  // onSignOutClick: () => void;
  name: string | undefined;
  hasUserDropdown: boolean;
  signOutText: string;
  routingComponent: RoutingComponent;
  onNavigate?: (event: React.MouseEvent<HTMLElement>) => void;
  siteName: string;
}

export const Logout: React.FC<LogoutProps> = ({
  userDropdownLinks,
  // onSignOutClick,
  name,
  hasUserDropdown,
  signOutText,
  routingComponent,
  onNavigate,
  siteName
}) => {
  const isMobile = useMobile('md');
  const logoutPopoverAnchor = useRef(null);

  const [logoutDropdownOpen, setLogoutDropdownOpen] = useState(false);

  function toggleLogoutDropdown() {
    setLogoutDropdownOpen((state) => !state);
  }

  useBreakpointChangeEffect(() => {
    setLogoutDropdownOpen(false);
  });

  const [expandedElement, setExpandedElement] = React.useState<null | HTMLElement>(null);
  const handleDropdownLinkToggle = (event: React.MouseEvent<HTMLElement>) => {
    if (expandedElement === null) {
      setExpandedElement(event.currentTarget);
    } else {
      setExpandedElement(null);
    }
  };

  function onSignOutClick () {
      location.href = '/logout';
      sessionStorage.removeItem('siteInfo');
  }

  function onChangePharmacyClick() {
    location.href = '/';
  }

  function onAccountClick() {
    location.href = '/secure/user-profile';
  }

  function onUserRequestClick() {
    location.href = '/secure/admin';
  }

  return (
    <>
      {!hasUserDropdown && (
        <>
          {!isMobile && (
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider flexItem orientation="vertical" />}
              spacing={2}
            >
              <Box sx={{minWidth:'100px'}}>
                <Typography style={{fontWeight: 'normal', color: 'black'}}>Pharmacy</Typography>
                <Typography style={{fontWeight: 'normal'}}> {siteName} </Typography>
              </Box>
              <Box >
                <ProfileButton onSignOutClick={onSignOutClick} onChangePharmacyClick={onChangePharmacyClick} onAccountClick={onAccountClick} name={name} onUserRequestClick={onUserRequestClick}/>
              </Box>
            </Stack>
          )}
          {isMobile && (
            <>
              <Box sx={{bgcolor: 'common.white', height: 54, alignItems: 'center', p: 2}}>
                <Typography sx={{color: 'primary.main'}}>
                  <strong>{name}</strong>
                </Typography>
              </Box>
              <Button
                onClick={onSignOutClick}
                sx={{
                  borderRadius: 0,
                  width: 375,
                  '&.MuiButtonBase-root': {
                    '&:after': {outline: (theme) => `medium solid ${theme.palette.common.white}`, outlineOffset: -5}
                  }
                }}
                data-testid="cta_signout_dropdown_btn"
              >
                {signOutText}
              </Button>
            </>
          )}
        </>
      )}
      {hasUserDropdown && (
        <>
          {!isMobile && (
            <>
              {logoutDropdownOpen && <Backdrop open sx={{top: 120, zIndex: 9999}} />}
              <Button
                variant="text"
                sx={{'&:hover': {bgcolor: 'transparent'}}}
                onClick={toggleLogoutDropdown}
                ref={logoutPopoverAnchor}
                endIcon={
                  <ExpandLessIcon
                    sx={{
                      color: 'primary.main',
                      transition: 'all 0.2s ease',
                      transform: `rotate(${logoutDropdownOpen ? 0 : '0.5turn'})`
                    }}
                  />
                }
              >
                <Typography sx={{color: 'primary.main'}}>
                  <strong>{name}</strong>
                </Typography>
              </Button>
              <Popover
                sx={{mt: 2.5, zIndex: 9999, '& .MuiPopover-paper': {border: 0, borderRadius: 0, p: 0, width: 316}}}
                anchorEl={logoutPopoverAnchor.current}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                open={logoutDropdownOpen}
                onClose={toggleLogoutDropdown}
              >
                <Stack divider={<Divider />}>
                  {userDropdownLinks.map((link) => (
                    <DropdownSection
                      key={link.value}
                      routingComponent={routingComponent}
                      onNavigate={toggleLogoutDropdown}
                      {...link}
                    />
                  ))}
                </Stack>
                <Button
                  fullWidth
                  onClick={onSignOutClick}
                  sx={{
                    borderRadius: 0,
                    '&.MuiButtonBase-root': {
                      '&:after': {outline: (theme) => `thin solid ${theme.palette.common.white}`, outlineOffset: -4}
                    }
                  }}
                  data-testid="cta_signout_dropdown_btn"
                >
                  {signOutText}
                </Button>
              </Popover>
            </>
          )}
          {isMobile && (
            <>
              <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Button
                  variant="text"
                  onClick={handleDropdownLinkToggle}
                  sx={{'&.MuiButtonBase-root': {'&:after': {outlineOffset: -6}, p: 0}}}
                >
                  <Box
                    sx={{
                      minHeight: 56,
                      width: '100%',
                      bgcolor: 'common.white',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      px: 2
                    }}
                  >
                    <strong>{name}</strong>
                    <ExpandLessIcon
                      sx={{transition: 'all 0.2s ease', transform: `rotate(${expandedElement ? 0 : '0.5turn'})`}}
                    />
                  </Box>
                </Button>
                <Collapse in={!!expandedElement} unmountOnExit sx={{width: 375}}>
                  <Stack divider={<Divider />} sx={{bgcolor: 'common.white'}}>
                    {userDropdownLinks.map((link) => (
                      <DropdownSection
                        key={link.value}
                        routingComponent={routingComponent}
                        onNavigate={onNavigate}
                        {...link}
                      />
                    ))}
                  </Stack>
                </Collapse>
              </Box>
              <Button
                onClick={onSignOutClick}
                sx={{
                  borderRadius: 0,
                  width: 375,
                  '&.MuiButtonBase-root': {
                    '&:after': {outline: (theme) => `medium solid ${theme.palette.common.white}`, outlineOffset: -5}
                  }
                }}
                data-testid="cta_signout_dropdown_btn"
              >
                {signOutText}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};
