import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import './DrugInteraction.css'
import { DrugInteractionDTO, DrugInteractionInfoDTO } from "../../Interfaces/DrugInteractionsDTOModels";
import { LoadingSpinner } from "../../common/components/LoadingSpinner/LoadingSpinner";
import { fetchInteractionInfo } from "../../actions/ProScriptAPI";
import { PrintButton } from "../../common/components/PrintButton";

interface InteractionOverlayProps {
    interaction: DrugInteractionDTO;
    onClose: () => void;
}

export const InteractionOverlay: React.FC<InteractionOverlayProps> = ({ interaction, onClose}) => {
    const printRef = useRef<HTMLDivElement>(null);
    const [interactionInfo, setInteractionInfo] = useState<DrugInteractionInfoDTO | null>(null);

    // If user clicks outside of the overlay, overlay closes
    const handleOverlaysClick = (e: React.MouseEvent) => {
        if ((e.target as HTMLElement).classList.contains('overlay-patient')) {
            onClose();
        }
    };

    useEffect(() => {
        fetchInteraction();
    }, [interaction])

    async function fetchInteraction() {
        try {
            const result: DrugInteractionInfoDTO = await fetchInteractionInfo(interaction.interactionID);
            setInteractionInfo(result);
        }
        catch (error) {
            console.error('Error fetching drug interaction info', error);
        }
    };

    if (interactionInfo === null) {
        return (
            <div className='overlay-patient' onClick={handleOverlaysClick}>
                <div className='overlay-content-rx' style={{ width: '80%'}}>
  
                    <div className='overlay-top'>
                        <div className='title-patient'>More Information</div>
                        <IconButton className='close-button' onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div> 

                    <LoadingSpinner {...{ height:"200px", width:"50%"}}/>            
                </div>
            </div>
        )
    };

    return (
        <div className='overlay-patient' onClick={handleOverlaysClick}>
            <div className='overlay-content-rx' style={{ width: '80%'}}>
  
                <div className='overlay-top'>
                    <div className='title-patient'>More Information</div>
                    <IconButton className='close-button' onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </div> 

                <div className='info-box' style={{margin: '20px', marginTop: '40px'}} ref={printRef}>
                    <PrintButton printRef={printRef} />
                    <div className='drug-title'>{interaction.drugName}</div>
                        <div className="result-row">
                            <p className="result-content"><span className='result-title-row'>Onset:</span> {interaction.onset}</p>
                            <p className="result-content"><span className='result-title-row'>Severity:</span> {interaction.severity}</p>
                            <p className="result-content"><span className='result-title-row'>Documentation:</span> {interaction.documentation}</p>
                        </div>
                            <p className='monograph-result-content'><span id='monograph-result-header'>Warning:</span> {interactionInfo.warning}</p>
                            <p className='monograph-result-content'><span id='monograph-result-header'>Effects:</span> {interactionInfo.effects}</p>
                            <p className='monograph-result-content'><span id='monograph-result-header'>Mechanism:</span> {interactionInfo.mechanism}</p>
                            <p className='monograph-result-content'><span id='monograph-result-header'>Management:</span> {interactionInfo.management}</p>
                            <p className='monograph-result-content'><span id='monograph-result-header'>Discussion:</span> {interactionInfo.discussion}</p>
                            <p className='monograph-result-content'><span id='monograph-result-header'>References:</span> {interactionInfo.references}</p>
                        </div>
                    </div>
                </div>
            );
};
