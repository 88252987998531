import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PharmacyForm from './PharmacyForm';
import { RxTracking1DTO, BatchTracking1DTO } from '../../Interfaces/TrackingDTOModels';
import { fetchRecentRx, fetchRecentBatch } from '../../actions/ProScriptAPI';
import { GetUserAccessesByUserId } from '../../actions/UsersAPI';
import { UserSiteAccessInfoInterface } from '../../Interfaces/Users';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import TabbedTable from './Tables';
import { ErrorMessage } from '../../common/components/AlertMessages/AlertMessages';

const theme = createTheme({
    palette: {
        primary: {
            main: '#004C97' // Primary Blue Color
        }
    }
});

export const LandingPage = (): JSX.Element => {
    interface UserPharmacies {
        state: string;
        pharmacy: string;
        pharmacyId: number;
    }

    const [statePharmacyPairs, setStatePharmacyPairs] = useState<UserPharmacies[]>([]);
    //set show loading spinner state for api call upon getting to landing page
    const [showLoading, setShowLoading] = useState(false);
    //set show recents for the api call after selecting a pharmacy on landing page
    const [showRecents, setShowRecents] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);

    const handleErrorMessageClose = () => {
        setErrorMessageVisibility(false);
        setErrorMessage('');
    };

    const addPharmacyPair = (element: UserSiteAccessInfoInterface) => {
        setStatePharmacyPairs((statePharmacyPairs) => [
            ...statePharmacyPairs,
            {
                state: element.siteShippingAddressState,
                pharmacy: element.siteName,
                pharmacyId: element.siteNumber
            }
        ]);
    };

    const sortPharmacyPairs = () => {
        setStatePharmacyPairs(statePharmacyPairs => statePharmacyPairs.sort((a, b) => {
            if(a.pharmacy.toLowerCase() < b.pharmacy.toLowerCase()) return -1;
            if(a.pharmacy.toLowerCase() > b.pharmacy.toLowerCase()) return 1;
            return 0;
        }));
    }

    useEffect(() => {
        const fetchInitialStatesPharmacies = async () => {
            setShowLoading(true);
            
            try {
                const response: UserSiteAccessInfoInterface[] = await GetUserAccessesByUserId();
                setShowLoading(false);

                response.forEach(addPharmacyPair);
                sortPharmacyPairs();
            } catch (error) {
                console.error('Error fetching useraccesses', error);
                setShowLoading(false);
                setErrorMessage("An Error Occurred When Fetching User Access. Please Try Again.\nIf the Problem Persists, Please Contact Support.");
                setErrorMessageVisibility(true);
            }
        };

        fetchInitialStatesPharmacies();
    }, []);

    const [prescriptions, setPrescriptions] = React.useState<RxTracking1DTO[] | null>([]);
    const [shipments, setShipments] = React.useState<BatchTracking1DTO[] | null>([]);
    const [pharmacy, setPharmacy] = React.useState('');
    const [pharmacyId, setPharmacyId] = useState(0);

    const fetchRxData = async () => {
        setShowRecents(true);
        setPrescriptions([]);

        try {
            const result: RxTracking1DTO[] = await fetchRecentRx({ siteNum: pharmacyId });
            setPrescriptions(result);
        }
        catch (error) {
            setErrorMessageVisibility(true);
            if (errorMessage === "") setErrorMessage("An Error Occurred When Fetching Prescriptions. Please Try Again.");
            else setErrorMessage("Unable to Locate Pharmacy. Please Try Again.");
            console.error('Error fetching rx tracking information', error);
            setPrescriptions(null);
        }
    };

    const fetchBatchData = async () => {
        setShowRecents(true);
        setShipments([])

        try {
            const result2: BatchTracking1DTO[] = await fetchRecentBatch({ siteNum: pharmacyId });
            setShipments(result2);
        }
        catch (error) {
            setErrorMessageVisibility(true);
            if (errorMessage === "") setErrorMessage("An Error Occurred When Fetching Shipments. Please Try Again.");
            else setErrorMessage("Unable to Locate Pharmacy. Please Try Again.");
            console.error('Error fetching batch tracking information', error);
            setShipments(null);
        }
    };

    useEffect(() => {
        if (!Number.isNaN(pharmacyId) && (pharmacyId > 0 || pharmacyId==-1)) {
            fetchRxData();
            fetchBatchData();
        };
    }, [pharmacyId]);

    return (
        <ThemeProvider theme={theme}>
            <div className='container-landing-page'>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                            <Typography style={{ color: 'black', fontSize: '24px', fontWeight: 'bold' }} textAlign="center" flexGrow={1}>
                                Welcome to Genoa Online NetRx!
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                { }
                {showLoading ? <LoadingSpinner {...{ height: "200px", width: "50%" }} /> :
                    <PharmacyForm
                        data={statePharmacyPairs}
                        pharmacy={pharmacy}
                        setPharmacy={setPharmacy}
                        setPharmacyId={setPharmacyId}
                        pharmacyId={pharmacyId}
                    />}
                {showRecents &&
                    <TabbedTable rxData={prescriptions} batchData={shipments}></TabbedTable> 
                }
            </div>
            {errorMessageVisibility &&
                <ErrorMessage message={errorMessage} onClose={handleErrorMessageClose} />
            }
        </ThemeProvider>
    );
};

